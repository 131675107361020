<app-header [enableMenu]="false"></app-header>

<div class="container-fluid mt100">

  <div class="row my-3">
    <div class="col-4">
      <button mat-raised-button color="primary" routerLink="">
        <mat-icon>arrow_back_ios</mat-icon>
        torna alla dashboard
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="ep_card">
        <div class="ep_card-header ep_card-header-icon ep_card-header-success">
          <div class="ep_card-icon"><i class="material-icons">account_circle</i></div>
          <h1 class="ep_card-title pt-2">Dati utente</h1>
        </div>
        <div class="ep_card-footer">
          <mat-form-field class="full-width">
            <input type="text" matInput disabled [(ngModel)]="user.name" placeholder="Nome">
          </mat-form-field>
          <mat-form-field class="full-width">
            <input type="text" matInput disabled [(ngModel)]="user.email" placeholder="Email">
          </mat-form-field>
          <mat-form-field class="full-width">
            <input type="text" matInput disabled [(ngModel)]="user.editore" placeholder="Editore">
          </mat-form-field>
        </div>
      </div>

<!--      <mat-card>-->
<!--        <mat-card-title>Dati utente</mat-card-title>-->

<!--        <mat-form-field class="full-width">-->
<!--          <input type="text" matInput disabled [(ngModel)]="user.name" placeholder="Nome">-->
<!--        </mat-form-field>-->
<!--        <mat-form-field class="full-width">-->
<!--          <input type="text" matInput disabled [(ngModel)]="user.email" placeholder="Email">-->
<!--        </mat-form-field>-->
<!--        <mat-form-field class="full-width">-->
<!--          <input type="text" matInput disabled [(ngModel)]="user.editore" placeholder="Editore">-->
<!--        </mat-form-field>-->

<!--      </mat-card>-->
    </div>

    <div class="col-4">

      <div class="ep_card">
        <div class="ep_card-header ep_card-header-icon ep_card-header-danger">
          <div class="ep_card-icon"><i class="material-icons">lock</i></div>
          <h1 class="ep_card-title pt-2">Cambio Password</h1>
        </div>
        <div class="ep_card-footer">
          <form #changePwdForm="ngForm">
            <mat-form-field class="full-width">
              <mat-label>Nuova password</mat-label>
              <input type="password" matInput id="pwd" #pwd required minlength="8"
                     [(ngModel)]="userPwd.pwd" name="pwd" placeholder="Nuova password">
              <mat-hint align="end">min. 8 caratteri</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>Ripeti password</mat-label>
              <input type="password" matInput id="ripPwd" #ripPwd required
                     [(ngModel)]="userPwd.ripPwd" name="ripPwd" placeholder="Ripeti password">
            </mat-form-field>
            <button type="button" (click)="changePwd()" mat-raised-button color="primary"
                    [disabled]="!changePwdForm.form.valid">Aggiorna password
            </button>
          </form>
        </div>
      </div>

<!--      <mat-card>-->
<!--        <mat-card-title>Cambio Password</mat-card-title>-->

<!--        <form #changePwdForm="ngForm">-->
<!--          <mat-form-field class="full-width">-->
<!--            <mat-label>Nuova password</mat-label>-->
<!--            <input type="password" matInput id="pwd" #pwd required minlength="8"-->
<!--                   [(ngModel)]="userPwd.pwd" name="pwd" placeholder="Nuova password">-->
<!--          </mat-form-field>-->
<!--          <mat-form-field class="full-width">-->
<!--            <mat-label>Nuova password</mat-label>-->
<!--            <input type="password" matInput id="ripPwd" #ripPwd required-->
<!--                   [(ngModel)]="userPwd.ripPwd" name="ripPwd" placeholder="Ripeti password">-->
<!--          </mat-form-field>-->
<!--          <button type="button" (click)="changePwd()" mat-raised-button color="primary"-->
<!--                  [disabled]="!changePwdForm.form.valid">Aggiorna password-->
<!--          </button>-->
<!--        </form>-->
<!--      </mat-card>-->
    </div>
  </div>
</div>
