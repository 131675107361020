<!-- <button (click)="exportexcel()">Esporta in Excel</button> -->
<!-- <button mat-icon-button (click)="generatePdf()"> -->
    <!-- <mat-icon matTooltip="Esporta tabella in PDF">open_in_new</mat-icon> -->
    <img src="/assets/file-iconset/xls-icon.png" *ngIf="excel" 
    width="48px" 
    height="48px" 
    (click)="exportexcel()"
    matTooltip="Esporta tabella in Excel">
    <img 
    src="/assets/file-iconset/pdf-icon.png"  *ngIf="pdf" 
    width="48px" 
    height="48px" 
    (click)="generatePdf()" 
    matTooltip="Esporta tabella in PDF">
<!-- </button> -->


