import { Partizioni } from './../../../models/user.model';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { description } from 'src/app/models/books.model';
import { Giro } from 'src/app/models/giro.model';
import { BookService } from 'src/app/services/book.service';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-insert-book',
  templateUrl: './insert-book.component.html',
  styleUrls: ['./insert-book.component.scss'],
})
export class InsertBookComponent implements OnInit, OnDestroy {
  form = new FormControl();
  isbnCtrl = new FormControl();
  subscriptions: Subscription[] = [];
  partizioni = [];
  public fieldLoader = {
    editori: false,
    giri: false,
  };

  insertFields = {
    fields: [
      {
        field: 'editore',
        option: {
          type: 'select',
          list: [],
        },
      },
      {
        field: 'giro',
        option: {
          type: 'select',
          list: [],
        },
      },
    ],
  };

  public values = {
    editore: null,
    giro: null,
  };
  itemInput: string = null;
  disabled = {
    submit: true,
    isbn: true,
  };
  validIsbn: boolean = false;
  constructor(
    private bookservice: BookService,
    private toastservice: ToastrService,
    public dialogRef: MatDialogRef<InsertBookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.getGiri();
    this.getEditori();
    /**salvo i valori dati in input nell'indice opportuno */
    this.form.valueChanges.subscribe((data) => {
      this.values[this.itemInput] = data;
      if (this.values.giro && this.values.editore) {
        this.disabled.submit = false;
      }
    });
  }
  /**prendo il nome dell'input */
  getInputName(event) {
    this.itemInput = event.srcElement.name;
  }

  onUndoClick() {
    this.dialogRef.close();
  }
  /**alla chiusura del dialog comunico i dati al componente che chiama il dialog */
  onInsertClick() {
    this.dialogRef.close(this.values);
  }

  getGiri(anno = 0) {
    this.fieldLoader.giri = true;
    const sub = this.bookservice
      .getGiriByYear(anno)
      .subscribe((giri: Array<Giro>) => {
        const data: Array<description> = [];

        giri.forEach((item: Giro) => {
          data.push({
            cod: item.anno + item.numero,
            descrizione: item.descrizione,
          });
        });

        this.loadLista(data, 'giro');
        this.fieldLoader.giri = false;
      });

      this.subscriptions.push(sub);
  }

  getEditori() {
    this.fieldLoader.editori = true;
    const sub = this.bookservice
      .editori('')
      .subscribe((editori: Array<description>) => {
        this.loadLista(editori, 'editore');
        this.fieldLoader.editori = false;
      });

      this.subscriptions.push(sub);
  }

  loadLista(data, field) {
    const i = _.findIndex(this.insertFields.fields, {
      field: field,
    });
    this.insertFields.fields[i].option.list = [];
    data.forEach((element) => {
      this.insertFields.fields[i].option.list.push({
        cod: element.cod,
        descrizione: element.descrizione,
      });
    });
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
  
}
