<form #f="ngForm" (submit)="changeInput(f.value)" class="orderQty">
  <div class="container-fluid mb-3">
    <div>Aggiungi all'ordine:</div>
    <div class="row justify-content-center">
      <button type="button" class="btnSm" [ngClass]="user.profile" (click)="remove()">-</button>
      <input
        placeholder="QTA"
        (change)="changeInput(f.value)"
        [(ngModel)]="qta"
        name="qta"
        size="4"
      />
      <button type="button" [ngClass]="user.profile" class="btnSm" (click)="add()">+</button>
    </div>
    <div class="row text-center">
      <div class="col-12 button-items" *ngIf="disponibilita">
        {{ articoloTools.disponibilita(disponibilita) }}
      </div>
    </div>
  </div>
</form>
