import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SuccessOautResponse } from '../models/oauth.model';
import { SessionStorageService } from './session.service';

export interface Filter {
  name: string;
  value: any;
} 

@Injectable({
  providedIn: 'root'
})

export class OrdiniService {
  API_BASE: string = environment[environment.env].base_url;

  constructor(
    private injector: Injector,
    private httpclient: HttpClient,
    private session: SessionStorageService,
  ) {}

  private getHeaders(): HttpHeaders {
    const token: SuccessOautResponse = this.session.get('token');
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });

    return header;
  }

  public getOrdiniCollection(
    startDate:string,
    stopDate: string, 
    filters?: Array<Filter>,
    orderby?: string
  ) {
      const serviceHeaders = this.getHeaders();
      let payload = new HttpParams();
      if (orderby) {
        payload = payload.append(`sort`, orderby);
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter: Filter) => {
          payload = payload.append(`filter[${filter.name}]`, filter.value);
        });
      } 
  
      const options = { headers: serviceHeaders, params: payload };  
      return this.injector.get(HttpClient).get(`${this.API_BASE}/ordini/${startDate}/${stopDate}`, options);
  }

  public getAllOrdini() {
      const serviceHeaders = this.getHeaders();
      let payload = new HttpParams();
       
  
      const options = { headers: serviceHeaders, params: payload };  
      return this.injector.get(HttpClient).get(`${this.API_BASE}/ordini`, options);
  }

  public getRigheOrdine(id_ordine) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/ordine/${id_ordine}`, options);
  }


  public getRigheOrdini() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/righe-ordini`, options);
  }

  /**
   * Inserisce a carrello gli aritcoli
   * @param items 
   * @returns 
   */
  public doImport(items) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders};
    return this.injector.get(HttpClient).post(`${this.API_BASE}/cart/store`, items, options);
  }

  public placeOrder() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders};
    return this.injector.get(HttpClient).get(`${this.API_BASE}/order`, options);
  }

  getStats() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders}; 
    //const uri = (kind ==='spediti') ? 'stats/spediti' : 'stats/in_spedizione';  
    return this.injector.get(HttpClient).get(`${this.API_BASE}/stats/libreria`, options);
  }

}
