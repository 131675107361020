<mat-card
  class="image"
  matTooltipPosition="left"
  matTooltip="La Copertina serve ad aggiornare gli archivi anagrafici degli operatori del settore (Arianna, catene di librerie, siti internet…).Il lato maggiore deve essere almeno di 1000px e il lato minore almeno di 500px per un peso massimo di 3Mb. "
>
  <mat-card-title
    ><h3 class="center">{{ title }}</h3></mat-card-title
  >
  <button
    mat-icon-button
    style="float: right"
    (click)="clear()"
    *ngIf="picture && !disabled"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <div class="loading" *ngIf="loading">
    Sto caricando la copertina
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <img [src]="picture | safeResourceUrl" *ngIf="picture && !loading" />
  <img
    class="placeholder"
    src="../../../assets/images/no-copertina.jpg"
    *ngIf="!picture && !loading"
    (click)="file.click()"
  />
  <input
    type="file"
    accept="image/jpeg"
    name="preview"
    id="preview"
    #file
    class="hide"
    (change)="loadFile($event)"
    [disabled]="disabled"
  />

  <!--  COMMENTATA 01/02/2021-->
  <mat-card-subtitle *ngIf="picture && w && h"
    >{{ w }}px X {{ h }}px - {{ size.size }}</mat-card-subtitle
  >

  <!--    <button mat-button class="full-width" color="primary" (click)="file.click()">-->
  <!--        Seleziona immagine-->
  <!--        <mat-icon>attach_file</mat-icon>-->
  <!--        <input type="file" accept="image/jpeg" name="preview" id="preview" #file class="hide"-->
  <!--               (change)="loadFile($event)">-->
  <!--    </button>-->
</mat-card>
