<h1 mat-dialog-title>Inserimento nuovo {{data.tipo}}</h1>
<div mat-dialog-content> 
  <mat-form-field>
    <mat-label>Cognome</mat-label>
    <input matInput [(ngModel)]="data.cognome">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Nome</mat-label>
    <input matInput [(ngModel)]="data.nome">
  </mat-form-field>
 
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onUndoClick()">Annulla</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial color="primary">Inserisci</button>
</div>