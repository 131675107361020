import { ModificaCatalogoComponent } from './../../components/modifica-catalogo/modifica-catalogo.component';
import { Ricerca } from './../../models/ricerca.model';
import { MessageService } from './../../services/message.service';
import { environment } from './../../../environments/environment';
import { LocalStorageService } from './../../services/storage.service';
import {
  BookCollection,
  description,
} from './../../models/books.model';
import {
  BookService,
  filters,
} from './../../services/book.service';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';

import { _MatTabGroupBase } from '@angular/material/tabs';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Wbook } from 'src/app/models/wbook';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
  animations: [],
})
export class CatalogoComponent
  implements OnInit, OnDestroy
{
  public book;
  public books: any = [];
  public booksPage: any = [];
  public loader = false;
  public page = 0;
  public recordPerPage = 20;
  public selectedAuthor = '';
  public selectedCollana = '';
  public selectedArgomento = '';
  public search = '';
  public searchField = 'titolo';
  public defaultOrder = 'data_pubblicazione';
  public defaultSort = 'desc';
  public option = 'view';
  salvaModifiche = false;
  flip: string = 'default';
  public stati = [
    { cod: 'P', descrizione: 'Disponibile' },
    { cod: 'E', descrizione: 'Esaurito' },
    { cod: 'R', descrizione: 'Ristampa' },
    //   { cod: 'I' , descrizione: 'In produzione'}
  ];
  animation = '';
  public links = [];
  searchCtrl = new FormControl();
  subscriptions: Subscription[] = [];
  ricerca: Ricerca = {
    fields: [
      {
        field: 'titolo',
        option: {
          type: 'text',
        },
      },
      {
        field: 'ean',
        option: {
          type: 'text',
        },
      },
      {
        field: 'anno_pubblicazione',
        option: {
          type: 'text',
        },
      },
      {
        field: 'autore',
        option: {
          type: 'select',
          list: [],
        },
      },
      {
        field: 'collana',
        option: {
          type: 'select',
          list: [],
        },
      },
      {
        field: 'argomento',
        option: {
          type: 'select',
          list: [],
        },
      },
      {
        field: 'stato',
        option: {
          type: 'select',
          list: [],
        },
      },
    ],
  };

  selectedBook = null;
  catalogoloaded = null;

  constructor(
    private bookservice: BookService,
    private storage: LocalStorageService,
    private messageservice: MessageService
  ) {
    const sub = this.searchCtrl.valueChanges.subscribe(
      (data: string) => {
        this.search = data;
        this.getBooks(0);
      }
    );

    this.subscriptions.push(sub);
  }
  ngOnInit(): void {
    // devo gestire l'evento
    // quando arriva l'evento scrivo nello storage, qualcosa come loaded e faccio getCached
    //
    this.catalogoloaded = this.storage.get(
      'catalogo-loaded'
    );
    if (this.catalogoloaded === true) {
      this.getCached();
    } else {
      const sub = this.messageservice
        .listenEvent()
        .subscribe((message) => {
          if (message.event === 'catalogo-loaded') {
            this.getCached();
          }
        });

      this.subscriptions.push(sub);
    }
  }
  modifica() {
    this.option = 'edit';
  }
  confirm() {
    this.salvaModifiche = true;
  }
  toggle(bookId): void {
    this.selectedBook = !bookId ? null : bookId;
    this.option = 'view';

    this.salvaModifiche = false;
  }

  async getCached() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.catalogoRequest()
    );
    response.json().then((data) => {
      this.books = data;
      this.buildAutoriList();
      this.buildArgomentiList();
      this.buildCollaneList();
      this.buildStatiList();
      this.getBooks(0);
    });
  }

  // crea sulla scorta della lista books un array di description {cod : cod_autore_1, description : autore}
  buildAutoriList() {
    const app = _.groupBy(this.books, 'cod_autore_1');
    const list: Array<description> = [];
    _.forEach(app, function (d) {
      list.push({
        cod: d[0].cod_autore_1,
        descrizione: d[0].autore,
      });
    });
    const i = _.findIndex(this.ricerca.fields, {
      field: 'autore',
    });
    this.ricerca.fields[i].option.list = _.sortBy(
      list,
      'descrizione'
    );
  }

  buildArgomentiList() {
    const app = _.groupBy(this.books, 'cod_argomento_1');
    const list: description[] = [];
    _.forEach(app, function (d) {
      if (d[0].cod_argomento_1 && d[0].argomento) {
        list.push({
          cod: d[0].cod_argomento_1,
          descrizione: d[0].argomento,
        });
      }
    });
    const i = _.findIndex(this.ricerca.fields, {
      field: 'argomento',
    });
    this.ricerca.fields[i].option.list = _.sortBy(
      list,
      'descrizione'
    );
  }

  buildCollaneList() {
    const app = _.groupBy(this.books, 'cod_collana');
    const list: description[] = [];
    _.forEach(app, function (d) {
      if (d[0].cod_collana && d[0].collana) {
        list.push({
          cod: d[0].cod_collana,
          descrizione: d[0].collana,
        });
      }
    });
    const i = _.findIndex(this.ricerca.fields, {
      field: 'collana',
    });
    this.ricerca.fields[i].option.list = _.sortBy(
      list,
      'descrizione'
    );
  }
  buildStatiList() {
    const app = _.groupBy(this.books, 'stato');
    const list: description[] = [];
    const _self = this;
    _.forEach(app, function (d) {
      list.push({
        cod: d[0].stato,
        descrizione: _self.getStato(d[0].stato),
      });
    });
    const i = _.findIndex(this.ricerca.fields, {
      field: 'stato',
    });
    this.ricerca.fields[i].option.list = _.sortBy(
      list,
      'descrizione'
    );
  }

  //riceve l'outuput del componente ricerca
  getSearchType(data: any) {
    if (data.event === 'search') {
      const field = data.field;
      switch (field) {
        case 'autore':
          const au = _.findIndex(this.ricerca.fields, {
            field: 'autore',
          });
          const indexAut = _.findIndex(
            this.ricerca.fields[au].option.list,
            { cod: data.value }
          );
          this.getBooksAutore(
            data.value,
            this.ricerca.fields[au].option.list[indexAut]
              .descrizione
          );
          break;
        case 'collana':
          const c = _.findIndex(this.ricerca.fields, {
            field: 'collana',
          });
          const indexCol = _.findIndex(
            this.ricerca.fields[c].option.list,
            { cod: data.value }
          );
          this.getBooksCollana(
            data.value,
            this.ricerca.fields[c].option.list[indexCol]
              .descrizione
          );
          break;
        case 'argomento':
          const ar = _.findIndex(this.ricerca.fields, {
            field: 'argomento',
          });
          const indexArg = _.findIndex(
            this.ricerca.fields[ar].option.list,
            { cod: data.value }
          );
          this.getArgomento(
            data.value,
            this.ricerca.fields[ar].option.list[indexArg]
              .descrizione
          );
          break;
        case 'anno_pubblicazione':
          this.search = data.value;
          this.searchField = data.field;
          this.getBooks(0);
          break;
        case 'stato':
          if (data.value !== '0') {
            this.search = data.value;
            this.searchField = data.field;
            this.getBooks(0);
          } else {
            this.resetParameters();
            this.getBooks(0);
          }
          break;
        case 'titolo':
          this.search = data.value;
          this.searchField = data.field;
          this.getBooks(0);
          break;
        case 'ean':
          this.search = data.value;
          this.searchField = data.field;
          this.getBooks(0);
          break;
      }
    } else if (data.event === 'order') {
      this.defaultOrder = data.field;
      this.defaultSort = data.value;
      this.getBooks(0);
    }
  }

  changePage($event): void {
    this.page = $event.pageIndex;
    this.getBooks(this.page);
  }

  resetParameters() {
    this.selectedArgomento = '';
    this.selectedAuthor = '';
    this.selectedCollana = '';
    this.search = '';
  }

  selectFromBook(e) {
    const event = e.event;
    const description = e.description;
    const cod = e.code;

    if (event === 'autore') {
      this.getBooksAutore(cod, description);
    }
    if (event === 'argomento') {
      this.getArgomento(cod, description);
    }
    if (event === 'collana') {
      this.getBooksCollana(cod, description);
    }
    this.selectedBook = null;
  }

  getArgomento(argomento, description) {
    this.resetParameters();
    this.selectedArgomento = argomento;
    this.getBooks(0);
    this.links = [
      'Catalogo',
      'Per argomento ' + description,
    ];
  }

  getBooksCollana(collana, description) {
    this.resetParameters();
    this.selectedCollana = collana;
    this.getBooks(0);
    this.links = ['Catalogo', 'Per collana ' + description];
  }

  getBooksAutore(autore, description) {
    this.resetParameters();
    this.selectedAuthor = autore;
    this.getBooks(0);
    this.links = ['Catalogo', 'Per autore ' + description];
  }

  catalogo() {
    this.links = [];
    this.resetParameters();
    this.getBooks(0);
  }

  getBooks(pagina) {
    this.loader = true;
    const offset = pagina;
    const search = this.search;
    const _s = this;
    let allBooks = null;
    if (this.selectedCollana > '') {
      allBooks = _.filter(this.books, {
        cod_collana: this.selectedCollana,
      });
    } else if (this.selectedArgomento > '') {
      allBooks = _.filter(this.books, {
        cod_argomento_1: this.selectedArgomento,
      });
    } else if (this.selectedAuthor > '') {
      allBooks = _.filter(this.books, {
        cod_autore_1: this.selectedAuthor,
      });
    } else {
      allBooks = _.filter(this.books, function (o) {
        if (
          o[_s.searchField] &&
          o[_s.searchField]
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return o;
        }
      });
    }
    // this.booksPage = _.sortBy(_(allBooks).slice(offset * limit).take(limit).value(), this.defaultOrder, this.defaultSort);
    this.booksPage = _.orderBy(
      _(allBooks)
        .slice(offset * this.recordPerPage)
        .take(this.recordPerPage)
        .value(),
      this.defaultOrder,
      this.defaultSort
    );
    this.loader = false;
  }

  setAutori($event) {}

  formatData(data) {
    return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
  }

  ristampa(ean) {
    this.messageservice.setEvent({
      type: 'menu',
      event: { link: 'novita' },
    });
    this.storage.set('ristampaBook', ean);
  }

  getStato(stato) {
    /*  stati = [
            { cod: 'P' , descrizione:  'Disponibile'},
            { cod: 'E' , descrizione: 'Esaurito' },
            { cod: 'R' , descrizione: 'Ristampa'},
            { cod: 'I' , descrizione: 'In produzione'}
        ]; 
*/
    const o = _.find(this.stati, { cod: stato });

    if (o) {
      return o.descrizione;
    }
  }

  /**
   * Funzione che reimposta i filtri delle ricerca
   */
  resetForm(event) {
    if (event) {
      this.defaultOrder = 'data_pubblicazione';
      this.defaultSort = 'desc';
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
            el.unsubscribe();
        }
      })
  }
}
