import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string): string { 
    return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
  }

}
