import { SessionStorageService } from "./../../../services/session.service";
import { iUser } from "./../../../models/user.model";
import { Subscription } from "rxjs";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Ricerca } from "src/app/models/ricerca.model";
import { BookService } from "src/app/services/book.service";
import { JsonapiService } from "src/app/services/jsonapi.service";
import { eventModel, MessageService } from "src/app/services/message.service";
import { LocalStorageService } from "src/app/services/storage.service";
import * as moment from "moment";
import { description } from "src/app/models/books.model";
import { CarrelloService } from "src/app/services/carrello.service";
import { findIndex } from "lodash";

@Component({
  selector: "app-catalogo-cliente",
  templateUrl: "./catalogo-cliente.component.html",
  styleUrls: ["./catalogo-cliente.component.scss"],
})
export class CatalogoClienteComponent implements OnInit, OnDestroy  {
  public pagination = [];
  public book;
  public books: any = [];
  public booksPage: any = [];
  //info riguardanti la paginaziine degli oggetti restituiti es. current-page
  public booksMeta: any = {};
  public loader = false;
  public recordPerPage = 20;
  public page = 1;
  public search = "";
  public searchField = "titolo";
  public defaultOrder = "data_pubblicazione";
  public sortField = "-data_pubblicazione";
  public option = "view";
  searchFilters: any = {};
  filters = [];
  salvaModifiche = false;
  flip: string = "default";
  user: iUser = null;
  public stati = [
    { cod: "P", descrizione: "Disponibile" },
    { cod: "E", descrizione: "Esaurito" },
    { cod: "R", descrizione: "Ristampa" },
    { cod: "I", descrizione: "In produzione" },
  ];
  animation = "";
  public links = [];
  searchCtrl = new FormControl();
  subscriptions: Subscription[] = [];
  ricerca: Ricerca = {
    fields: [
      {
        field: "editore",
        option: {
          type: "select",
          list: [],
        },
      },
      {
        field: "titolo",
        option: {
          type: "text",
        },
      },
      {
        field: "ean",
        option: {
          type: "text",
        },
      },
      {
        field: "anno_pubblicazione",
        option: {
          type: "text",
        },
      },
      {
        field: "autore",
        option: {
          type: "lazyUrl",
          lazyUrl: "autore",
        },
      },
      {
        field: "collana",
        option: {
          type: "lazyUrl",
          lazyUrl: "collana",
        },
      },
      {
        field: "argomento",
        option: {
          type: "lazyUrl",
          lazyUrl: "argomento",
        },
      },
      {
        field: "stato",
        option: {
          type: "select",
          list: [],
        },
      },
    ],
  };

  selectedBook = null;
  catalogoloaded = null;
  submit: boolean = false;
  eventListernerSub;
  @Output() loaded = new EventEmitter();

  constructor(
    private jsonapi: JsonapiService,
    private storage: LocalStorageService,
    private session: SessionStorageService,
    private messageservice: MessageService,
    private bookservice: BookService,
    private carrelloservice: CarrelloService
  ) {
    const sub = this.searchCtrl.valueChanges.subscribe((data: string) => {
      this.search = data;
      //this.getBooks(0);
    });

    this.subscriptions.push(sub);
  }
  ngOnInit(): void {
    this.user = this.session.get("usr");
    this.buildStatiList();
    this.getEditori();
    this.listenCart();
  }

  ngOnDestroy(): void {
    this.eventListernerSub.unsubscribe();
    this.subscriptions.forEach((el) => {
      if(el){
        el.unsubscribe();
      }
    })
  }

  /**prendo tutti i libri presenti nel catalogo dal database */
  getAllBooks(page, sort?, filters?) {
    this.loader = true;
    let sub;
    (sub = this.jsonapi
      .getCollectionPaginated("catalogo-generales", page, 20, filters, sort)
      .subscribe((data: any) => {
        this.loader = false;
        this.books = data.data;
        this.booksMeta = data.meta.page;
        this.booksPage = data.data;
        this.searchFilters = {};
      })),
      (error) => {
        console.error(error);
      };

      this.subscriptions.push(sub);
  }

  loadLista(data, field) {
    const i = findIndex(this.ricerca.fields, { field: field });
    this.ricerca.fields[i].option.list = [];
    data.forEach((element) => {
      this.ricerca.fields[i].option.list.push({
        cod: element.cod,
        descrizione: element.descrizione,
      });
    });
  }

  getEditori() {
    const sub = this.bookservice
      .getEditoriCliente(this.user.code)
      .subscribe((editori: Array<description>) => {
        this.loadLista(editori, "editore");
      });

      this.subscriptions.push(sub);
  }

  toggle(bookId): void {
    this.selectedBook = !bookId ? null : bookId;
    this.option = "view";
    this.salvaModifiche = false;
  }

  getSearchType(data: any) {
    if (data.event === "search") {
      this.searchFilters = data.values;
    }
    if (data.event === "order") {
      this.sortField = data.value === "desc" ? "-" + data.field : data.field;
    }
  }

  submitButton() {
    this.filters = this.searchFilters;
    this.filters && this.filters.length > 0
      ? this.getAllBooks(1, this.sortField, this.filters)
      : this.getAllBooks(1, this.sortField);
  }

  modifica() {
    this.option = "edit";
  }
  confirm() {
    this.salvaModifiche = true;
  }

  changePage($event): void {
    this.page = $event.pageIndex + 1;
    this.getAllBooks(this.page, this.sortField, this.filters);
    this.loader = true;
  }
  resetForm(event) {
    if (event) {
      this.filters = [];
    }
  }

  getStato(codstato) {
    let stato = this.stati.find((stato) => {
      return stato.cod === codstato;
    });
    return stato.descrizione;
  }

  buildStatiList() {
    const i = findIndex(this.ricerca.fields, { field: "stato" });
    this.ricerca.fields[i].option.list = this.stati;
  }
  /**Esegue la ricerca al click dell'elemento nella card del libro*/
  searchFromBook(field, value) {
    const filtro = [{ name: field, value: value }];
    this.getAllBooks(1, this.sortField, filtro);
  }

  formatData(data) {
    return moment(data, "YYYYMMDD").format("DD-MM-YYYY");
  }

  public setQta(event) {
    const sub = this.carrelloservice
      .setItemToCart(event.codice, event.qta)
      .subscribe((data: any) => {
        this.messageservice.setEvent({ type: "cart", event: data.data });
      });

      this.subscriptions.push(sub);
  }

  /**
   * Aggiorna le qta del libro quando viene cambiata dal carrello
   */
  listenCart() {
    const sub  = this.eventListernerSub = this.messageservice
      .listenEvent()
      .subscribe((data: eventModel) => {
        if (data.type === "adj-cart-item" && this.booksPage.length) {
          const i = findIndex(this.booksPage, function (book) {
            return book.attributes.ean === data.event.ean;
          });
          //controllo che il libro sia visualizzzato nella pagina corrente
          if (i >= 0) {
            this.booksPage[i].attributes.qta = data.event.qta;
            this.booksPage = [...this.booksPage];
          }
        }
      });

      this.subscriptions.push(sub);
  }
}
