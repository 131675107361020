import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Carrello, CartItem } from 'src/app/models/cart.model';
import { CarrelloService } from 'src/app/services/carrello.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit, OnDestroy {
  @Input() item: CartItem;
  @Output() removeItemFromCart: EventEmitter<string> = new EventEmitter<string>();
  @Output() setItem: EventEmitter<Carrello> = new EventEmitter<Carrello>();
  lastevent;
  subscription: Subscription;
  constructor(private carrelloservice:CarrelloService, private messageservice:MessageService) { }

  ngOnInit(): void {
  }
  
  delete(ean: string){
    this.removeItemFromCart.emit(ean);
  }

  qtaChanged(event) {  

      this.subscription = this.carrelloservice.setItemToCart(this.item.ean, event).subscribe((data:any) => {  
          this.setItem.emit(data.data);
          this.messageservice.setEvent({type:'adj-cart-item', event: {ean: this.item.ean, qta: event}});
          this.messageservice.setEvent({type:'adj-cart', event: data.data});
      }); 
    
  }

  sconto(item: CartItem) {
    const lordo =    (item.prezzo*item.qta);

    if (item.defiscalizzato > 0 ) {
      const lordodef = lordo - (lordo * item.defiscalizzato / 100);
      const netto = lordo - (lordodef * item.sconto/ 100);
      return netto;
    } else { 
      const netto = lordo - (lordo * item.sconto/ 100);
      return netto;
    }
    /**
     * 
     */
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }

}
