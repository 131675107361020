<div class="w-full">
  <h1 mat-dialog-title class="title mr-15">Lista cedole App</h1>
  <div mat-dialog-content class="w-full">
    <div class="container">
      <div class="row">
        <table mat-table class="col" [dataSource]="data.pdf">
          <ng-container matColumnDef="cedola">
            <th mat-header-cell *matHeaderCellDef>Cedola</th>
            <td mat-cell *matCellDef="let row">
              {{ row.cedola }}
            </td>
          </ng-container>

          <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef>Nome File</th>
            <td mat-cell *matCellDef="let row">
              {{ row.filename }}
            </td>
          </ng-container>

          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>Scarica cedola</th>
            <td mat-cell *matCellDef="let row">
              <button
                mat-button
                color="error"
                (click)="donwloadPDF(row)"
                [disabled]="downlaod"
              >
                <i *ngIf="!download" class="fa fa-icon fa-download"></i>
                <i *ngIf="download" class="fa fa-gear fa-spin"></i>
                scarica
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
