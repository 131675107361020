<div class="cartContent" *ngIf="!loading" >
    <div class="container-fluid" *ngIf="items?.length">
      <div class="row subtotal" >
        <div class="col-12 text-center my-2 ">
          <strong>Subtotale: </strong>
          <span class="price">{{total | currency}}</span>
        </div>
        <div class="col-12 text-center my-2"  >
          <button mat-flat-button color="default" class="mr-5" (click)="svuotaCarrello();">Svuota il carrello</button>
          <button mat-flat-button color="accent" (click)="concludiOrdine();" >Concludi ordine</button>
        </div>
      </div>
      <div class="row itemsContainer">
        <div class="container-fluid p-0" *ngFor="let item of items">
          <div class="row">
            <div class="col-12">
              <app-cart-item [item]="item" (setItem)="aggiornaTotale($event)" (removeItemFromCart)="remove($event)"></app-cart-item>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" *ngIf="items?.length === 0 || !items">  
        <h1 class=" text-center ">Il tuo carrello &egrave; vuoto.</h1>   
    </div>
  </div>
  