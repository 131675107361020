<div *ngIf="!book">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
</div>
<div
  class="row"
  *ngIf="book"
>
  <div class="col-3">
    <app-image-view
      *ngIf="book.isbn"
      [ean]="book.isbn.toString()"
      [type]="'thumb'"
    ></app-image-view>
    <br />
    <app-images-list
      [title]="
        'Immagini aggiuntive ' + this.images.length + '/6'
      "
      [insert]="true"
      [isbn]="book.isbn"
      (changeImage)="setAdditionalImages($event)"
      max="6"
    ></app-images-list>
    <br />
    <app-pdf-uploader
      *ngIf="!loadingPdf"
      title="Scheda Novità"
      [pdf]="novita"
      [disabled]="true"
      (download)="downloadPdf()"
    ></app-pdf-uploader>
  </div>
  <div class="col-9">
    <!-- controllo presenza "titolo esteso" (book.test) -->
    <h2>{{ book.titolo | uppercase }}</h2>
    <h3>[ISBN]: {{ book.isbn }}</h3>
    <mat-tab-group selectedIndex="0">
      <mat-tab label="Informazioni generali">
        <mat-card>
          <div *ngIf="book.editore">
            <strong>Editore:</strong>
            <span>
              <button
                mat-button
                color="accent"
              >
                {{ editore | uppercase }}
              </button>
            </span>
          </div>
          <div *ngIf="1 * book.cod_autore_1">
            <strong>Autori:</strong>
            <span *ngFor="let autore of autori">
              <button
                mat-button
                color="accent"
              >
                {{ autore.descrizione | uppercase }}
              </button>
            </span>
          </div>
          <div *ngIf="1 * book.cod_argomento_1">
            <strong>Argomenti:</strong>
            {{ argomento }}
          </div>
          <div
            *ngIf="book.cod_categoria"
            class="my-2"
          >
            <strong>Categoria:</strong> {{ categoria }}
          </div>
          <div
            *ngIf="book.cod_genere"
            class="my-2"
          >
            <strong>Genere:</strong> {{ genere }}
          </div>

          <div
            *ngIf="1 * book.edizione_anno > 0"
            class="my-2"
          >
            <strong>Edizione :</strong> 20{{
              book.edizione_anno
            }}
            / {{ book.edizione_mese }} Numero :
            {{ book.edizione_numero }}
          </div>

          <div
            *ngIf="book.base != 0 && book.altezza != 0"
            class="my-2"
          >
            <strong>Formato:</strong> {{ book.base }} X
            {{ book.altezza }}
          </div>
          <div
            *ngIf="book.datapubblicazione"
            class="my-2"
          >
            <strong>Data pubblicazione:</strong>
            {{ formatData(book.datapubblicazione) }}
          </div>
          <div
            *ngIf="book.cod_collana"
            class="my-2"
          >
            <strong>Collana:</strong> {{ collana }}
            <span *ngIf="1 * book.n_collana > 0"
              ><strong>Numero collana</strong
              >{{ book.n_collana }}</span
            >
          </div>
          <div
            *ngIf="book.numero_pagine"
            class="my-2"
          >
            <strong>Numero pagine:</strong>
            {{ book.numero_pagine }}
          </div>
          <div
            *ngIf="book.volume != ' '"
            class="my-2"
          >
            <strong>Volume:</strong> {{ book.volume }} di
            {{ book.nVolumi }}
          </div>
          <div
            *ngIf="book.prezzo"
            class="my-2"
          >
            <strong>Prezzo:</strong> {{ book.prezzo }}&nbsp;
            <!-- <span class="my-2"><strong book.iva>IVA:</strong> {{book.cod_iva}}</span> -->
          </div>

          <div
            *ngIf="1 * book.annoGiro"
            class="my-2"
          >
            <strong>Giro :</strong> {{ book.nuGiro }} /
            {{ book.annoGiro }}
          </div>

          <div
            class="my-2"
            *ngIf="book.interesse"
          >
            <strong>Interesse :</strong>
            {{
              book.interesse === 1 ? 'Nazionale' : 'Locale'
            }}
            {{
              book.desInteresse !== ' '
                ? 'Zona : ' + book.desInteresse
                : ''
            }}
          </div>

          <div
            class="my-2"
            *ngIf="book.tags != ''"
          >
            <strong>Tags :</strong>
            <p
              class="description"
              [innerHTML]="book.tags"
            ></p>
          </div>
          <mat-card
            *ngIf="book.recensione"
            class="mt-2"
          >
            <mat-card-title>Abstract</mat-card-title>
            <p
              class="description"
              [innerHTML]="book.recensione"
            ></p>
          </mat-card>
          <mat-card
            *ngIf="book.note"
            class="mt-2"
          >
            <mat-card-title>Note</mat-card-title>
            <p
              class="description"
              [innerHTML]="book.note"
            ></p>
          </mat-card>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
