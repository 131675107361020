<body class="p-5 d-flex justify-content-center align-items-center">
  <div class="row login-container p-3" style="width: 100%">
    <div class="col-6 image-container">
      <img src="../../../assets/images/Writing room-rafiki.svg" class="img-class"/>
    </div>
    <div
      class="col d-flex align-items-center justify-content-center flex-column login-right-container"
    >
      <form
        *ngIf="!forgotPsw && !pswSent; else forgotPswForm"
        class="form-container"
        [formGroup]="form"
        (ngSubmit)="login()"
      >
        <h1 class="mb-3 title">Login to pboox 2.0</h1>

        <mat-form-field appearance="outline" class="input">
          <mat-label>Email</mat-label>
          <input
            matInput
            placeholder="Username"
            formControlName="email"
            type="email"
            mat-autosize="true"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="input">
          <mat-label>Password</mat-label>
          <input
            matInput
            placeholder="Password"
            type="password"
            placeholder="Password"
            formControlName="password"
          />
        </mat-form-field>

        <div>
          <p>
            Password dimenticata?
            <button (click)="forgotPsw = true" type="button" class="psw-button">
              <u>Premi qui</u>
            </button>
          </p>
        </div>

        <button mat-raised-button color="primary" class="loginBtn">
          Log in
        </button>
      </form>

      <ng-template #forgotPswForm>
        <form
          *ngIf="!pswSent; else pswSentTemplate"
          class="form-container"
          [formGroup]="formPSwRecover"
          (ngSubmit)="changePsw()"
        >
          <div></div>
          <h1 class="mb-3 title">Recupero Password</h1>
          <h3>Inserisci la tua mail per recuperare la password</h3>

          <mat-form-field appearance="outline" class="input">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Username"
              formControlName="changePSwEmail"
              type="email"
              mat-autosize="true"
            />
          </mat-form-field>
          <span class="focus-input100"></span>

          <p>
            <button
              (click)="forgotPsw = false"
              type="button"
              class="psw-button"
            >
              <u>Torna indietro</u>
            </button>
          </p>

          <button
            mat-raised-button
            color="primary"
            class="loginBtn"
            [disabled]="!formPSwRecover.valid"
          >
            Recupera La Password
          </button>
        </form>

        <ng-template class="text-align-center" #pswSentTemplate>
          <mat-icon>check_circle</mat-icon>
          <p>Password inviata con successo</p>
          <p>Controllare il proprio indirizzo email per la nuova utenza CDA</p>
          <button class="psw-button" (click)="toLoginForm()">
            Torna al login
          </button>
        </ng-template>
      </ng-template>
      <div class="version-container mb-5">
        <div class="version">Versione {{ version }}</div>
      </div>
    <!--   <app-news class="mt-3" style="width: 80%"> </app-news> -->
    </div>
  </div>
</body>
