<mat-card class="card-container">
  <mat-card-content *ngIf="user.isCliente()">
    <app-giri-cliente
      [giri]="giriCliente"
    ></app-giri-cliente>
    <!-- 
    AREA CLIENTE QUI. 
    AGGIUNGERE IL CALENDARIO DEI GIRI PER IL CLIENTE
    AGGIUNGERE IL COMPONENETE PER SCARICARE I DOCUMENTI
   -->
    <div class="row">
      <div class="col-6">
        <scarica-cedole
          [user]="user"
          type="ep_card-header-warning"
          title="Scarica cedola"
        ></scarica-cedole>
      </div>
      <div class="col-6">
        <app-document-download
          [user]="user"
          [giro]="giriCliente"
        ></app-document-download>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="!user.isCliente()">
    <div class="container-fluid">
      <div
        class="row"
        *ngIf="user.isEditore()"
      >
        <div class="col-12">
          <app-archivio-giri
            *ngIf="
              giri && !loadingItems.giri && giri.length
            "
            [giri]="giri"
          ></app-archivio-giri>
        </div>
      </div>
      <div
        class="row"
        *ngIf="user.isAdmin()"
      >
        <div class="col-4">
          <scarica-cedole
            [user]="user"
            type="ep_card-header-warning"
            title="Scarica cedole cliente"
          ></scarica-cedole>
        </div>
        <div class="col-4">
          <app-download-schede></app-download-schede>
        </div>
        <div class="col-4">
          <generate-report
            [user]="user"
            type="ep_card-header-warning"
            title="Rendiconto Editore"
          ></generate-report>
        </div>
        <div class="col-4">
          <export-images
            type="ep_card-header-primary"
            [subtitle]="lastData"
            [secondSubtitle]="execDate"
            (changeDate)="getDataExport()"
          ></export-images>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-card>
            <mat-form-field appearance="">
              <mat-label>Mostra</mat-label>
              <input
                matInput
                type="number"
                [(ngModel)]="limit"
                (change)="doReport()"
                min="1"
                [disabled]="loadingItems.toplibri"
              />
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="">
              <mat-label>Intervallo date</mat-label>
              <mat-date-range-input
                [formGroup]="range"
                [rangePicker]="picker"
                matTooltip="Seleziona l'intervallo di tempo premendo sul calendario"
                [disabled]="loadingItems.toplibri"
              >
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Data inizio"
                  readonly
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="Data fine"
                  (dateChange)="changeDate()"
                  readonly
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker
                #picker
              ></mat-date-range-picker>
              <mat-error
                *ngIf="
                  range.controls.start.hasError(
                    'matStartDateInvalid'
                  )
                "
                >Data inizio non valida</mat-error
              >
              <mat-error
                *ngIf="
                  range.controls.end.hasError(
                    'matEndDateInvalid'
                  )
                "
                >Data fine non valida</mat-error
              >
            </mat-form-field>
            <label id="example-radio-group-label"
              >Tipo : &nbsp;</label
            >
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              [disabled]="loadingItems.toplibri"
            >
              <mat-radio-button
                class="example-radio-button"
                *ngFor="let type of types"
                [value]="type.cod"
                (change)="getSelectedType($event)"
                [checked]="type.checked"
              >
                {{ type.description }}&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          </mat-card>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-tabella
            [ngClass]="{
              cursor: dataTable.rows.length > 0
            }"
            (rowClick)="openStatsBook($event)"
            [loading]="loadingItems.toplibri"
            [data]="dataTable"
            [scroll]="true"
            title="Top Libri"
            icon="grid_on"
            type="ep_card-header-success"
            style="width: 100%"
          ></app-tabella>
        </div>
        <div class="col-6">
          <app-grafico
            *ngIf="user.isEditore()"
            [loading]="loadingItems.graficoVenduto"
            [chartOption]="chartOption"
            title="Sell IN/Sell OUT"
            subtitle=""
            icon="trending_up"
            type="ep_card-header-success"
            style="width: 100%"
          ></app-grafico>
        </div>
        <div class="col-12">
          <mat-form-field class="giro-select">
            <mat-label>Seleziona Giro</mat-label>
            <mat-select
              (selectionChange)="
                getEditoreAllegatoInfo($event)
              "
            >
              <mat-option
                *ngFor="let giro of giriResume"
                value="{{ giro.cod }}"
              >
                {{ giro.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <app-tabella
            [loading]="loadingItems.editoriAllegato"
            [data]="editoriAllegato"
            [scroll]="true"
            [export]="true"
            (rowClick)="downloadAllegato($event)"
            title="Editori"
            icon="grid_on"
            type="ep_card-header-success"
            style="width: 100%"
          ></app-tabella>
        </div>
      </div>

      <div class="row">
        <div
          class="col-12"
          *ngIf="user.isAdmin()"
        >
          <app-grafico
            [loading]="loadingItems.graficoVenduto"
            [chartOption]="chartOption"
            title="Sell IN/Sell OUT"
            subtitle=""
            icon="trending_up"
            type="ep_card-header-success"
            style="width: 100%"
          ></app-grafico>
        </div>
        <div
          class="col-6"
          *ngIf="user.isEditore()"
        >
          <generate-report
            type="ep_card-header-warning"
            title="Rendiconto"
            [user]="user"
          ></generate-report>
          <app-testo
            [number]="libriCatalogo"
            [loading]="loadingItems.catalogo"
            title="Catalogo"
            subtitle="Numero di libri"
            icon="book"
            type="ep_card-header-success"
            style="width: 100%"
          >
          </app-testo>
          <app-testo
            [number]="libriNovita"
            [loading]="loadingItems.novita"
            title="Novità"
            subtitle="Numero di libri"
            icon="book"
            type="ep_card-header-info"
            style="width: 100%"
          ></app-testo>
        </div>

        <div class="col-6">
          <app-grafico
            *ngIf="user.isEditore()"
            [loading]="loadingItems.graficoGiacenze"
            [chartOption]="pieOption"
            title="Giacenza"
            subtitle=""
            icon="pie_chart"
            type="ep_card-header-danger"
            style="width: 100%"
          ></app-grafico>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
