<nav aria-label="breadcrumb">
    <ol class="matero-breadcrumb">
      <li class="matero-breadcrumb-item"
          *ngFor="let navlink of links; trackBy: trackByNavlink; first as isFirst;">
        <a href="#" class="link" *ngIf="isFirst" (click)="click(navlink)">{{navlink}}</a>
        <ng-container *ngIf="!isFirst">
          <mat-icon class="chevron">chevron_right</mat-icon>
          <span class="link">{{navlink}}</span>
        </ng-container>
      </li>
    </ol>
  </nav>
