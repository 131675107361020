import { iUser } from './../../models/user.model';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { description } from 'src/app/models/books.model';
import { BookService } from 'src/app/services/book.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-lazy-url',
  templateUrl: './lazy-url.component.html',
  styleUrls: ['./lazy-url.component.scss']
})
export class LazyUrlComponent implements OnInit, OnDestroy {
  @Input() itemsLabel:string = '';
  @Input() newItemLabel:string = '';
  @Input() addElementEnabled = false;
  @Output() selectedItems = new EventEmitter<any>();
  @Output() searching = new EventEmitter<any>();
  @Input() disabled = false;
  @Input() loading = false;
  @Input() filtereditems: Array<description> = [];
  @Input() resetFormField:boolean;
  visible = true;
  removable = true;
  itemCtrl = new FormControl();
  item: description;
  subscription: Subscription;
  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  constructor(private bookservice: BookService) {  
    this.subscription = this.itemCtrl.valueChanges.subscribe((data:string)=> {
      //this.resetFormField = false;
    if (data && data.length > 3 && this.loading === false) {
      this.loading = true;
      this.searching.emit({type:this.itemsLabel,value:data});
      if(this.filtereditems && this.filtereditems.length > 0){
        this.loading = false;
      }
    }else{
      this.loading = false;
    }
    if(data.length === 0){
      this.selectedItems.emit({field:this.itemsLabel, value:''});
    }
  });}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.resetFormField){
      this.itemCtrl.setValue('');
      this.itemInput.nativeElement.value = '';
      this.filtereditems = [];
    }
    //this.resetFormField = false;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.loading = false;
    const SelectedElement = _.filter(this.filtereditems, { cod: event.option.value });
    this.item = SelectedElement[0];
    this.itemInput.nativeElement.value = this.item.descrizione;
    this.selectedItems.emit({field:this.itemsLabel, value:this.item});
    this.filtereditems = []; 
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }

}
