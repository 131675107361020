import { LocalStorageService } from 'src/app/services/storage.service';
import { iUser } from './../../models/user.model';
import { BookService } from './../../services/book.service';
import { DomSanitizer } from '@angular/platform-browser';
import { description } from 'src/app/models/books.model';
import { FormControl } from '@angular/forms';
import { Ricerca } from './../../models/ricerca.model';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import * as _ from 'lodash';
import { SessionStorageService } from 'src/app/services/session.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-ricerca',
  templateUrl: './ricerca.component.html',
  styleUrls: ['./ricerca.component.scss']
})
export class RicercaComponent implements OnInit, OnDestroy {
  user: iUser;
  scope : string = '';
  inputScope : string = '';
  cont : number = 0;
  order : string = '';
  form = new FormControl();
  searcheTerm: string = '';
  field: any;
  filtereditems: Array<description> = [];
  @Input() ricerca: Ricerca;
  @Input() singleSearch:boolean = true;
  @Output () searched = new EventEmitter<any>();
  @Output () resetSearch = new EventEmitter<boolean>();
  @Output() sendSubmit = new EventEmitter<boolean>();
  myControl = new FormControl();
  toBeSearched: Array<any> = [];
  risp = [];
  subscription: Subscription[] = [];
  //{event:order, field: [nome campo], value: [asc || desc]}
  //{event:search, field: [nome campo], value: [valore campo]}
  types = [];
  reset: boolean = false;
  constructor(
    private icon: MatIconRegistry, 
    private sanitizer: DomSanitizer, 
    private bookservice: BookService,
    private storage: LocalStorageService,
    private session: SessionStorageService,
     ) { 
    this.icon.addSvgIcon(
      'reset_filter',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/filter-off-48x48.svg')
    )
  }

  ngOnInit(): void {
    this.user = new iUser(this.session.get('usr'));
    //creo l'oggetto di risposta in base ai campi dati in input, inizzializzando il valore di ognuno di esso a null
    if(!this.user.isEditore()){
      this.ricerca.fields.forEach(field=>{
        this.risp.push({name:field.field, value:null});
      });
    }
    const sub = this.form.valueChanges.subscribe((data: any) => {
      this.ricerca.fields.forEach(field => {
        if (this.inputScope !== field.field && this.singleSearch){
          (document.getElementById(field.field.toString()) as HTMLInputElement).value = null;
        } 
      });

      this.searcheTerm = data;
      if(this.singleSearch){
        this.searched.emit({event:'search', field: this.inputScope, value: this.searcheTerm});
      }else{
        let i = this.risp.findIndex(r => r.name === this.inputScope);
        if(this.risp[i]){
          this.risp[i].value = this.searcheTerm ? this.searcheTerm : null;
        }
      }
    });

    this.subscription.push(sub);
  }
  /**
   * Funzione che salva il nome del campo della ricerca in cui sta avvenendo un evento
   * 
   */
  getName(data: any){
    this.inputScope = data.srcElement.name;
  }

  getValues(event){
    let sub;
    switch(event.type){
      case 'autore':
        sub = this.bookservice.autori(event.value).subscribe((autori :Array<description>) => {
          this.filtereditems = autori;
          this.reset = false;
        });
        break;
      case 'editore':
        sub = this.bookservice.editori(event.value).subscribe((editori :Array<description>) => {
          this.filtereditems = editori;
          this.reset = false;
        });
        break;
      case 'argomento':
        sub = this.bookservice.argomenti(event.value).subscribe((argomenti :Array<description>) => {
          this.filtereditems = argomenti;
          this.reset = false;
        });
        break;
      case 'collana':
        sub = this.bookservice.getCollane(event.value).subscribe((collane :Array<description>) => {
          this.filtereditems = collane;
          this.reset = false;
        });
      break;
    }

    this.subscription.push(sub);
  }
  selected(event){
    let i = this.risp.findIndex(r => r.name === event.field);
    this.risp[i].value = event.value.cod;

  }

  orderBy(data: string){
    if(data != this.scope){
      this.scope = data;
      this.cont = 0;
    }
    if(this.cont % 2 != 0){
      this.order = "asc";
    }else{
      this.order = "desc";
      
    }
    this.cont++;
    this.searched.emit({event: 'order', field: data, value: this.order});
  }

  /**
   * Funzione che emitta il reset dei campi della ricerca e reimposta il form
   */
  resetFormField(){
    this.resetSearch.emit(true);
    this.form.setValue('');
    this.reset = true;
    this.scope = '';
    this.order = '';
    this.filtereditems = [];
    this.types = [];
    //svuoto l'oggetto di risposta
    if(!this.user.isEditore()){
      this.risp.forEach(element => {
        element.value = null;
      });
    }

  }


  Onsubmit(){
    //compongo l'oggetto filtri che sara' emittato in formato jsonapi
    let filters = this.risp.filter(r => 
      { 
        if(r.value !== null || r.value !== undefined)
          return r.value;
      });
    this.searched.emit({event:'search', values:filters});
    this.sendSubmit.emit(true);
  }

  ngOnDestroy(): void {
      this.subscription.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
