<mat-card class="py-1" *ngIf="!this.selectedBook && links.length === 0">
  <app-ricerca
    (searched)="getSearchType($event)"
    [ricerca]="ricerca"
    (resetSearch)="resetForm($event)"
    (sendSubmit)="submitButton()"
    [singleSearch]="false"
  ></app-ricerca>
</mat-card>
<mat-card class="py-1 mt-2" *ngIf="!this.selectedBook && links.length > 0">
  <!-- <app-breadcrumps [links]="links" (clicked)="catalogo()"></app-breadcrumps> -->
</mat-card>
<div *ngIf="loader" align="center" class="loader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-card class="card" *ngIf="booksPage.length === 0 && !loader">
  <mat-icon style="vertical-align: middle">info</mat-icon>
  <span> Effettua una ricerca per visualizzare il catalogo</span>
</mat-card>
<mat-card class="card" *ngIf="booksMeta['total'] === 0 && !loader">
  <mat-icon style="vertical-align: middle">error</mat-icon>
  <span> La ricerca non ha prodotto risultati</span>
</mat-card>
<div *ngIf="booksPage" class="tp-wrapper">
  <div class="container-fluid" *ngIf="this.selectedBook != null">
    <mat-card class="book">
      <div align="right" *ngIf="option === 'view'">
        <button mat-raised-button (click)="toggle(null)" color="primary">
          Chiudi Scheda
        </button>
        <button mat-raised-button (click)="modifica()" color="warn">
          Modifica
        </button>
      </div>
      <div align="right" *ngIf="option === 'edit'">
        <button mat-raised-button (click)="toggle(null)" color="primary">
          Chiudi Scheda
        </button>
        <button mat-raised-button color="warn" (click)="confirm()">
          Conferma
        </button>
      </div>
      <div class="row">
        <div class="col-12">
          <app-book *ngIf="option === 'view'" [code]="selectedBook"></app-book>
          <app-modifica-catalogo
            *ngIf="option === 'edit'"
            [code]="selectedBook"
            [process]="salvaModifiche"
            (close)="toggle(null)"
          ></app-modifica-catalogo>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="container-fluid" *ngIf="!selectedBook && !loader">
    <mat-card class="book" *ngFor="let book of booksPage">
      <div class="row" [id]="book.attributes.cod">
        <div class="col-2">
          <app-image-view
            [ean]="book.attributes.ean"
            [type]="'mini'"
          ></app-image-view>
        </div>
        <div class="col-10">
          <h2>{{ book.attributes.titolo | uppercase }}</h2>
          <h3>[ISBN]: {{ book.attributes.ean }}</h3>
          <p>
            Editore :
            <button
              mat-button
              color="accent"
              (click)="searchFromBook('editore', book.attributes.cod_editore)"
            >
              {{ book.attributes.editore | uppercase }}
            </button>
            Autore :
            <button
              mat-button
              color="accent"
              (click)="searchFromBook('autore', book.attributes.cod_autore_1)"
            >
              {{ book.attributes.autore | uppercase }}
            </button>
            Collana
            <button
              mat-button
              color="primary"
              (click)="searchFromBook('collana', book.attributes.cod_collana)"
            >
              {{ book.attributes.collana }}
            </button>
            <span *ngIf="book.attributes.cod_argomento_1 > 0">
              Argomento:
              <button
                mat-button
                color="primary"
                (click)="
                  searchFromBook('argomento', book.attributes.cod_argomento_1)
                "
              >
                {{ book.attributes.argomento }}
              </button>
            </span>
          </p>
          <div class="row">
            <div class="col">
              Data di pubblicazione :
              {{ formatData(book.attributes.data_pubblicazione) }}
            </div>
            <div class="col">Categoria : {{ book.attributes.categoria }}</div>
            <div class="col">Stato : {{ getStato(book.attributes.stato) }}</div>
            <div class="col">
              <b>Giacenza : {{ book.attributes.giacenza | integer }}</b>
            </div>
          </div>
          <div align="right">
            <button
              mat-raised-button
              color="primary"
              id=""
              (click)="toggle(book.attributes.cod)"
            >
              Scheda Libro
            </button>
            <button
              mat-raised-button
              color="primary"
              id=""
              (click)="
                ristampa(book.attributes.ean, book.attributes.cod_editore)
              "
            >
              Ristampa
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<mat-paginator
  *ngIf="!loader && books.length > 0 && !this.selectedBook"
  [length]="booksMeta['total']"
  [pageSize]="20"
  [pageIndex]="page - 1"
  (page)="changePage($event)"
  [showFirstLastButtons]="true"
>
</mat-paginator>
