import { FormControl } from '@angular/forms';
import {LocalStorageService} from '../../services/storage.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
 

@Component({
  selector: 'app-modalita-stampa',
  templateUrl: './modalita-stampa.component.html',
  styleUrls: ['./modalita-stampa.component.scss']
})
export class ModalitaStampaComponent implements OnInit {
  @Input() disabled = false;
  @Input() value ='';
  @Output() selectedStampa = new EventEmitter<Object>();
  @Input() date = null;
  @Input() error = false;
  giro = null;
  data = null;
  
  giorni = ["domenica", "lunedì", "martedì", "mercoledì","giovedì", "venerdì","sabato"];
  mesi = ["gennaio", "febbraio","marzo","aprile","maggio","giugno","luglio","agosto","settembre","ottobre","novembre","dicembre"];
 
  constructor(private storage : LocalStorageService,) {}

  ngOnInit(): void {
    this.giro  = this.storage.get('giro');
    if (this.date) {
    this.data = this.giorni[this.date.getDay()]+" "+this.date.getDate()+" "+this.mesi[this.date.getMonth()]+" "+this.date.getFullYear();
   }
  }
  
  getSelected(event){
    this.selectedStampa.emit({value:event.target.id, giro: this.giro.data_scadenza})
  }

}
