<mat-card class="cedola">
  <mat-card-header>
    <mat-card-title
      style="color: #e64a19"
      class="fs20 text text-dark pointer"
      title="Visualizza i titoli delle cedole"
      >{{ title }}
      <!-- <span class="fa fa-gear fa-spin" *ngIf="loading" ></span> -->
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="card-container">
    <owl-carousel-o
      *ngIf="items.length > 5"
      [options]="customOptions"
      (change)="changeOutput.emit($event)"
    >
      <ng-container *ngFor="let item of items">
        <ng-template carouselSlide [id]="item.ean">
          <div class="img-container card text-center">
            <i
              *ngIf="type !== 'novita'"
              [color]="color"
              [ngClass]="user.profile === 'libreria' ? 'file-libreria' : ''"
              class="fa fa-icon fa-shopping-cart fa-2x"
              style="margin: 5px; padding: 0px; cursor: pointer"
              (click)="itemSelected(item)"
            ></i>
            <i
              *ngIf="type === 'novita'"
              [color]="color"
              [ngClass]="user.profile === 'libreria' ? 'file-libreria' : ''"
              class="fa fa-icon fa-search fa-2x"
              style="margin: 5px; padding: 0px; cursor: pointer"
              (click)="itemSelected(item)"
            ></i>
            <div class="img-container">
              <div *ngIf="rankings" class="isRanked">
                <p class="rankFont">{{ items.indexOf(item) + 1 }}°</p>
              </div>
              <app-image-view
                class="bookCover"
                [ean]="item.ean"
                [type]="'thumb'"
              ></app-image-view>
            </div>
            <p *ngIf="type === 'novita'">
              <b>
                Commercializzazione dal:
                {{ item.data_pubblicazione.slice(6, 8) }}/{{
                  item.data_pubblicazione.slice(4, 6)
                }}/{{ item.data_pubblicazione.slice(0, 4) }}
              </b>
            </p>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <div *ngIf="items.length <= 5" class="d-flex">
      <div
        class="img-container card text-center col-2"
        *ngFor="let item of items"
      >
        <i
          *ngIf="type !== 'novita'"
          [color]="color"
          [ngClass]="user.profile === 'libreria' ? 'file-libreria' : ''"
          class="fa fa-icon fa-shopping-cart fa-2x"
          style="margin: 5px; padding: 0px; cursor: pointer"
          (click)="itemSelected(item)"
        ></i>
        <i
          *ngIf="type === 'novita'"
          [color]="color"
          [ngClass]="user.profile === 'libreria' ? 'file-libreria' : ''"
          class="fa fa-icon fa-search fa-2x"
          style="margin: 5px; padding: 0px; cursor: pointer"
          (click)="itemSelected(item)"
        ></i>
        <div class="img-container">
          <div *ngIf="rankings" class="isRanked">
            <p class="rankFont">{{ items.indexOf(item) + 1 }}°</p>
          </div>
          <app-image-view
            class="bookCover"
            [ean]="item.ean"
            [type]="'thumb'"
          ></app-image-view>
        </div>
        <p *ngIf="type === 'novita'">
          <b>
            Commercializzazione dal:
            {{ item.data_pubblicazione.slice(6, 8) }}/{{
              item.data_pubblicazione.slice(4, 6)
            }}/{{ item.data_pubblicazione.slice(0, 4) }}
          </b>
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
