<div class="container-fluid my-1 py-1 cartItemContainer mat-elevation-z2">
  <div class="row">
    <div class="mycol col-3 d-flex align-items-center">
      <app-image-view [ean]="item.ean" [type]="'mini'"></app-image-view>
    </div>
    <div class="mycol col-9">
      <div>
        <strong>{{ item.titolo | uppercase }} </strong>
      </div>
      <div>
        <h4>ISBN:{{ item.ean }}</h4>
      </div>
      <div class="cart-item-row">
        <table>
          <tr>
            <th>Qta.</th>
            <th>Prezzo</th>
          </tr>
          <tr>
            <td>
              <div>
                <input
                  type="number"
                  matInput
                  min="1"
                  style="max-width: 70px"
                  [(ngModel)]="item.qta"
                  (ngModelChange)="qtaChanged($event)"
                />
              </div>
            </td>
            <td>{{ item.prezzo | currency }}</td>
          </tr>
        </table>
        <button mat-raised-button (click)="delete(item.ean)" color="warn">
          elimina
        </button>
      </div>
    </div>
  </div>
</div>
