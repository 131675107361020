import { BookService } from 'src/app/services/book.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { dataTable } from 'src/app/models/dataTable.model';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-giacenza-dialog',
  templateUrl: './giacenza-dialog.component.html',
  styleUrls: ['./giacenza-dialog.component.scss']
})
export class GiacenzaDialogComponent implements OnInit, OnDestroy {
  giacenza: dataTable = {
    header: [
      'descrizione', 'giacenza fisica', 'deposito', 'ultimo aggiornamento'
    ],
    rows: [
    ]
  };
  subscription: Subscription = null;
  constructor(
    public dialogRef: MatDialogRef<GiacenzaDialogComponent>,
    private bookservice: BookService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.dialogContent();
  }

  onUndoClick() {
    this.dialogRef.close();
  }

  dialogContent() {
    this.giacenza.rows = [];
    this.subscription = this.bookservice.getBookStats(this.data.codice, 'giacenza', false).subscribe((data: any) => {
      data.forEach(element => {
        this.giacenza.rows.push(
          [
            element.descrizione_mag,
            (Number(element.giacenza_fisica) === 0) ? '' : Number(element.giacenza_fisica),
            (Number(element.deposito) === 0) ? '' : Number(element.deposito),
            (element.ultimo_aggiornamento != '' && element.ultimo_aggiornamento != null) ? this.formatData(element.ultimo_aggiornamento) : '' // annomeseanno
          ]
        )
      });
    });
  }

  formatData(data) {
    return moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY');
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }
}
