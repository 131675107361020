import { OrdiniService } from 'src/app/services/ordini.service';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { formatCurrency } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DisplayTableComponent } from 'src/app/components/display-table/display-table.component';
import { Utilities } from 'src/app/utilities/utilities.class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ordini',
  templateUrl: './ordini.component.html',
  styleUrls: ['./ordini.component.scss'],
})
export class OrdiniComponent implements OnInit, OnDestroy {
  constructor(
    private ordiniservice: OrdiniService,
    public dialog: MatDialog
  ) {}
  loader = false;
  rowsLoader = false;
  ricerca = '';
  public page = 1;
  start = null;
  stop = null;
  @ViewChild(MatSort) sort: MatSort;
  defaultSort = 'data_ordine';
  defaultSortDir = 'desc';
  displayedColumns: string[] = [
    'nr_ordine',
    'causale_ordine',
    'stato',
    'data_ordine',
    'riferimento',
    'data_riferim',
    'valore_ordine',
    'valore_rimanenza',
    'descrizione_pagamento',
    'scadenza',
  ];
  columnsName: string[] = [
    'Nr.Ordine',
    'Causale',
    'Stato',
    'Data',
    'Riferimento',
    'Data riferimento',
    'Valore Ordine',
    'Rimanenza',
    'Pagamento',
    'GG. Scadenza',
  ];
  dataSource;
  expandedElement: any | null;
  dataExport = [];
  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loader = true;
    const sub = this.ordiniservice
      .getAllOrdini()
      .subscribe((data: any) => {
        const keyToCast = [
          'valore_ordine',
          'valore_rimanenza',
        ];
        this.dataSource = new MatTableDataSource(
          this.buildDataSource(
            Utilities.castToNumber(data.data, keyToCast)
          )
        );
        this.dataExport = this.buildDataExport(data.data);
        this.loader = false;
      });

    this.subscriptions.push(sub);
  }

  buildDataSource(collection: any) {
    let dataSource = [];
    collection.forEach((item) => {
      dataSource.push(item);
    });
    return _.orderBy(
      collection,
      [this.defaultSort, 'nr_ordine'],
      [this.defaultSortDir, 'desc']
    );
  }

  buildDataExport(collection: any) {
    let dataExport = [];
    let row = {};
    collection.forEach((element) => {
      this.displayedColumns.forEach((column, index) => {
        row[this.columnsName[index]] = element[column];
      });
      dataExport.push(row);
      row = {};
    });
    return dataExport;
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement)
      .value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public addEvent() {
    this.getData();
  }

  total(columnName) {
    const totals = ['valore_ordine', 'valore_rimanenza'];
    if (totals.indexOf(columnName) > -1) {
      return formatCurrency(
        _.sumBy(this.dataSource.data, function (o) {
          return Number(o[columnName]);
        }),
        'IT',
        ''
      );
    }
  }

  openRighe(element: any) {
    this.rowsLoader = true;
    const sub = this.ordiniservice
      .getRigheOrdine(element.id_ordine)
      .subscribe((data: any) => {
        const displayedColumns: string[] = [
          'nr_riga',
          'stato',
          'stato_articolo',
          'ean',
          'titolo',
          'editore',
          'qta_ordine',
          'qta_spedita',
          'qta_rimanenza',
          'prezzo',
          'sconto_base',
          'sc1',
          'sc2',
          'sc3',
          'sconto',
          'totale_riga',
        ];
        const columnsName: string[] = [
          'Nr.Riga',
          'Stato riga',
          'Stato articolo',
          'Ean',
          'Titolo',
          'Editore',
          'Ordinato',
          'Spedito',
          'Rimanenza',
          'Prezzo',
          'sb',
          'sc1',
          'sc2',
          'sc3',
          'Sconto',
          'Totale',
        ];
        const dialogRef = this.dialog.open(
          DisplayTableComponent,
          {
            //  width: '1000px',
            data: {
              data: this.findSconto(data.data),
              displayNames: columnsName,
              fieldNames: displayedColumns,
              title:
                'Specifica ordine nr. ' + element.nr_ordine,
              numbers: [
                'prezzo',
                'totale_riga',
                'qta_rimanenza',
                'qta_spedita',
                'qta_ordine',
                'qta_in_spedizione',
              ],
              totali: ['totale_riga'],
            },
          }
        );
        this.rowsLoader = false;
      });

    this.subscriptions.push(sub);
  }

  sortData($event) {
    this.defaultSortDir = $event.direction;
    this.defaultSort = $event.active;
    this.dataSource = new MatTableDataSource(
      this.buildDataSource(this.dataSource.data)
    );
  }

  getClass(fieldName) {
    const numbers = ['valore_ordine', 'valore_ordine'];
    const dates = ['data_ordine', 'data_riferim'];

    if (numbers.indexOf(fieldName) > -1) {
      return 'number';
    }

    if (dates.indexOf(fieldName) > -1) {
      return 'date';
    }
  }

  format(field, value) {
    /**'nr_ordine', 'causale_ordine', 'data_ordine', 'descrizione_canale' ,'valore_ordine' , 'valore_rimanenza',  'descrizione_pagamento' */
    const valute = ['valore_ordine', 'valore_rimanenza'];
    const dates = ['data_ordine', 'data_riferim'];
    const numbers = ['nr_ordine'];

    if (numbers.indexOf(field) > -1) {
      if (Number(value) > 0) {
        return value;
      }
      return;
    }

    if (valute.indexOf(field) > -1) {
      // caso valuta
      if (Number(value) > 0) {
        return formatCurrency(value, 'IT', '');
      }
      return;
    }
    // caso date
    if (dates.indexOf(field) > -1) {
      if (Number(value) >> 20000000) {
        return moment(value, 'YYYYMMDD').format(
          'DD-MM-YYYY'
        );
      }
      return;
    }
    return value;
  }

  findSconto(data) {
    //raggruppo per numero ordine
    const groupedOrders = _.groupBy(data, 'ean');
    let rows = [];
    Object.keys(groupedOrders).forEach((key) => {
      //sommo tutti gli ordini omaggio
      let giftedOrders = _.filter(groupedOrders[key], [
        'sconto_base',
        '99',
      ]);
      let totalGiftedQuantity = _.sumBy(
        giftedOrders,
        function (o) {
          return Number(o.qta_rimanenza);
        }
      );
      //sommo tutti gli ordini non in omaggio
      let regularOrders = _.filter(
        groupedOrders[key],
        function (o) {
          return Number(o.sconto_base) < 99;
        }
      );
      let totalRegularQuantity = _.sumBy(
        regularOrders,
        function (o) {
          return Number(o.qta_rimanenza);
        }
      );
      //concateno il valore finale
      groupedOrders[key][0].qta_rimanenza =
        totalGiftedQuantity && totalRegularQuantity
          ? `${totalRegularQuantity}+${totalGiftedQuantity} omaggio`
          : groupedOrders[key][0].qta_rimanenza;
      groupedOrders[key][0].qta_ordine =
        totalGiftedQuantity && totalRegularQuantity
          ? `${totalRegularQuantity}+${totalGiftedQuantity} omaggio`
          : groupedOrders[key][0].qta_ordine;
      //groupedOrders[key][0].sconto_base = "40";
      if (Number(groupedOrders[key][0].sconto) === 99) {
        groupedOrders[key][0].sconto = 'omaggio';
      }
      rows.push(groupedOrders[key][0]);
    });
    return rows;
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
/*
  @todo togliere date ordini
  */
