import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentService } from 'src/app/services/document.service';
import { orderBy, filter } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';


export interface Scadenza {
  ditta: string;
  cliente: string;
  dir_ric: string;
  a_saldo: number;
  registro: string;
  num_fattura: string;
  data_fattura: string;
  descrizione: string;
  cod_rappresentante: string;
  data_scadenza: string;
  giorni_scadenza: number;
  cod_pagamento: string;
  desc_pag: string;
  tipo: string;
}

@Component({
  selector: 'app-scadenze',
  templateUrl: './scadenze.component.html',
  styleUrls: ['./scadenze.component.scss']
})
export class ScadenzeComponent implements OnInit, OnDestroy {
  loader = false;
  subscription: Subscription = null;
  dataSource: MatTableDataSource<Scadenza>;
  columnsName: string[] = ['Nr.Fattura', 'Descrizione', 'Data Fattura', 'Scadenza', 'GG.Scadenza', 'Pagamento', 'Da pagare', 'Tipo']
  displayedColumns: string[] = ['num_fattura', 'descrizione', 'data_fattura', 'data_scadenza', 'giorni_scadenza','desc_pag', 'a_saldo', 'tipo', 'id_fattura'];
  dataExport = [];
  @ViewChild(MatSort) sort: MatSort; 
  constructor(private service: DocumentService, private toast: ToastrService) {}

  ngOnInit(): void {
    this.getData();
  
  }
  
  getData() {
    this.loader = true;
    this.subscription = this.service.getScadenze().subscribe((r:any) => {
        // applico l'ordinamento di default
        const collection = orderBy(r.data, ['data_scadenza'], ['asc']); 
        collection.forEach((element:Scadenza) => {
          element.a_saldo = Number(element.a_saldo);
          element.giorni_scadenza = Number(element.giorni_scadenza);
        }); 
        this.dataSource = new MatTableDataSource(collection);

        this.dataExport = this.buildDataExport(this.dataSource.data);  
        if (this.dataSource && this.dataSource.sort) { 
          this.dataSource.sort = this.sort;
        } 
  
        this.loader = false;
    });
  }


  buildDataExport(collection: Array<any>){
    let dataExport = [];
    let row= {};
    collection.forEach(element =>{
      this.displayedColumns.forEach((column, index) => {
        row[this.columnsName[index]]=element[column];
      });
      dataExport.push(row);
      row = {};
    }); 
    return dataExport;
  }
 

  applyFilter(event: Event) { 
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase(); 
  }
 
  getTotalPagare() {
      const daPagare = filter(this.dataSource.data, function(o) { return o.giorni_scadenza <= 0 });
      let totale = 0;

      daPagare.forEach((element) => {
          totale = eval(totale + element.a_saldo);
      });
      return totale;
  }

  getTotalPagati() {
    const pagati = filter(this.dataSource.data, function(o) { return o.giorni_scadenza > 0 });
    let totale = 0;

    pagati.forEach((element) => {
        totale = eval(totale + element.a_saldo);
    });
    return totale;
  }


  sortData($event) { 
    const orderBy = $event.active;
    const direction = $event.direction;
    const collection =  orderBy(this.dataSource.data, [orderBy], [direction]);
    this.dataSource = new MatTableDataSource(collection);
  }

  downloadPdf(type: string, id: string){ 
    
  const request = this.service.fileRequest(type, id);
    fetch(request, {cache: "reload"})
    .then(response => { 
      if(response.status === 200){
      response.blob().then(blob =>{
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `${type}_${id}.pdf`;
          a.click(); 
          //this.file = URL.createObjectURL(blob); 
        });  
      }else{ 
        this.toast.error('File non trovato');
      }
    });
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }
}
