<div class="ep_card">
  <div
    class="ep_card-header ep_card-header-icon"
    [ngClass]="type"
  >
    <div class="ep_card-icon">
      <i class="material-icons">{{ icon }}</i>
    </div>
    <p class="ep_card-category">{{ title }}</p>
    <h3 class="ep_card-title">{{ subtitle }}</h3>
  </div>
  <div class="ep_card-footer">
    <div class="row">
      <div
        class="col-4"
        *ngIf="!user.isCliente()"
      >
        <mat-form-field class="full-width">
          <mat-label>Seleziona un Cliente</mat-label>
          <select
            matNativeControl
            [(ngModel)]="clienteSelected"
          >
            <option
              *ngFor="let item of clienti"
              value="{{ item.cod }}"
            >
              {{ item.descrizione }}
            </option>
          </select>
          <mat-spinner
            matSuffix
            [diameter]="15"
            class="mx-auto"
            *ngIf="loader.clienti"
          ></mat-spinner>
        </mat-form-field>
      </div>
      <div
        [ngClass]="!user.isCliente() ? 'col-4' : 'col-6'"
      >
        <mat-form-field class="full-width">
          <mat-label>Seleziona un giro</mat-label>
          <select
            matNativeControl
            [(ngModel)]="giroSelected"
          >
         
            <option
              *ngFor="let item of giri"
              value="{{ item.cod }}"
            >
              {{ item.description }}
            </option>
          </select>
          <mat-spinner
            matSuffix
            [diameter]="15"
            class="mx-auto"
            *ngIf="loader.giri"
          ></mat-spinner>
        </mat-form-field>
      </div>
      <div
        [ngClass]="!user.isCliente() ? 'col-4' : 'col-6'"
      >
        <mat-form-field class="full-width">
          <mat-label>Seleziona tipo file</mat-label>
          <select
            matNativeControl
            [(ngModel)]="typeSelected"
          >
            <option
              value="pdf"
              default
            >
              PDF
            </option>
            <option value="excel">Excel</option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <button
      mat-raised-button
      color="primary"
      *ngIf="
        giroSelected &&
        clienteSelected &&
        typeSelected &&
        !loader.download
      "
      (click)="scaricaCedola()"
    >
      Scarica cedole
    </button>
    <button
      *ngIf="loader.download"
      mat-raised-button
      color="link"
    >
      Sto scaricando...
      <mat-icon
        ><mat-spinner
          diameter="20"
          color="accent"
        ></mat-spinner
      ></mat-icon>
    </button>
  </div>
</div>
