import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { description } from "src/app/models/books.model";
import { BookService } from "src/app/services/book.service";
import * as _ from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: "app-seleziona-collaboratore",
  templateUrl: "./seleziona-collaboratore.component.html",
  styleUrls: ["./seleziona-collaboratore.component.scss"],
})
export class SelezionaCollaboratoreComponent implements OnInit, OnChanges, OnDestroy {
  @Input() items: any[] = [];
  @Input() addElementEnabled = false;
  filtereditems: description[];
  itemCtrl = new FormControl();
  loading = false;
  subscriptions: Subscription[] = [];

  @Output() selectedItems = new EventEmitter<any>();
  @Output() selecteType = new EventEmitter<any>();
  @Output() addElement = new EventEmitter<boolean>();

  @ViewChild("collabInput") itemInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  constructor(private apiservice: BookService) {
    const sub = this.itemCtrl.valueChanges.subscribe((data) => {
      if (data && data.length > 3 && !this.loading) {
        this.loading = true;
        const sub =  this.apiservice.autori(data).subscribe((autori: Array<description>) => {
          this.filtereditems = autori;
          this.loading = false;
        });

        this.subscriptions.push(sub);
      }
    });

    this.subscriptions.push(sub);
  }

  ngOnChanges(changes: SimpleChanges): void {
  
    if (changes.items.previousValue) {
      this.items = changes.items.currentValue;
    } 
  }

  ngOnInit(): void {
   
  }

  /**
   * 
   * @param event 
   * @param tipo 
   */
  selected(event: MatAutocompleteSelectedEvent, tipo): void {
    const SelectedElement = _.filter(this.filtereditems, {
      cod: event.option.value,
    });
    SelectedElement[0]["type"] = "curatore";
    if (this.items.length < 5) {
      this.items.push(SelectedElement[0]);
      this.itemInput.nativeElement.value = "";
      this.selectedItems.emit(this.items);
    }
    this.itemCtrl.setValue(null);
    this.filtereditems = [];
  }

  /**
   * elimino un autore
   * @param item nome autore
   */
  remove(item: description): void {
    const index = _.findIndex(this.items, item);
    this.items.splice(index, 1);
    this.selectedItems.emit(this.items);
  }

  changeType(event, item) {
    const index = _.indexOf(this.items, item);
    this.items[index]["type"] = event.value;

    this.selectedItems.emit(this.items);
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
