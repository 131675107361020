import { formatCurrency } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-fattura',
  templateUrl: './fattura.component.html',
  styleUrls: ['./fattura.component.scss']
})
export class FatturaComponent implements OnInit {
  rows = [];
  @Input() data = []; 
  columnsName =  [ 'imponibile', 'iva', 'desc_iva', 'totale'];
  dataSource;
  displayNames: string[] = [ 'Imponibile', 'IVA', 'Aliquota IVA', 'Totale']; //, 'iva', 'prezzo','totale', 'desc_iva' 

  constructor() { }

  ngOnInit(): void {
   let idata = [];
   this.data.forEach((item) => {
     item['totale'] = item.iva + item.imponibile;
     idata.push(item);
   });
   this.dataSource = new MatTableDataSource(idata); 

  }
  
  formatCur(value) {
     return formatCurrency(value, 'IT', ''); 
  }

  sortData($event) {}
}
