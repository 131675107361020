import { iUser } from './../../../models/user.model';
import { BookService } from './../../../services/book.service';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { description } from 'src/app/models/books.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss'],
})
export class GenerateReportComponent
  implements OnInit, OnDestroy
{
  @Input() title = '';
  @Input() subtitle = '';
  @Input() icon = 'receipt';
  @Input() type = '';
  @Input() user: iUser;
  public anni = [];
  public mesi = [];
  public editori = [];
  public selected = {
    mese: null,
    anno: null,
    editore: null,
  };

  public loader = {
    editore: false,
    anno: false,
    mese: false,
  };

  subscriptions: Subscription[] = [];

  constructor(
    private bookservice: BookService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.user.isAdmin()) {
      this.getEditoriList();
    } else {
      this.loader.editore = false;
      this.selected.editore = this.user.code;
      this.getAnniList();
    }
  }

  async getEditoriList() {
    this.loader.editore = true;
    const sub = this.bookservice
      .editoriReport().subscribe({
        next: (data: description[]) => this.editori = data,
        error: (err) => console.error(err),
        complete: () => this.loader.editore = false
      })
     
    this.subscriptions.push(sub);
  }

  public getAnniList() {
    this.loader.anno = true;
    const sub = this.bookservice
      .anniReport(this.selected.editore)
      .subscribe((data: any) => {
        this.loader.anno = false;
        this.anni = data;
      });

      this.subscriptions.push(sub);
  }

  public getMesiList() {
    this.loader.mese = true;
    const sub = this.bookservice
      .mesiReport(this.selected.editore, this.selected.anno)
      .subscribe((data: any) => {
        this.loader.mese = false;
        this.mesi = data;
      });

    this.subscriptions.push(sub);
  }

  public printMonth(mese: number): string {
    moment.locale('it');
    const m = moment(new Date());
    m.set({ month: mese - 1 });
    return m.format('MMMM');
  }

  /**
   * scarico il rendiconto per l'editore corrente se l'account è editore
   * per quello selezionato se l'account è admin
   * @param type se è PDF o XLSX
   */
  public scaricaRendiconto(type: string) {
    const filetype =
      type === 'pdf'
        ? 'pdf'
        : 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const sub = this.bookservice
      .downloadReport(
        this.selected.editore,
        this.selected.anno,
        this.selected.mese,
        type
      )
      .subscribe((responseData: any) => {
        const blob = new Blob([responseData], {
          type: `application/${filetype}`,
        });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.click();
        /* const myWindow = window.open(a.href, "My Window"); */
      });

    this.subscriptions.push(sub);
  }

  /**
   * Scarcio un documento excel con i dati di sell-in o sell-out
   * @param type se è sell-in o sell-out
   */
  public donwloadSellInOut(type: string) {
    this.toast.info('Il download sta per inziare');

    const sub = this.bookservice
      .getResourceFile(
        `report/${type}`,
        `${this.selected.anno.trim()}/${this.selected.mese.trim()}`
      )
      .subscribe({
        next: (responseData: any) => {
          const blob = new Blob([responseData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          0;
          a.download = `${type}_${this.selected.anno}_${this.selected.mese}`;
          a.click();
          //const myWindow = window.open(a.href, "My Window");
        },
        error: (err) => {
          this.toast.error(
            'Errore nel download della risorsa'
          );
          console.error(err);
        },
      });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
