import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  get(key: string) {
    const i = sessionStorage.getItem(key);
    if (i !== null){
      return JSON.parse(i);
    }
    return null;
  }
    
  set(key: string, value: any): boolean {
    sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  }
    
  remove(key: string): void {
    sessionStorage.removeItem(key);
  }
    
  clear(): void {
    sessionStorage.clear();
  }
}
