<div class="w-full d-flex justify-content-between">
  <h1 mat-dialog-title class="title mr-15">
    {{ data.item.titolo
    }}<span *ngIf="data.rankings"> - {{ data.position }}° posto</span>
  </h1>
  <button
    mat-mini-fab
    [mat-dialog-close]="data"
    color="default"
    class="icon-right mt-10 mb-10 ml-15"
  >
    <mat-icon mat-list-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content *ngIf="!this.loading">
  <div class="container">
    <div class="row">
      <div class="col-5">
        <div class="imageContainer">
          <app-image-view
            [ean]="data.item.ean"
            [type]="'thumb'"
          ></app-image-view>
        </div>
      </div>
      <div class="col-7 d-flex align-items-center">
        <table class="table table-borderd imageDescription">
          <tr>
            <th>EAN :</th>
            <td class="text text-info" [noWrap]="true">
              {{ data.item.ean }}
            </td>
          </tr>
          <tr>
            <th>Autore/i :</th>
            <td>{{ articolo.autore_1 }}</td>
            <td>{{ articolo.autore_2 }}</td>
          </tr>
          <tr>
            <td></td>
            <td>{{ articolo.autore_3 }}</td>
            <td>{{ articolo.autore_4 }}</td>
          </tr>
          <tr>
            <th>Editore :</th>
            <td>{{ articolo.editore }}</td>
          </tr>
          <tr *ngIf="articolo.collana">
            <th>Collana :</th>
            <td>{{ articolo.collana }}</td>
          </tr>
          <tr>
            <th>Pubblicazione :</th>
            <td>{{ articolo?.datapubblicazione | date: "shortDate" }}</td>
          </tr>
          <tr>
            <th>Nr. Pagine :</th>
            <td>{{ articolo.numero_pagine }}</td>
          </tr>
          <tr>
            <th>Tipo prodotto</th>
            <td>{{ articolo.categoria }}</td>
          </tr>
          <tr>
            <th>Prezzo</th>
            <td>{{ articolo.prezzo | currency: "EUR" }}</td>
          </tr>
          <tr>
            <th>Stato</th>
            <td>{{ articolo | statoArticolo }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="this.data.type !== 'novita'">
      <div class="col-12">
        <app-order-quantity
          [codice]="articolo.ean"
          (editQta)="setQta($event)"
        ></app-order-quantity>
      </div>
    </div>
  </div>
</div>
