import { CatalogoClienteComponent } from './components/cliente/catalogo-cliente/catalogo-cliente.component';
import { HttpClientModule } from '@angular/common/http';
import { RicercaComponent } from './components/ricerca/ricerca.component';
import { SafeUrlPipe } from './pipes/safe-resource-url.pipe';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component'; 
import { ImageComponent } from './components/image/image.component';
import { HeaderComponent } from './components/header/header.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { PageComponent } from './pages/page/page.component';
import { LanciComponent } from './pages/lanci/lanci.component';
import { StatisticheComponent } from './pages/statistiche/statistiche.component';
import { AutoreComponent } from './components/autore/autore.component'; 
import { ProfiloComponent } from './pages/profilo/profilo.component';
import { PdfUploaderComponent } from './components/pdf-uploader/pdf-uploader.component';
import { ChipsComponent } from './components/chips/chips.component';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { ToastrModule } from 'ngx-toastr';
import { GraficoComponent } from './components/grafico/grafico.component';
import { TestoComponent } from './components/testo/testo.component';
import { TabellaComponent } from './components/tabella/tabella.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { BreadcrumpsComponent } from './components/breadcrumps/breadcrumps.component';
import { BookComponent } from './components/book/book.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecapLancioComponent } from './components/recap-lancio/recap-lancio.component';
import { ElencoNovitaComponent } from './components/elenco-novita/elenco-novita.component';
import { BookFormComponent } from './components/book-form/book-form.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { NuovoAutoreComponent } from './components/nuovo-autore/nuovo-autore.component';
import { ArchivioGiriComponent } from './components/archivio-giri/archivio-giri.component';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { ImagesListComponent } from './components/images-list/images-list.component';
import { ModalitaStampaComponent } from './components/modalita-stampa/modalita-stampa.component';
import { ModificaCatalogoComponent } from './components/modifica-catalogo/modifica-catalogo.component';
import { DeleteQuestionComponent } from './components/delete-question/delete-question.component';
import { NovitaComponent } from './components/admin/novita/novita.component'; 
import { CatalogoComponent  as catalogoadmin } from './components/admin/catalogo/catalogo.component';
import { GiacenzaDialogComponent } from './components/giacenza-dialog/giacenza-dialog.component';
import { LazyUrlComponent } from './components/lazy-url/lazy-url.component';
import { GenerateReportComponent } from './components/admin/generate-report/generate-report.component';
import { ExportImagesComponent } from './components/admin/export-images/export-images.component';
import { GiriClienteComponent } from './components/cliente/giri-cliente/giri-cliente.component';
import { DocumentDownloadComponent } from './components/cliente/document-download/document-download.component';
import { NovitaClienteComponent } from './components/cliente/novita-cliente/novita-cliente.component';
import { ScaricaCedoleComponent } from './components/admin/scarica-cedole/scarica-cedole.component';
import { InsertBookComponent } from './components/admin/insert-book/insert-book.component';
import { BookClienteComponent } from './components/cliente/book-cliente/book-cliente.component';
import { DownloadSchedeComponent } from './components/admin/download-schede/download-schede.component';
import { MovimentiLibrerieDialogComponent } from './components/movimenti-librerie-dialog/movimenti-librerie-dialog.component';
import { ExportTableComponent } from './components/export-table/export-table.component';
import { ElencoDdtComponent } from './pages/elenco-ddt/elenco-ddt.component';
import { CurrencyPipe } from './pipes/currency.pipe';
import { DisplayTableComponent } from './components/display-table/display-table.component';
import {MatTableModule} from '@angular/material/table';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { MatSortModule } from '@angular/material/sort';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { ScontoPipe } from './pipes/sconto.pipe';
import { BookTitlePipe } from './pipes/book-title.pipe';
import { ElencoFattureComponent } from './pages/elenco-fatture/elenco-fatture.component';
import { FatturaComponent } from './pages/elenco-fatture/fattura/fattura.component';
import { OrdiniComponent } from './pages/ordini/ordini.component';
import { StatoArticoloPipe } from './pipes/stato-articolo.pipe';
import { StatoDescrizionePipe } from './pipes/stato-descrizione.pipe';
import { ScadenzeComponent } from './pages/scadenze/scadenze.component';
import { PrintComponent } from './components/print/print.component';
import { ImportaOrdineComponent } from './pages/importa-ordine/importa-ordine.component';
import { ArticoliOrdineComponent } from './pages/articoli-ordine/articoli-ordine.component';
import { ViewOrderComponent } from './pages/articoli-ordine/view-order/view-order.component';
import { CarrelloComponent } from './components/carrello/carrello.component';
import { CartItemComponent } from './components/carrello/cart-item/cart-item.component';
import { OrderQuantityComponent } from './components/order-quantity/order-quantity.component';
import { RistampaDialogComponent } from './components/ristampa-dialog/ristampa-dialog.component';
import { DashboardLibreriaComponent } from './components/libreria/dashboard-libreria/dashboard-libreria.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';  
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BookModalComponent } from './components/book-modal/book-modal.component';
import { IntegerPipe } from './pipes/integer.pipe';
import { PdfListDialogComponent } from './pages/dashboard/pdf-list-dialog/pdf-list-dialog.component';
import { StoricoNovitaComponent } from './pages/storico-novita/storico-novita.component';
import { CircolariComponent } from './circolari/circolari.component';
import { MailModalComponent } from './circolari/mail-modal/mail-modal.component';
import { NewsComponent } from './components/news/news.component';
import { SelezionaCollaboratoreComponent } from './components/seleziona-collaboratore/seleziona-collaboratore.component';
import { DialogCambioGiroComponent } from './components/dialog-cambio-giro/dialog-cambio-giro.component';


const ENTRYCOMPONENTS = [ NuovoAutoreComponent, DeleteQuestionComponent, GiacenzaDialogComponent ];

registerLocaleData(localeIt);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CatalogoComponent, 
    ImageComponent,
    HeaderComponent,
    UserSettingsComponent,
    PageComponent,
    LanciComponent,
    StatisticheComponent,
    AutoreComponent, 
    ProfiloComponent,
    PdfUploaderComponent,
    ChipsComponent,
    ImageViewComponent,
    GraficoComponent,
    TestoComponent,
    TabellaComponent,
    BreadcrumpsComponent,
    BookComponent,
    RecapLancioComponent,
    ElencoNovitaComponent,
    RicercaComponent,
    BookFormComponent,
    FormatDatePipe,
    SafeUrlPipe,
    NuovoAutoreComponent,
    ArchivioGiriComponent,
    DateDiffPipe,
    ImagesListComponent,
    ModalitaStampaComponent,
    ModificaCatalogoComponent,
    DeleteQuestionComponent,
    NovitaComponent,
    catalogoadmin,
    GiacenzaDialogComponent,
    LazyUrlComponent,
    GenerateReportComponent,
    ExportImagesComponent,
    GiriClienteComponent,
    DocumentDownloadComponent,
    CatalogoClienteComponent,
    NovitaClienteComponent,
    ScaricaCedoleComponent,
    InsertBookComponent,
    BookClienteComponent,
    DownloadSchedeComponent,
    MovimentiLibrerieDialogComponent,
    ExportTableComponent,
    ElencoDdtComponent,
    CurrencyPipe,
    DisplayTableComponent,
    AlertBoxComponent,
    ScontoPipe,
    BookTitlePipe,
    ElencoFattureComponent,
    FatturaComponent,
    OrdiniComponent,
    StatoArticoloPipe,
    StatoDescrizionePipe,
    ScadenzeComponent,
    PrintComponent,
    ImportaOrdineComponent,
    ArticoliOrdineComponent,
    ViewOrderComponent,
    CarrelloComponent,
    CartItemComponent,
    OrderQuantityComponent,
    RistampaDialogComponent,
    DashboardLibreriaComponent,
    ImageCarouselComponent,
    BookModalComponent,
    IntegerPipe,
    PdfListDialogComponent,
    StoricoNovitaComponent,
    CircolariComponent,
    MailModalComponent,
    NewsComponent,
    SelezionaCollaboratoreComponent,
    DialogCambioGiroComponent
   ],
  imports: [
    BrowserModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,  
    RouterModule,
    CarouselModule 
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'}
  ],
  bootstrap: [AppComponent],
  entryComponents: ENTRYCOMPONENTS
})
export class AppModule { }
