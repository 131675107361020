import { MessageService } from "./../../services/message.service";
import { DeleteQuestionComponent } from "./../delete-question/delete-question.component";
import { LocalStorageService } from "./../../services/storage.service";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { BookService } from "../../services/book.service";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import * as _ from "lodash";
import { Book } from "src/app/models/books.model";
import { Subscription } from "rxjs";

export interface novita {
  cod: string;
  oid: string;
  ean: string;
  titolo: string;
  cod_autore_1: string;
  cod_editore: string;
  editore: string;
  autore: string;
  cod_argomento_1: string;
  argomento?: string;
  anno_giro: string;
  numero_giro: string;
  ristampa: string;
  data_pubblicazione: string;
  rich_stampa: string;
  stato: string;
  novita: string;
  prezzo: string;
  cod_collana: string;
  ncol: string;
  collana: string;
}

@Component({
  selector: "app-elenco-novita",
  templateUrl: "./elenco-novita.component.html",
  styleUrls: ["./elenco-novita.component.scss"],
})
export class ElencoNovitaComponent implements OnInit, OnDestroy {
  @Output() OnClickEdit = new EventEmitter<any>();
  public books: Array<novita>;
  public loading = true;
  @Input() firstGiro = null;
  subscriptions: Subscription[] = [];


  constructor(
    private bookservice: BookService,
    private storage: LocalStorageService,
    private dialog: MatDialog,
    private toastservice: ToastrService,
    private messageservice: MessageService
  ) {}

  ngOnInit(): void {
    this.getNovita();
  }

  isNovita(book) {
    const giro = Number(this.firstGiro.anno + Number(this.firstGiro.numero));
    const giroLibro = Number(book.anno_giro + Number(book.numero_giro));
    if (giroLibro === giro || giroLibro > giro) {
      return true;
    } else {
      return false;
    }
  }

  getNovita() {
    this.loading = true;
    const sub = this.bookservice.getNovita().subscribe(
      (data: Array<novita>) => {
        this.loading = false;
        this.books = _.orderBy(data, ["data_pubblicazione"]);

        return;
        /* data.map((nov: novita) => { 
                    return nov;
            }); */
      },
      (error: any) => {
        this.bookservice.hadleError(error);
        this.loading = false;
      }
    );

    this.subscriptions.push(sub);
  }

  clickEdit(cod: string, book) {
    this.OnClickEdit.emit({ "cod": cod, "novita": this.isNovita(book) });
  }

  deleteNovita(id) {
    let book = this.books.find((libro) => {
      return libro.oid === id;
    });
    const dialogRef = this.dialog.open(DeleteQuestionComponent, {
      data: {
        delete: true,
        titolo: book.titolo,
        dialogTitle: "Elimina libro dalle Novità",
      },
      height: "auto",
      width: "auto",
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data && data.delete) {
          const sub = this.bookservice.deleteBook(id).subscribe(
            (response: any) => {
              this.getNovita();
              this.toastservice.success("Libro eliminato con successo");
              this.messageservice.setEvent({ type: "refresh" });
            },
            (error) => {
              this.toastservice.error(
                "Errore durante l'eliminazione, libro non eliminato"
              );
            }
          );
          
          this.subscriptions.push(sub);
        }
      });
  }
  formatData(data) {
    return moment(data, "YYYYMMDD").format("DD-MM-YYYY");
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
