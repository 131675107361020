import {Component, OnDestroy, OnInit} from '@angular/core';
import {SuccessOautResponse} from '../../models/oauth.model';
import {OauthService} from '../../services/oauthservice.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../services/storage.service';
import {User} from "../../models/user.model";
import { SessionStorageService } from 'src/app/services/session.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-profilo',
    templateUrl: './profilo.component.html',
    styleUrls: ['./profilo.component.scss']
})
export class ProfiloComponent implements OnInit, OnDestroy {
    userPwd = {
        pwd: '',
        ripPwd: '',
    };
    user: User;
    subscription: Subscription;

    constructor(
        private oauth: OauthService,
        private toast: ToastrService,
        private session: SessionStorageService,
        private st: LocalStorageService
    ) {
    }

    ngOnInit(): void {
        this.user = this.session.get('usr'); 
    }

    changePwd() {
        if (this.userPwd.pwd.length < 8) {
            this.toast.error('La password deve contenere almeno 8 caratteri');
        } else if (this.userPwd.pwd !== this.userPwd.ripPwd) {
            this.toast.error('Le password non coincidono');
        } else {
            this.subscription = this.oauth.changePwd(this.userPwd.pwd)
                .subscribe((res: SuccessOautResponse) => {
                    this.toast.success('Password aggiornata');
                }, (err: any) => {
                    this.toast.error(err.error.error);
                });
        }
    }

    ngOnDestroy(): void {
        if(this.subscription){
            this.subscription.unsubscribe();
        }
    }

}
