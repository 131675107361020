<button color="accent" mat-raised-button *ngIf="(element.nr_fattura*1)"  (click)="downloadPdf('fat', element.fat_id)">
    <mat-icon *ngIf="!loading.fat">download</mat-icon>
    <mat-icon *ngIf="loading.fat"><mat-spinner [diameter]="15" class="mx-auto" ></mat-spinner></mat-icon>
    Fattura 
</button>
 
<!-- <ng-template #ddt> -->
    <button color="accent" mat-raised-button (click)="downloadPdf('ddt', element.ddt_id)" *ngIf="!((element.nr_ddt*1)  === (element.nr_fattura*1)) && (element.flag_ddt!=='1')" >
        <mat-icon *ngIf="loading.ddt"><mat-spinner [diameter]="15" class="mx-auto" ></mat-spinner></mat-icon>
        <mat-icon  *ngIf="!loading.fat">download</mat-icon>
        DDT 
    </button>
<!-- </ng-template> -->