//import { WBookEntity } from './wbook';
import * as moment from 'moment';
import { collaboratori, description } from './books.model';

export interface WBookEntity {
  filename: string | null | undefined;
  //NV_ID: string;
  NV_DITTA: number;
  NV_ARTEDI: string; //numero aricolo editore
  NV_EAN: number;
  //NV_DATMOD: number;  //data di modifica
  //NV_DATINS: number; //data di inserimento
  NV_EDITORE: number;
  NV_DESCRIZIONE: string; //titolo
  NV_ST: string; //sottotiotlo
  NV_PREZZO: number;
  NV_TMLP: number;
  NV_IVA: number;
  NV_VET_COEDI_1: number; //co-editore 1
  NV_VET_COEDI_2: number; //co-editore 2
  NV_COLLANA: number;
  NV_NCOL: number;
  NV_VET_RUO_1: string;
  NV_VET_RUO_2: string;
  NV_VET_RUO_3: string;
  NV_VET_RUO_4: string;
  NV_VET_RUO_5: string;
  NV_VET_CUR_1: number;
  NV_VET_CUR_2: number;
  NV_VET_CUR_3: number;
  NV_VET_CUR_4: number;
  NV_VET_CUR_5: number;
  NV_VET_ARG_1: number;
  NV_VET_ARG_2: number;
  NV_VET_ARG_3: number;
  NV_VET_ARG_4: number;
  NV_VET_ARG_5: number;
  NV_VET_ARG_6: number;
  NV_VET_ARG_7: number;
  NV_VET_ARG_8: number;
  NV_VET_ARG_9: number;
  NV_VET_AUT_1: number;
  NV_VET_AUT_2: number;
  NV_VET_AUT_3: number;
  NV_VET_AUT_4: number;
  NV_VET_AUT_5: number;
  NV_VET_AUT_6: number;
  NV_N_PAG: string;
  NV_N_VOL: string;
  NV_VOLUME: string;
  NV_ANNOED: number;
  NV_MESE_ED: number;
  NV_NUM_EDI: number;
  NV_RILEG: string;
  NV_LINGUA: number;
  NV_LINGUA_ORIG: number;
  NV_TITOLO_ORIG: string;
  NV_QPC: number;
  NV_PESON: number;
  NV_STATO: string;
  NV_EQUIVA: string;
  NV_DATA_PUBB: string;
  NV_BASE: number;
  NV_ALTEZZA: number;
  NV_SPESSORE: number;
  NV_SPEM12: string;
  NV_AAGIRO: number;
  NV_NUGIRO: number;
  NV_TIPOX: number;
  NV_IDRISTAMPA: string;
  NV_TIRATURA: number;
  NV_ADOTTATO: string;
  NV_RICH_STAMPA: string;
  NV_SIZE_SCHEDA: number;
  NV_INTERESSE: number;
  NV_DATA_UPLOAD: number;
  NV_DATA_DOWNLOAD: number;
  NV_DES_INTERESSE: string;
  NV_GENEREX: string;
  NV_NOTA: string;
  NV_ABSTRACT: string;
  NV_NOTAHTML: string;
  OID: number;
  collaboratori: any[];
}

export class Wbook {
  constructor(
    public isbn?: number,
    public titolo?: string,
    public sottotitolo?: string,
    public altezza?: number,
    public base?: number,
    public spessore?: number,
    public peso?: number,
    public recensione?: string,
    public numero_pagine?: string,
    public cod_autore_1?: number,
    public cod_autore_2?: number,
    public cod_autore_3?: number,
    public cod_autore_4?: number,
    public cod_autore_5?: number,
    public cod_autore_6?: number,
    public n_collana?: number,
    public cod_collana?: number,
    public cod_argomento_1?: number,
    public cod_argomento_2?: number,
    public cod_argomento_3?: number,
    public cod_argomento_4?: number,
    public cod_argomento_5?: number,
    public cod_argomento_6?: number,
    public cod_argomento_7?: number,
    public cod_argomento_8?: number,
    public cod_argomento_9?: number,
    public prezzo?: string,
    public interesse?: number,
    public cod_iva?: number,
    public cod_genere?: string,
    public cod_categoria?: number,
    public cod_lingua?: number,
    public edizione_anno?: number,
    public edizione_mese?: number,
    public edizione_numero?: number,
    public cod_rilegatura?: string,
    public note?: string,
    public tiratura?: number,
    public tags?: string,
    public ditta?: number,
    public artedi?: string,
    public datmod?: number,
    public datins?: number,
    public editore?: number,
    public datapubblicazione?: Date,
    public curatore?: number,
    public illustratore?: number,
    public traduttore?: number,
    public prefazione?: number,
    public collaboratori?: collaboratori[],
    public nVolumi?: string,
    public volume?: string,
    public codLinguaOriginale?: number,
    public titoloOriginale?: string,
    public qpc?: number,
    public stato?: string,
    public equiva?: string,
    public annoGiro?: number,
    public nuGiro?: number,
    public tipox?: number,
    public idRistampa?: string,
    public adottato?: boolean,
    public richStampa?: string,
    public sizeScheda?: number,
    public desInteresse?: string,
    public upload?: number,
    public oid?: number,
    public noteinterne?: string
  ) {}

  public setValues(Wbook: WBookEntity) {
    console.log('NEL BOOK', Wbook);
    this.isbn = Wbook.NV_EAN;
    this.titolo = Wbook.NV_DESCRIZIONE;
    this.sottotitolo = Wbook.NV_ST;
    this.altezza = Wbook.NV_ALTEZZA;
    this.base = Wbook.NV_BASE;
    this.spessore = Wbook.NV_SPESSORE;
    this.peso = Wbook.NV_PESON;
    this.recensione = Wbook.NV_ABSTRACT;
    this.numero_pagine = Wbook.NV_N_PAG;
    this.cod_autore_1 = Number(Wbook.NV_VET_AUT_1);
    this.cod_autore_2 = Number(Wbook.NV_VET_AUT_2);
    this.cod_autore_3 = Number(Wbook.NV_VET_AUT_3);
    this.cod_autore_4 = Number(Wbook.NV_VET_AUT_4);
    this.cod_autore_5 = Number(Wbook.NV_VET_AUT_5);
    this.cod_autore_6 = Number(Wbook.NV_VET_AUT_6);
    this.prezzo = (
      Math.round(Wbook.NV_PREZZO * 100) / 100
    ).toFixed(2);
    this.interesse = Wbook.NV_INTERESSE;
    this.cod_iva = Wbook.NV_IVA;
    this.cod_genere = Wbook.NV_SPEM12;
    this.cod_categoria = Wbook.NV_TMLP;
    this.cod_lingua = Wbook.NV_LINGUA;
    this.edizione_anno = Wbook.NV_ANNOED;
    this.edizione_mese = Wbook.NV_MESE_ED;
    this.edizione_numero = Wbook.NV_NUM_EDI;
    this.cod_rilegatura = Wbook.NV_RILEG;
    this.note = Wbook.NV_NOTAHTML;
    this.tiratura = Wbook.NV_TIRATURA;
    this.tags = Wbook.NV_GENEREX;
    this.ditta = Wbook.NV_DITTA;
    this.artedi = Wbook.NV_ARTEDI;
    //this.datmod =  Wbook.NV_DATMOD;
    //this.datins = Wbook.NV_DATINS;
    this.editore = Wbook.NV_EDITORE;
    this.cod_argomento_1 = Wbook.NV_VET_ARG_1;
    this.cod_argomento_2 = Wbook.NV_VET_ARG_2;
    this.cod_argomento_3 = Wbook.NV_VET_ARG_3;
    this.cod_argomento_4 = Wbook.NV_VET_ARG_4;
    this.cod_argomento_5 = Wbook.NV_VET_ARG_5;
    this.cod_argomento_6 = Wbook.NV_VET_ARG_6;
    this.cod_argomento_7 = Wbook.NV_VET_ARG_7;
    this.cod_argomento_8 = Wbook.NV_VET_ARG_8;
    this.cod_argomento_9 = Wbook.NV_VET_ARG_9;
    this.n_collana = Wbook.NV_NCOL;
    this.datapubblicazione = Wbook.NV_DATA_PUBB
      ? moment(
          Wbook.NV_DATA_PUBB.toString().substring(0, 4) +
            '-' +
            Wbook.NV_DATA_PUBB.toString().substring(4, 6) +
            '-' +
            Wbook.NV_DATA_PUBB.toString().substring(6, 8)
        ).toDate()
      : moment().toDate();
    this.cod_collana = Wbook.NV_COLLANA;
    this.curatore = Wbook.NV_VET_CUR_1;
    this.illustratore = Wbook.NV_VET_CUR_2;
    this.traduttore = Wbook.NV_VET_CUR_3;
    this.prefazione = Wbook.NV_VET_CUR_4;
    this.nVolumi = Wbook.NV_N_VOL;
    this.volume = Wbook.NV_VOLUME;
    this.codLinguaOriginale = Wbook.NV_LINGUA_ORIG;
    this.titoloOriginale = Wbook.NV_TITOLO_ORIG;
    this.qpc = Wbook.NV_QPC;
    this.stato = Wbook.NV_STATO;
    this.equiva = Wbook.NV_EQUIVA;
    this.annoGiro = Wbook.NV_AAGIRO;
    this.nuGiro = Wbook.NV_NUGIRO;
    this.tipox = Wbook.NV_TIPOX; //flag ristampa si o no
    this.idRistampa = Wbook.NV_IDRISTAMPA;
    this.adottato =
      Wbook.NV_ADOTTATO === '1' ? true : false;
    this.richStampa = Wbook.NV_RICH_STAMPA;
    this.sizeScheda = Wbook.NV_SIZE_SCHEDA;
    this.upload = Wbook.NV_DATA_UPLOAD;
    this.desInteresse = Wbook.NV_DES_INTERESSE;
    this.collaboratori = Wbook.collaboratori;
    (this.noteinterne = Wbook.NV_NOTA),
      //this.notaHtml = Wbook.NV_NOTAHTML
      (this.oid = Wbook.OID);
  }
  /**Oggetto che uso per definire i curatori */
  curatoriObj = {
    curatore: 'CUR',
    illustratore: 'ILL',
    traduttore: 'TRA',
    prefazione: 'PRE',
  };

  public getPayLoad(): WBookEntity {
    const payload: WBookEntity = {
      filename: '',
      //'NV_ID': ' ',
      NV_DITTA: 1,
      NV_ARTEDI: this.artedi,
      NV_EAN: this.isbn,
      //'NV_DATMOD': Number(moment().format('YYYYMMGG')),
      //'NV_DATINS': Number(moment().format('YYYYMMGG')),
      NV_EDITORE: this.editore,
      NV_DESCRIZIONE: this.titolo,
      NV_ST: this.sottotitolo ? this.sottotitolo : ' ',
      NV_PREZZO: this.prezzo ? Number(this.prezzo) : 0,
      NV_TMLP: this.cod_categoria ? this.cod_categoria : 0,
      NV_IVA: this.cod_iva ? this.cod_iva : 0,
      NV_VET_COEDI_1: 0,
      NV_VET_COEDI_2: 0,
      NV_COLLANA: this.cod_collana ? this.cod_collana : 0,
      NV_NCOL: this.n_collana ? this.n_collana : 0,
      NV_VET_RUO_1: '', //this.curatore > 0 ? "CUR" : " ",
      NV_VET_RUO_2: '', //this.illustratore > 0 ? "ILL" : " ",
      NV_VET_RUO_3: '', //this.traduttore > 0 ? "TRA" : " ",
      NV_VET_RUO_4: '', //this.prefazione > 0 ? "PRE" : " ",
      NV_VET_RUO_5: '',
      NV_VET_CUR_1: 0, //this.curatore ? this.curatore : 0,
      NV_VET_CUR_2: 0, //this.illustratore ? this.illustratore : 0,
      NV_VET_CUR_3: 0, //this.traduttore ? this.traduttore : 0,
      NV_VET_CUR_4: 0, //this.prefazione ? this.prefazione : 0,
      NV_VET_CUR_5: 0,
      NV_VET_ARG_1: this.cod_argomento_1
        ? this.cod_argomento_1
        : 0,
      NV_VET_ARG_2: this.cod_argomento_2
        ? this.cod_argomento_2
        : 0,
      NV_VET_ARG_3: this.cod_argomento_3
        ? this.cod_argomento_3
        : 0,
      NV_VET_ARG_4: this.cod_argomento_4
        ? this.cod_argomento_4
        : 0,
      NV_VET_ARG_5: this.cod_argomento_5
        ? this.cod_argomento_5
        : 0,
      NV_VET_ARG_6: this.cod_argomento_6
        ? this.cod_argomento_6
        : 0,
      NV_VET_ARG_7: this.cod_argomento_7
        ? this.cod_argomento_7
        : 0,
      NV_VET_ARG_8: this.cod_argomento_8
        ? this.cod_argomento_8
        : 0,
      NV_VET_ARG_9: this.cod_argomento_9
        ? this.cod_argomento_9
        : 0,
      NV_VET_AUT_1: this.cod_autore_1
        ? this.cod_autore_1
        : 0,
      NV_VET_AUT_2: this.cod_autore_2
        ? this.cod_autore_2
        : 0,
      NV_VET_AUT_3: this.cod_autore_3
        ? this.cod_autore_3
        : 0,
      NV_VET_AUT_4: this.cod_autore_4
        ? this.cod_autore_4
        : 0,
      NV_VET_AUT_5: this.cod_autore_5
        ? this.cod_autore_5
        : 0,
      NV_VET_AUT_6: this.cod_autore_6
        ? this.cod_autore_6
        : 0,
      NV_N_PAG: this.numero_pagine
        ? this.numero_pagine
        : '0',
      NV_N_VOL: this.nVolumi
        ? this.nVolumi.toString()
        : ' ',
      NV_VOLUME: this.volume ? this.volume.toString() : ' ',
      NV_ANNOED: this.edizione_anno
        ? this.edizione_anno
        : 0,
      NV_MESE_ED: this.edizione_mese
        ? this.edizione_mese
        : 0,
      NV_NUM_EDI: this.edizione_numero
        ? this.edizione_numero
        : 0,
      NV_RILEG: this.cod_rilegatura,
      NV_LINGUA: this.cod_lingua ? this.cod_lingua : 0,
      NV_LINGUA_ORIG: this.codLinguaOriginale
        ? this.codLinguaOriginale
        : 0,
      NV_TITOLO_ORIG: this.titoloOriginale
        ? this.titoloOriginale
        : ' ',
      NV_QPC: this.qpc ? this.qpc : 0,
      NV_PESON: this.peso ? this.peso : 0,
      NV_STATO: this.stato ? this.stato : 'I',
      NV_EQUIVA: this.equiva ? this.equiva : ' ',
      NV_DATA_PUBB: moment(this.datapubblicazione).format(
        'YYYYMMDD'
      ),
      NV_BASE: this.base ? this.base : 0,
      NV_ALTEZZA: this.altezza ? this.altezza : 0,
      NV_SPESSORE: this.spessore ? this.spessore : 0,
      NV_SPEM12: this.cod_genere
        ? this.cod_genere.toString()
        : ' ',
      NV_AAGIRO: this.annoGiro,
      NV_NUGIRO: this.nuGiro,
      NV_TIPOX: this.tipox ? this.tipox : 0,
      NV_IDRISTAMPA: this.idRistampa
        ? this.idRistampa
        : ' ',
      NV_TIRATURA: this.tiratura ? this.tiratura : 0,
      NV_ADOTTATO: this.adottato ? '1' : '0',
      NV_RICH_STAMPA: this.richStampa
        ? this.richStampa
        : '0',
      NV_SIZE_SCHEDA: this.sizeScheda ? this.sizeScheda : 0,
      NV_INTERESSE: this.interesse ? this.interesse : 0,
      NV_DATA_UPLOAD: Number(moment().format('YYYYMMGG')),
      NV_DATA_DOWNLOAD: Number(moment().format('YYYYMMGG')),
      NV_DES_INTERESSE: this.desInteresse
        ? this.desInteresse
        : ' ',
      NV_GENEREX: this.tags ? this.tags : '',
      NV_NOTA: this.noteinterne,
      NV_ABSTRACT: this.recensione ? this.recensione : ' ',
      NV_NOTAHTML: this.note ? this.note : ' ',
      OID: this.oid ? this.oid : 0,
      collaboratori: [],
    };

    return this.setCollaboratori(payload);
  }

  getEditori() {
    /* "SELECT * FROM F_TBEDIT ft WHERE 
                TBEDIT_CODICE NOT IN (SELECT F_BLSP.BLSP_DISTRIBUTORE FROM F_BLSP WHERE F_BLSP.BLSP_CONTO = 6010591 AND BLSP_DITTA = 1 AND BLSP_ART = \' \' AND BLSP_TOTALE=\'E\') 
                AND TBEDIT_STATO =\' \' "; */
  }

  setCollaboratori(payload) {
    let index = 1;

    if (this.collaboratori) {
      this.collaboratori.forEach((collaboratore) => {
        payload[`NV_VET_RUO_${index}`] =
          this.curatoriObj[collaboratore.type];
        payload[`NV_VET_CUR_${index}`] = Number(
          collaboratore.cod
        );
        index++;
      });
    }

    return payload;
  }
}
