import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-ristampa-dialog",
  templateUrl: "./ristampa-dialog.component.html",
  styleUrls: ["./ristampa-dialog.component.scss"],
})
export class RistampaDialogComponent implements OnInit {
  errors = [];
  constructor(
    public dialogRef: MatDialogRef<RistampaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (Number(this.data.NV_PREZZO) === 0) {
      this.errors.push("Il prezzo non può essere 0");
    }
    if (Number(this.data.NV_DATA_PUBB) === 0) {
      this.errors.push("La data di pubblicazione non è una data valida");
    }
    if (Number(this.data.NV_N_PAG) === 0) {
      this.errors.push("Il numero di pagine non può essere uguale a 0");
    }
    if (Number(this.data.NV_BASE) === 0) {
      this.errors.push("La base non può essere uguale a 0");
    }
    if (Number(this.data.NV_ALTEZZA) === 0) {
      this.errors.push("L'altezza non può essere uguale a 0");
    }
    if (Number(this.data.NV_SPESSORE) == 0) {
      this.errors.push("Lo spessore non può essere uguale a 0");
    }
    if (Number(this.data.NV_PESON) === 0) {
      this.errors.push("Il peso non può essere uguale a 0");
    }
    if (this.data.NV_RILEG.length < 2) {
      this.errors.push("La rilegatura deve essere definita");
    }
    if (!this.data.NV_ABSTRACT || this.data.NV_ABSTRACT.toString().length < 2) {
      this.errors.push("Abstract non presente o invalido");
    }
  }
}
