import { SessionStorageService } from "./../../../services/session.service";
import { iUser } from "./../../../models/user.model";
import { environment } from "src/environments/environment";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { Wbook } from "src/app/models/wbook";
import { BookService } from "src/app/services/book.service";
import { LocalStorageService } from "src/app/services/storage.service";
import { description } from "src/app/models/books.model";
import { Subscription } from "rxjs";

@Component({
  selector: "app-book-cliente",
  templateUrl: "./book-cliente.component.html",
  styleUrls: ["./book-cliente.component.scss"],
})
export class BookClienteComponent implements OnInit,OnDestroy {
  public book: Wbook = new Wbook();
  public loading = true;

  @Input() code: string = null;
  @Output() clickEvent: any = new EventEmitter<any>();
  private additionalImages = new FormData();
  images = [];
  autori = [];
  editore: string = null;
  collana: string = null;
  novita = null;
  categoria = null;
  argomento = null;
  genere = null;
  user: iUser = null;
  loadingPdf = true;
  subscriptions: Subscription[] = [];

  constructor(
    private bookservice: BookService,
    private toastservice: ToastrService,
    private storage: LocalStorageService,
    private session: SessionStorageService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.user = this.session.get("usr");
    this.getBookDetail(this.code);
  }
  /**
   * getBookDetail
   */
  getBookDetail(code) {
    this.loading = true;
    const sub = this.bookservice.getEntityNovita(code).subscribe(
      (data: any) => {
        const wbook = this.book.setValues(data);
        this.loading = false;
        this.makeDefaultAuthors();
        this.getArgomenti();
        this.getCategorie();
        this.getGeneri();
        this.getCollane();
        this.getEditori();
        this.loadSchedaNovita(data)
      },
      (error: any) => {
        this.bookservice.hadleError(error);
      }
    );

    this.subscriptions.push(sub);
  }

  loadSchedaNovita(data) {
    this.loadingPdf = true;
    const request = this.bookservice.schedaNovitaRequest(data.NV_EAN);
    fetch(request, { cache: "reload" }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          this.novita = this.domSanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(blob)
          );
        
          this.loadingPdf = false;
        });
      } else {
        this.loadingPdf = false;
      }
    });
  }

  formatData(data) {
    return moment(data, "YYYY-MM-DD").format("DD-MM-YYYY");
  }
  schedaNovita() {
    this.toastservice.warning("In fase di implementazione");
  }

  select(code, description, event) {
    this.clickEvent.emit({
      code: code,
      event: event,
      description: description,
    });
  }

  setAdditionalImages($event): void {
    if ($event === 0) {
      this.images.pop();
      if (this.images.length === 0) {
        this.additionalImages.delete("images[]");
      }
    } else {
      this.images.push($event);
    }
    this.additionalImages.delete("images[]");
    for (let image of this.images) {
      this.additionalImages.append("images[]", image);
    }
  }

  async getCollane() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached("collane")
    );
    response.json().then((data) => {
      const i = _.findIndex(data, { cod: this.book.cod_collana });
      this.collana = i >= 1 ? data[i].descrizione : "";
    });
  }

  async getGeneri() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached("generi")
    );
    response.json().then((data) => {
      const i = _.findIndex(data, { cod: this.book.cod_genere });
      this.genere = data[i].descrizione;
    });
  }

  async getCategorie() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached("categorie")
    );
    response.json().then((data) => {
      const i = _.findIndex(data, { cod: this.book.cod_categoria });
      this.categoria = data[i].descrizione;
    });
  }

  async getArgomenti() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached("argomenti")
    );
    response.json().then((data) => {
      const i = _.findIndex(data, {
        cod: this.book.cod_argomento_1.toString().padStart(12, "0"),
      });
      this.argomento = data[i].descrizione;
    });
  }

  async getEditori() {
    const sub = this.bookservice
      .getEditoriCliente(this.user.code)
      .subscribe((editori: Array<description>) => {
        const i = _.findIndex(editori, { cod: this.book.editore });
        this.editore = editori[i].descrizione;
      });

      this.subscriptions.push(sub);
  }

  makeDefaultAuthors() {
    let defaultCodiciAutori = [];
    for (let i = 1; i < 7; i++) {
      if (Number(this.book["cod_autore_" + i]) !== 0) {
        defaultCodiciAutori.push(Number(this.book["cod_autore_" + i]));
      }
    }
    const sub = this.bookservice
      .getAutoriInfo(defaultCodiciAutori)
      .subscribe((data: Array<description>) => {
        this.autori = data;
      });

      this.subscriptions.push(sub);
  }

  downloadPdf() {
    const request = this.bookservice.schedaNovitaRequest(
      this.book.isbn.toString()
    );
    fetch(request, { cache: "reload" }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `${this.book.isbn}.pdf`;
          a.click();
        });
      }
    });
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}

