import { MessageService } from './../../services/message.service';
import { BookService } from './../../services/book.service';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { OrdiniService } from 'src/app/services/ordini.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

interface CSVRecord {
  codiceISBN: any;
  quantita: any;
}

@Component({
  selector: 'app-importa-ordine',
  templateUrl: './importa-ordine.component.html',
  styleUrls: ['./importa-ordine.component.scss'],
})
export class ImportaOrdineComponent
  implements OnInit, OnDestroy
{
  import = 0;
  isbns = [];
  unvalidISBNS: Array<string> = [];
  public records = [];
  validElements = [];
  subscriptions: Subscription[] = [];
  displayedColumns: string[] = ['codiceISBN', 'quantita'];
  /**  *public codiceISBN: any;
    public quantita: any;
     */
  constructor(
    private bookservice: BookService,
    private ordiniservice: OrdiniService,
    private toast: ToastrService,
    private messageservice: MessageService
  ) {}

  ngOnInit(): void {
    this.import = 0;
  }

  undoImport() {
    this.import = 0;
    this.records = [];
  }

  /**
   * onFileChange
   * analizza il file lato client
   * @param evt
   */

  onFileChange(evt: any) {
    this.records = [];
    this.unvalidISBNS = [];
    this.isbns = [];
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1)
      throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: 'binary',
      });
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
      });
      data.forEach((item: CSVRecord) => {
        this.isbns.push(item[0]);
        this.records.push({
          codiceISBN: item[0],
          quantita: item[1],
        });
      });
      this.checkIbsns();
    };
    reader.readAsBinaryString(target.files[0]);
  }
  /**
   * checkIbsns
   */
  public checkIbsns() {
    const sub = this.bookservice
      .checkIsbns(this.isbns)
      .subscribe((data: any) => {
        if (data.errors.length) {
          data.errors.forEach((element) => {
            this.unvalidISBNS.push(element);
          });
        }
        this.validElements = data.valid;
      });

    this.subscriptions.push(sub);
  }

  public doImport() {
    let items = [];
    this.validElements.forEach((element) => {
      const e = _.find(this.records, {
        codiceISBN: Number(element.ean),
      });
      items.push({
        ean: element.ean,
        qta: e.quantita,
        prezzo: element.prezzo,
      });
    });

    const sub = this.ordiniservice
      .doImport(items)
      .subscribe(
        (data: any) => {
          this.messageservice.setEvent({
            type: 'adj-cart',
            event: data.data,
          });
          this.messageservice.setEvent({
            type: 'cart',
            event: data.data,
          });
          this.import = 1;
          this.toast.success(
            "L'importazione ha avuto successo"
          );
        },
        (error) => {
          this.toast.error(
            "Si sono verificati dei problemi durante l'importazione dell'ordine"
          );
        }
      );

    this.subscriptions.push(sub);
  }

  isValid(isbn): boolean {
    const i = _.indexOf(this.unvalidISBNS, isbn);
    if (i > -1) {
      return false;
    }
    return true;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
