<div class="ep_card" style="padding: 0 0 1rem 0;">
  <div class="ep_card-header ep_card-header-icon" [ngClass]="type">
    <div class="ep_card-icon"><i class="material-icons">{{icon}}</i></div>
    <p class="ep_card-category">{{title}}</p>
    <h3 class="ep_card-title">Ultima esportazione: {{subtitle}}<br>Dalla data: {{secondSubtitle}}</h3>
  </div>
  <div class="ep_card-footer" >
    <div class="row" style="margin-left: 1rem;">
      <!-- <div class="col-12">
        <mat-form-field>
          <mat-label>Dalla data</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="options.dataesportazione">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div> -->
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <button mat-raised-button color="primary" *ngIf="options.dataesportazione && !loading"
            (click)="export()" [disabled]="disablexport">Scarica archivio</button>
        </div>
      </div>
      <button mat-raised-button color="primary" *ngIf="loading" color="link">Sto esportando...
        <mat-icon><mat-spinner diameter="20" color="accent"></mat-spinner></mat-icon>
      </button>
    </div>