import { JsonapiService } from './../services/jsonapi.service';
import { AppRoutingModule } from './../app-routing.module';
import { MaterialModule } from './../material/material.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,  ReactiveFormsModule } from '@angular/forms';
import { BookService } from '../services/book.service';
import { HttpClientModule } from '@angular/common/http';
import { OauthService } from '../services/oauthservice.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { getSaver, SAVER } from '../services/saver.provider';



const MODULES = [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularEditorModule,
];

const SERVICES = [
  BookService,
  OauthService,
  JsonapiService,
];


@NgModule({
  declarations: [],
  imports: MODULES,
  providers: [{
    provide: LOCALE_ID,
    useValue: 'it-IT'
  }, {provide: SAVER, useFactory: getSaver} , ...SERVICES],
  exports: [...MODULES],
})
export class SharedModule { }
