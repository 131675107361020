<div *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="!loading">
  <mat-card class="book" *ngFor="let book of books">
    <div class="row" [id]="book.oid">
      <div class="col-2">
        <app-image-view [ean]="book.ean" [type]="'mini'"></app-image-view>
      </div>
      <div class="col-9">
        <h2>{{ book.titolo | uppercase }}</h2>
        <h3>[ISBN]: {{ book.ean }}</h3>
        <p>
          Autore: {{ book.autore | uppercase }}<br />
          Collana: {{ book.collana }}<br />
          Argomento: {{ book.argomento }} <br />
          Giro : {{ book.numero_giro }}/{{ book.anno_giro }}<br />
          Data di commercializzazione :
          {{ formatData(book.data_pubblicazione) }}
        </p>
      </div>
      <div class="col-1">
        <div class="ribbon-content">
          <div
            class="ribbon novita px-5"
            *ngIf="isNovita(book) && book.ristampa !== '2'"
          >
            <span class="uppercase">Novità</span>
          </div>
          <div class="ribbon ristampa px-5" *ngIf="book.ristampa === '2'">
            <span class="uppercase">Ristampa</span>
          </div>
        </div>
      </div>
    </div>
    <mat-card-actions align="end">
      <button
        mat-raised-button
        color="primary"
        (click)="clickEdit(book.oid, book)"
      >
        {{ isNovita(book) ? "Modifica" : "Visualizza" }}
      </button>
      <button
        mat-raised-button
        color="warn"
        (click)="deleteNovita(book.oid)"
        [disabled]="!isNovita(book)"
      >
        Elimina
      </button>
    </mat-card-actions>
  </mat-card>
</div>
