<mat-progress-bar
  *ngIf="(100 * steps) / nsteps < 100"
  mode="determinate"
  value="{{ (100 * steps) / nsteps }}"
></mat-progress-bar>

<div *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  <app-alert-box
    type="warning"
    msg="Attenzione! Non chiudere questa pagina o il tuo browser! "
  >
  </app-alert-box>
  <app-alert-box
    type="warning"
    msg="Stiamo caricando i contenuti multimediali, possono volerci alcuni minuti a seconda dei file caricati."
  >
  </app-alert-box>
  <app-alert-box
    type="warning"
    msg="Sarai rediretto nella lista delle novità a fine processo"
  >
  </app-alert-box>
</div>

<mat-card
  *ngIf="!loading && (100 * steps) / nsteps === 100"
>
  <mat-card-header class="headerWithBtn">
    <div>
      <mat-card-title *ngIf="!viewOnly"
        >{{ formTitle }} (i campi contrassegnati con * sono
        obbligatori)</mat-card-title
      >
      <mat-card-title *ngIf="viewOnly"
        >Visualizza Novità</mat-card-title
      >

      <mat-card-subtitle
        >Giro n° {{ book.nuGiro }} /
        {{ book.annoGiro }}</mat-card-subtitle
      >
      <!-- <mat-card-subtitle *ngIf="usr.profile === 'admin' && !loadingGiro">
        Giro n°: 
        <mat-form-field> 
          <mat-select [(ngModel)]="this.giroSelezionato.cod" (selectionChange)="changeGiro($event)">
            <mat-option *ngFor="let giro of giri" [value]="giro.cod">
              {{giro.descrizione}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-subtitle> -->
    </div>
    <div class="fill"></div>
  </mat-card-header>
  <mat-card-content>
    <div class="container-fluid">
      <div class="row">
        <div class="col-9">
          <form
            #bookForm="ngForm"
            class="bookform"
          >
            <mat-card class="full-width">
              <div class="row">
                <div class="col-3">
                  <mat-form-field class="full-width">
                    <mat-label>ISBN</mat-label>
                    <input
                      type="text"
                      readonly="{{ disabilitaModifiche }}"
                      [disabled]="viewOnly"
                      aria-label="Number"
                      matInput
                      [formControl]="isbnControl"
                      id="isbn"
                      #isbn
                      required
                      maxlength="13"
                      minlength="13"
                      [(ngModel)]="book.isbn"
                      name="isbn"
                      [matAutocomplete]="auto"
                    />
                    <mat-autocomplete
                      autoActiveFirstOption
                      #auto="matAutocomplete"
                    >
                      <mat-option
                        *ngFor="let option of isbnOptions"
                        [value]="option"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <p style="color: red">
                    {{ isbnControl.errors?.invalidIsbn }}
                  </p>
                </div>
                <div class="col-9">
                  <mat-form-field class="full-width">
                    <mat-label>Titolo</mat-label>
                    <input
                      type="text"
                      matInput
                      id="titolo"
                      #titolo
                      required
                      maxlength="200"
                      [(ngModel)]="book.titolo"
                      name="titolo"
                      readonly="{{ disabilitaModifiche }}"
                      [disabled]="viewOnly"
                    />
                    <mat-hint align="end"
                      >{{ titolo.value.length }} /
                      200</mat-hint
                    >
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <mat-form-field class="full-width">
                    <mat-label>Cod. Interno</mat-label>
                    <input
                      type="text"
                      matInput
                      id="artedi"
                      #cod_interno
                      maxlength="13"
                      name="artedi"
                      [(ngModel)]="book.artedi"
                      readonly="{{ disabilitaModifiche }}"
                      [disabled]="viewOnly"
                    />
                  </mat-form-field>
                </div>
                <div class="col-9">
                  <mat-form-field class="full-width">
                    <mat-label>Sottotitolo</mat-label>
                    <input
                      type="text"
                      matInput
                      id="sottotitolo"
                      #sottotitolo
                      maxlength="200"
                      [(ngModel)]="book.sottotitolo"
                      name="sottotitolo"
                      readonly="{{ disabilitaModifiche }}"
                      [disabled]="viewOnly"
                    />
                    <mat-hint align="end"
                      >{{ titolo.value.length }} /
                      200</mat-hint
                    >
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <mat-form-field class="full-width">
                    <mat-label>Categoria</mat-label>
                    <select
                      required
                      matNativeControl
                      #cod_categoria
                      id="cod_categoria"
                      name="cod_categoria"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.cod_categoria"
                      (change)="getTabellaIva()"
                    >
                      <option
                        *ngFor="let option of categorie"
                        value="{{ option.cod }}"
                      >
                        {{ option.descrizione }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label
                      >Prezzo di copertina
                      <span *ngIf="aliquotaIva"
                        >(
                        {{ aliquotaIva.descrizione }}
                        )</span
                      >
                    </mat-label>
                    <input
                      type="number"
                      matInput
                      id="prezzo"
                      required
                      maxlength="200"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.prezzo"
                      name="prezzo"
                    />
                  </mat-form-field>
                </div>
                <div class="col-4">
                  <mat-form-field class="full-width">
                    <mat-label>Collana</mat-label>
                    <select
                      matNativeControl
                      #cod_collana
                      id="cod_collana"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      name="cod_collana"
                      [(ngModel)]="book.cod_collana"
                    >
                      <option
                        *ngFor="let option of collane"
                        value="{{ option.cod }}"
                      >
                        {{ option.descrizione }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label>Nic</mat-label>
                    <input
                      type="number"
                      matInput
                      id="n_collana"
                      #n_collana
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.n_collana"
                      name="n_collana"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <app-autore
                    *ngIf="autoriSelected"
                    [itemsLabel]="'Autori *'"
                    [maxItems]="6"
                    [disabled]="
                      viewOnly
                        ? viewOnly
                        : disabilitaModifiche
                    "
                    [newItemLabel]="'Inserisci autore...'"
                    (selectedItems)="setAutori($event)"
                    [items]="autoriSelected"
                    [addElementEnabled]="!viewOnly"
                    (addElement)="aggiungiAutore('autore')"
                  >
                  </app-autore>
                  <app-autore
                    *ngIf="!autoriSelected"
                    [itemsLabel]="'Autori'"
                    [maxItems]="6"
                    [disabled]="
                      viewOnly
                        ? viewOnly
                        : disabilitaModifiche
                    "
                    [newItemLabel]="'Inserisci autore...'"
                    (selectedItems)="setAutori($event)"
                    [addElementEnabled]="true"
                    (addElement)="aggiungiAutore('autore')"
                  >
                  </app-autore>

                  <!-- SELEZIONE DEI COLLABORATORI-->
                  <app-seleziona-collaboratore
                    [items]="collaboratoriSelected"
                    (selectedItems)="
                      setCollaboratori($event)
                    "
                    [readonly]="
                      viewOnly
                        ? viewOnly
                        : disabilitaModifiche
                    "
                    *ngIf="!loadingCollabs"
                    [addElementEnabled]="true"
                    (addElement)="
                      aggiungiAutore('collaboratore')
                    "
                  ></app-seleziona-collaboratore>
                  <!--  <app-autore
                    [itemsLabel]="'Curatore'"
                    [maxItems]="1"
                    *ngIf="defaultCollaboratori.curatore.length"
                    [newItemLabel]="'A cura di...'"
                    (selectedItems)="setRuolo($event, 'curatore')"
                    [items]="defaultCollaboratori.curatore"
                    [disabled]="viewOnly ? viewOnly : disabilitaModifiche"
                  ></app-autore> -->
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label>Anno edizione</mat-label>
                    <input
                      type="number"
                      matInput
                      id="annoEdizione"
                      maxlength="4"
                      [(ngModel)]="book.edizione_anno"
                      name="annoEdizione"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                    />
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label>Mese edizione</mat-label>
                    <select
                      matNativeControl
                      id="meseEdizione"
                      name="meseEdizione"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.edizione_mese"
                    >
                      <option
                        *ngFor="let option of mesi"
                        value="{{ option.cod }}"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label>Numero edizione</mat-label>
                    <input
                      type="number"
                      matInput
                      id="edizione_numero"
                      #edizione_numero
                      maxlength="5"
                      [(ngModel)]="book.edizione_numero"
                      name="edizione_numero"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                    />
                    <mat-hint align="end"
                      >{{ edizione_numero.value.length }} /
                      5</mat-hint
                    >
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label
                      >Data commercializzazione</mat-label
                    >
                    <input
                      matInput
                      [min]="minDate"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      required
                      [matDatepicker]="datapubblicazione"
                      id="datapubblicazione"
                      [(ngModel)]="book.datapubblicazione"
                      name="datapubblicazione"
                      readonly
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="datapubblicazione"
                      *ngIf="
                        !disabilitaModifiche || !viewOnly
                      "
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                      #datapubblicazione
                    ></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label>Numero Volume</mat-label>
                    <input
                      type="number"
                      matInput
                      id="volume"
                      #volume
                      maxlength="5"
                      [(ngModel)]="book.volume"
                      name="volume"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                    />
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label>Totale Volumi</mat-label>
                    <input
                      type="number"
                      matInput
                      id="nVolumi"
                      #nVolumi
                      maxlength="5"
                      [(ngModel)]="book.nVolumi"
                      name="nVolumi"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-2">
                  <mat-form-field
                    class="full-width"
                    *ngIf="usr.profile === 'admin'"
                  >
                    <mat-label>Base (cm) </mat-label>
                    <input
                      type="number"
                      matInput
                      id="base"
                      #base
                      maxlength="200"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.base"
                      name="base"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="full-width"
                    *ngIf="usr.profile !== 'admin'"
                  >
                    <mat-label>Base (cm) </mat-label>
                    <input
                      type="number"
                      matInput
                      id="base"
                      #base
                      maxlength="200"
                      min="5"
                      max="50"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.base"
                      name="base"
                      required
                    />
                  </mat-form-field>
                  <mat-error
                    class="error"
                    *ngIf="book.base < 5 || book.base > 50"
                  >
                    Questo valore non può essere minore di 5
                    e maggiore di 50
                  </mat-error>
                </div>
                <div class="col-2">
                  <mat-form-field
                    class="full-width"
                    *ngIf="usr.profile === 'admin'"
                  >
                    <mat-label>Altezza (cm)</mat-label>
                    <input
                      type="number"
                      matInput
                      id="altezza"
                      #altezza
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.altezza"
                      name="altezza"
                      required
                    />
                  </mat-form-field>

                  <mat-form-field
                    class="full-width"
                    *ngIf="usr.profile !== 'admin'"
                  >
                    <mat-label>Altezza (cm)</mat-label>
                    <input
                      type="number"
                      min="8"
                      max="60"
                      matInput
                      id="altezza"
                      #altezza
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.altezza"
                      name="altezza"
                      required
                    />
                  </mat-form-field>
                  <mat-error
                    class="error"
                    *ngIf="
                      book.altezza < 8 || book.altezza > 60
                    "
                  >
                    Questo valore non può essere minore di 8
                    e maggiore di 60
                  </mat-error>
                </div>
                <div class="col-2">
                  <mat-form-field
                    class="full-width"
                    *ngIf="usr.profile !== 'admin'"
                  >
                    <mat-label>Spessore (cm)</mat-label>
                    <input
                      type="number"
                      matInput
                      id="spessore"
                      #spessore
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.spessore"
                      min="0.3"
                      max="20"
                      name="spessore"
                      required
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="full-width"
                    *ngIf="usr.profile === 'admin'"
                  >
                    <mat-label>Spessore (cm)</mat-label>
                    <input
                      type="number"
                      matInput
                      id="spessore"
                      #spessore
                      maxlength="200"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.spessore"
                      name="spessore"
                      required
                    />
                  </mat-form-field>
                  <mat-error
                    class="error"
                    *ngIf="
                      book.spessore < 0.3 ||
                      book.spessore > 20
                    "
                  >
                    Questo valore non può essere minore di
                    0,3 e maggiore di 20
                  </mat-error>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label>Numero Pagine</mat-label>
                    <input
                      type="number"
                      matInput
                      id="numero_pagine"
                      #numero_pagine
                      maxlength="200"
                      readonly="{{ disabilitaModifiche }}"
                      [(ngModel)]="book.numero_pagine"
                      name="numero_pagine"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <mat-form-field
                    class="full-width"
                    *ngIf="usr.profile !== 'admin'"
                  >
                    <mat-label>Peso(g)</mat-label>
                    <input
                      type="number"
                      matInput
                      id="peso"
                      #peso
                      maxlength="200"
                      required
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.peso"
                      name="peso"
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="full-width"
                    *ngIf="usr.profile === 'admin'"
                  >
                    <mat-label>Peso(g)</mat-label>
                    <input
                      type="number"
                      matInput
                      id="peso"
                      #peso
                      maxlength="200"
                      required
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.peso"
                      name="peso"
                    />
                  </mat-form-field>
                  <mat-error
                    class="error"
                    *ngIf="
                      book.peso < 100 || book.peso > 3000
                    "
                  >
                    Questo valore non può essere minore di
                    100 e maggiore di 3000
                  </mat-error>
                </div>
                <div class="col-2">
                  <mat-form-field class="full-width">
                    <mat-label>Rilegatura</mat-label>
                    <select
                      matNativeControl
                      #cod_rilegatura
                      id="cod_rilegatura"
                      name="cod_rilegatura"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.cod_rilegatura"
                      required
                    >
                      <option
                        *ngFor="let option of rilegature"
                        value="{{ option.cod }}"
                      >
                        {{ option.descrizione }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <mat-form-field class="full-width">
                    <mat-label>Genere</mat-label>
                    <select
                      matNativeControl
                      #cod_genere
                      id="cod_genere"
                      name="cod_genere"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.cod_genere"
                    >
                      <option
                        *ngFor="let option of generi"
                        value="{{ option.cod }}"
                      >
                        {{ option.descrizione }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field class="full-width">
                    <mat-label>Argomento</mat-label>
                    <select
                      matNativeControl
                      #argomento
                      id="cod_argomento_1"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      name="cod_argomento_1"
                      [(ngModel)]="book.cod_argomento_1"
                    >
                      <option
                        *ngFor="let option of argomenti"
                        value="{{ option.cod }}"
                      >
                        {{ option.descrizione }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="full-width">
                    <mat-label>Tags</mat-label>
                    <mat-chip-list
                      #tagList
                      aria-label="Tag"
                    >
                      <mat-chip
                        *ngFor="let tag of tags"
                        selectable="true"
                        [removable]="!disabilitaModifiche"
                        (removed)="removeTag(tag)"
                      >
                        {{ tag }}
                        <mat-icon matChipRemove
                          >cancel</mat-icon
                        >
                      </mat-chip>

                      <input
                        placeholder="Nuovo tag..."
                        [matChipInputFor]="tagList"
                        [disabled]="
                          viewOnly
                            ? viewOnly
                            : disabilitaModifiche
                        "
                        [matChipInputSeparatorKeyCodes]="
                          separatorKeysCodes
                        "
                        matChipInputAddOnBlur="true"
                        (matChipInputTokenEnd)="
                          addTag($event)
                        "
                      />
                      <mat-error
                        class="error"
                        *ngIf="!charTag"
                      >
                        Sono ammessi massimo 100 caratteri
                      </mat-error>
                    </mat-chip-list>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <mat-form-field class="full-width">
                    <mat-label>Lingua</mat-label>
                    <select
                      matNativeControl
                      #cod_lingua
                      id="cod_lingua"
                      name="cod_lingua"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.cod_lingua"
                    >
                      <option
                        *ngFor="let option of lingue"
                        value="{{ option.cod }}"
                      >
                        {{ option.descrizione }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field class="full-width">
                    <mat-label>Lingua originale</mat-label>
                    <select
                      matNativeControl
                      #lingua_originale
                      id="lingua_originale"
                      name="lingua_originale"
                      [(ngModel)]="book.codLinguaOriginale"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                    >
                      <option
                        *ngFor="let option of lingue"
                        value="{{ option.cod }}"
                      >
                        {{ option.descrizione }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="full-width">
                    <mat-label>Titolo originale</mat-label>
                    <input
                      type="text"
                      matInput
                      id="titolo_originake"
                      #titolo_originale
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.titoloOriginale"
                      name="titoloOriginale"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-form-field class="full-width">
                    <mat-label>Interesse</mat-label>
                    <select
                      matNativeControl
                      #interesse
                      id="interesse"
                      name="interesse"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.interesse"
                    >
                      <option value="1">Nazionale</option>
                      <option value="2">Locale</option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field
                    class="full-width"
                    *ngIf="book.interesse == 2"
                  >
                    <mat-label>Zona</mat-label>
                    <input
                      type="text"
                      matInput
                      id="desInteresse"
                      #desInteresse
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.desInteresse"
                      name="desInteresse"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div class="checkbox-class">
                    <!--   <mat-checkbox value="1"  [checked]="(this.book.adottato) ? true : false" name="adottato" (click)="setFieldAdottato()" >Titolo adottato</mat-checkbox>
                -->
                    Titolo adottato
                    <input
                      name="adottato"
                      type="checkbox"
                      [(ngModel)]="book.adottato"
                      [disabled]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                    />
                  </div>
                </div>
                <div class="col-3">
                  <mat-form-field class="full-width">
                    <mat-label>Tiratura</mat-label>
                    <input
                      type="number"
                      matInput
                      id="tiratura"
                      maxlength="200"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.tiratura"
                      name="tiratura"
                    />
                  </mat-form-field>
                </div>
                <div class="6">
                  <mat-form-field class="full-width">
                    <mat-label>Codice e-book</mat-label>
                    <input
                      type="number"
                      matInput
                      id="equiva"
                      maxlength="200"
                      [readonly]="
                        viewOnly
                          ? viewOnly
                          : disabilitaModifiche
                      "
                      [(ngModel)]="book.equiva"
                      name="equiva"
                    />
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
            <mat-card class="book-section">
              <h3>Abstract *</h3>
              <angular-editor
                [(ngModel)]="book.recensione"
                [config]="
                  viewOnly || disabilitaModifiche
                    ? disabledConfig
                    : config
                "
                name="recensione"
              >
              </angular-editor>
            </mat-card>
            <mat-card class="book-section">
              <h3>Note</h3>
              <angular-editor
                [(ngModel)]="book.note"
                [config]="
                  viewOnly || disabilitaModifiche
                    ? disabledConfig
                    : config
                "
                #note
                name="note"
              ></angular-editor>
            </mat-card>
            <mat-card class="book-section">
              <mat-form-field class="full-width">
                <mat-label>Note Interne</mat-label>
                <textarea
                  [readonly]="
                    viewOnly
                      ? viewOnly
                      : disabilitaModifiche
                  "
                  matInput
                  [(ngModel)]="book.noteinterne"
                  name="noteinterne"
                  rows="5"
                  maxlength="200"
                ></textarea>
                <mat-hint align="end"
                  >{{
                    book.noteinterne
                      ? book.noteinterne.length
                      : 0
                  }}
                  / 200</mat-hint
                >
              </mat-form-field>
            </mat-card>
          </form>
        </div>
        <div class="col-3">
          <app-image
            title="Copertina Libro *"
            [loading]="loadingCopertina"
            (changeFile)="setCopertina($event)"
            [picture]="immagineCopertina"
            [disabled]="viewOnly"
          ></app-image>
          <br />
          <app-images-list
            [title]="
              'Immagini aggiuntive ' +
              this.images.length +
              '/6'
            "
            max="6"
            [insert]="viewOnly"
            *ngIf="book.isbn"
            [isbn]="book.isbn.toString()"
            [bookForm]="true"
            (existingImages)="setImages($event)"
            (changeImage)="setAdditionalImages($event)"
          ></app-images-list>
          <app-images-list
            [title]="
              'Immagini aggiuntive ' +
              this.images.length +
              '/6'
            "
            (changeImage)="setAdditionalImages($event)"
            max="6"
            [insert]="viewOnly"
            *ngIf="!book.isbn"
          ></app-images-list>
          <br />
          <app-pdf-uploader
            title="Scheda Novità *"
            [pdferror]="pdffilename"
            [loading]="loadingScheda"
            (changeFile)="setPdf($event, 'novita')"
            [pdf]="novita"
            (download)="downloadPdf()"
            [disabled]="viewOnly"
          ></app-pdf-uploader>
          <br />

          <app-modalita-stampa
            [disabled]="
              viewOnly
                ? viewOnly
                : disabilitaModifiche && !ristampa
            "
            [date]="modalitaStampaData"
            [value]="book.richStampa"
            (selectedStampa)="richiestaStampa($event)"
            [error]="errors.richiestastampa"
          ></app-modalita-stampa>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div class="container-fluid">
      <div class="row center">
        <div class="col-12 mb-3">
          <button
            type="button"
            mat-raised-button
            color="warn"
            (click)="clickClose()"
            *ngIf="!viewOnly"
          >
            Annulla {{ formTitle }}
          </button>
          <button
            type="button"
            mat-raised-button
            color="warn"
            (click)="clickClose()"
            *ngIf="viewOnly"
          >
            Indietro
          </button>
          <button
            type="button"
            (click)="submit()"
            mat-raised-button
            color="primary"
            [disabled]="
              !bookForm.form.valid ||
              !copertina.has('image') ||
              !schedaNovita.has('scheda')
            "
            *ngIf="!viewOnly"
          >
            Conferma
          </button>
        </div>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
