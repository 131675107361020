import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-breadcrumps',
  templateUrl: './breadcrumps.component.html',
  styleUrls: ['./breadcrumps.component.scss']
})
export class BreadcrumpsComponent implements OnInit {
  @Input() links:Array<any> = [];
  @Output() clicked = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  trackByNavlink(index: number, navlink: string): string {
    return navlink;
  }

  click(link) { 
    this.clicked.emit(link);
  }

}
