<mat-toolbar
  [color]="color"
  class="toolbar"
  [ngClass]="user.profile === 'libreria' ? 'libreria' : ''"
>
  <span class="logo">
    <img src="./../../../assets/ico-48.png" />
  </span>
  <span
    >pboox 2.0 <span *ngIf="user.profile === 'libreria'">- LIBRERIA</span></span
  >
  <span class="spacer"></span>
  <button
    mat-icon-button
    aria-label="Carrello"
    (click)="toggleCart()"
    matTooltip="Il tuo carrello"
    *ngIf="user.isCliente() && user.permessi?.ordini"
  >
    <mat-icon
      [@openClose]="isOpen ? 'open' : 'closed'"
      *ngIf="!loading && items && items.length > 0"
      matBadge="{{ items.length }}"
      matBadgeColor="warn"
      >local_shipping</mat-icon
    >
    <mat-icon *ngIf="!loading && items && items.length == 0"
      >local_shipping</mat-icon
    >
    <mat-icon *ngIf="lWoading || !items">local_shipping</mat-icon>
  </button>
  <span style="font-size: 14px">{{ user.getName() }}</span>
  <button mat-button routerLink="profilo">
    {{ user.email }}
    <mat-icon>account_circle</mat-icon>
  </button>
  <button mat-icon-button aria-label="Esci" (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
  </button>
</mat-toolbar>
<mat-tab-group
  [color]="color"
  *ngIf="enableMenu"
  class="mt64"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
>
  <mat-tab label="Dashboard">
    <app-dashboard
      [user]="user"
      *ngIf="user.profile !== 'libreria'"
    ></app-dashboard>
    <app-dashboard-libreria
      *ngIf="user.profile === 'libreria'"
    ></app-dashboard-libreria>
  </mat-tab>
  <mat-tab label="Catalogo" *ngIf="!user.isCliente()">
    <ng-template matTabContent>
      <app-catalogo *ngIf="user.isEditore()"></app-catalogo>
      <admin-catalogo *ngIf="user.isAdmin()"></admin-catalogo>
    </ng-template>
  </mat-tab>

  <!-- && user?.permessi.catalogo_novita -->
  <mat-tab label="Catalogo" *ngIf="user.isCliente()">
    <ng-template matTabContent>
      <app-catalogo-cliente></app-catalogo-cliente>
    </ng-template>
  </mat-tab>

  <mat-tab label="Novità" *ngIf="!user.isCliente()">
    <ng-template matTabContent>
      <app-lanci [giro]="giro" *ngIf="user.isEditore()"></app-lanci>
      <admin-novita *ngIf="user.isAdmin()"></admin-novita>
    </ng-template>
  </mat-tab>

  <!-- && user?.permessi.catalogo_novita -->
  <mat-tab label="Novità" *ngIf="user.isCliente()">
    <ng-template matTabContent>
      <app-novita-cliente></app-novita-cliente>
    </ng-template>
  </mat-tab>

  <mat-tab label="Archivio Giri Vendite" *ngIf="user.isEditore()">
    <ng-template matTabContent>
      <app-storico-novita></app-storico-novita>
    </ng-template>
  </mat-tab>

  <!-- <mat-tab label="Circolari" *ngIf="user.isAdmin()">
    <app-circolari></app-circolari>
  </mat-tab> -->

  <mat-tab label="Statistiche" *ngIf="!user.isCliente()">
    <ng-template matTabContent>
      <app-statistiche [user]="user"></app-statistiche>
    </ng-template>
  </mat-tab>

  <mat-tab
    label="Importa ordine"
    *ngIf="user.isCliente() && user?.permessi.ordini"
  >
    <ng-template matTabContent>
      <app-importa-ordine></app-importa-ordine>
    </ng-template>
  </mat-tab>

  <mat-tab
    label="Articoli in ordine"
    *ngIf="user.isCliente() && user?.permessi.stato_ordini"
  >
    <ng-template matTabContent>
      <app-articoli-ordine>Articoli in ordine</app-articoli-ordine>
    </ng-template>
  </mat-tab>

  <mat-tab
    label="Ordini"
    *ngIf="user.isCliente() && user?.permessi.stato_ordini"
  >
    <ng-template matTabContent>
      <app-ordini></app-ordini>
    </ng-template>
  </mat-tab>

  <mat-tab label="Elenco DDT" *ngIf="user.isCliente() && user?.permessi.ddt">
    <ng-template matTabContent>
      <app-elenco-ddt></app-elenco-ddt>
    </ng-template>
  </mat-tab>

  <mat-tab
    label="Elenco fatture"
    *ngIf="user.isCliente() && user?.permessi.fatture_scadenze"
  >
    <ng-template matTabContent>
      <app-elenco-fatture></app-elenco-fatture>
    </ng-template>
  </mat-tab>

  <mat-tab
    label="Scadenze"
    *ngIf="user.isCliente() && user.permessi?.fatture_scadenze"
  >
    <ng-template matTabContent>
      <app-scadenze></app-scadenze>
    </ng-template>
  </mat-tab>
</mat-tab-group>
