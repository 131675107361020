import {Injectable, Injector} from '@angular/core';
import {environment} from 'src/environments/environment';
import {LocalStorageService} from './storage.service';
import {SuccessOautResponse} from '../models/oauth.model';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import { SessionStorageService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class CarrelloService {
  API_BASE: string = environment[environment.env].base_url;

  constructor(
      private injector: Injector,
      private httpclient: HttpClient,
      private session: SessionStorageService
  ) {

  }

  public setItemToCart(ean, qta) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
      const url = `${this.API_BASE}/cart`;
      return this.httpclient.post(url, {ean: ean, qta: qta}, options);

  }

  public getCart() {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url = `${this.API_BASE}/cart`;
    return this.httpclient.get(url, options);
  }

  public delItemFromCart(ean) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url = `${this.API_BASE}/cart/${ean}`;
    return this.httpclient.delete(url, options);
  }

  public emptyTrash(){
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url = `${this.API_BASE}/cart`;
    return this.httpclient.delete(url, options);
  }

  public sendOrder(){
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    const url = `${this.API_BASE}/ordine/send`;
    return this.httpclient.post(url, {},options);
  }

  private getHeaders(): HttpHeaders {
    const token: SuccessOautResponse = this.session.get('token');
    if (token) {
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token.access_token,
    }); 
    return header;
    }
    const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }); 
    return header;
  }
}
