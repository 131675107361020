
<div class="ep_card">
    <div class="ep_card-header ep_card-header-icon" [ngClass]="'ep_card-header-warning'"> 
      <button mat-icon-button matTooltip='Scarica il calendario' (click)="downloadCalendario()">
        <mat-icon style="font-size: 30px;">download</mat-icon>
      </button>
      <p class="ep_card-category">Archivio Giri</p>
      <h3 class="ep_card-title" *ngIf="giri" >{{giri.length}} giri disponibili</h3>
    </div>
    <div class="ep_card-footer">
      <mat-spinner *ngIf="!giri" [diameter]="30" class="mx-auto"></mat-spinner> 
      <div *ngIf="giri" >
        <table class="mytable" width="100%">
            <tr class="rowTh">
             <th class="cell" >Descrizione</th>
              <th class="cell" >Consegna cedola</th>
              <th class="cell" >Inizio prenotazione</th>
              <th class="cell" >Fine prenotazione</th>
              <th class="cell">Invio delle prenotazioni</th>
              <th class="cell">Mesi consigliati</th>
              <th class="cell">Giorni Rimanenti all'invio delle prenotazioni</th>
            </tr>
            <tr *ngFor="let giro of giri" class="{{isSelected(giro, giri)}}">
              <td ngClass="cell center"><strong>{{giro.descrizione}}</strong></td>
              <td ngClass="cell center">{{formatDataConsegna(giro.data_scadenza)}}</td>
              <td ngClass="cell center">{{giro.data_inizio_prenotazione | formatDate}}</td>
              <td ngClass="cell center">{{giro.data_fine_prenotazione | formatDate}}</td>
              <td ngClass="cell center">{{formatDataInvio(giro.data_distribuzione)| formatDate }}</td>
              <td ngClass="cell center">{{giro.mesi_consigliati}}</td>
              <td ngClass="cell center" *ngIf="(formatDataInvio(giro.data_distribuzione) | dateDiff: oggi) > 0">{{formatDataInvio(giro.data_distribuzione) | dateDiff: oggi }}</td>
              <td ngClass="cell center" *ngIf="(formatDataInvio(giro.data_distribuzione) | dateDiff: oggi) < 0">0</td>
            </tr>  
          </table> 
      </div>
      <div >
      </div>
    </div>
  </div>
  