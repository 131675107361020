import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import * as _ from "lodash";
import { description } from "src/app/models/books.model";
import { BookService } from "src/app/services/book.service";
import { CarrelloService } from "src/app/services/carrello.service";
import { MessageService } from "src/app/services/message.service";
import { SessionStorageService } from "src/app/services/session.service";
import { iUser } from "src/app/models/user.model";
import { Wbook } from "src/app/models/wbook";
import * as moment from "moment";

@Component({
  selector: "app-book-modal",
  templateUrl: "./book-modal.component.html",
  styleUrls: ["./book-modal.component.scss"],
})
export class BookModalComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  articolo: any;
  loading = false;
  user: iUser = null;

  constructor(
    private bookservice: BookService,
    public dialogRef: MatDialogRef<BookModalComponent>,
    private carrelloservice: CarrelloService,
    private messageservice: MessageService,
    private session: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.user = this.session.get("usr");
    if (this.data.type && this.data.type === "novita") {
      this.getNovitaBook();
    } else {
      this.getBookData();
    }
  }

  /**
   * Prendo i dati del singolo libro tramite il codice di AmicoWin che mi viene passato
   */
  getBookData() {
    this.loading = true;
    const sub = this.bookservice
      .getBook(this.data.item.codice)
      .subscribe({
        next: (data) => {
          this.articolo = data;
        },
        error: (err) => console.error(err),
        complete: () => (this.loading = false),
      });

      this.subscription.push(sub);
  }

  getNovitaBook() {
    this.loading = true;
    this.articolo = new Wbook();

  
    const sub = this.bookservice
      .getEntityNovita(this.data.item.oid)
      .subscribe({
        next: (data) => {
          this.articolo.setValues(data);
          this.articolo["data_pubblicazione"] = moment(
            this.articolo.datapubblicazione
          ).format("GG-MM-YYYY");

          this.makeDefaultAuthors();
          this.getCollane();
          this.getCategorie();
          this.getEditori();
        },
        error: (err) => console.error(err),
        complete: () => (this.loading = false),
      });

      this.subscription.push(sub);
  }

  /**
   * In base alla quantità selezionata metto nel carrello
   * @param event quantita
   */
  setQta(event) {
   
    this.carrelloservice.setItemToCart(event.codice, event.qta).subscribe({
      next: (data: any) => {
        this.messageservice.setEvent({ type: "cart", event: data.data });
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  async getEditori() {
    const sub = this.bookservice
      .getEditoriCliente(this.user.code)
      .subscribe((editori: Array<description>) => {
        const i = _.findIndex(editori, { cod: this.articolo.editore });
        this.articolo["editore"] = editori[i].descrizione;
      });

      this.subscription.push(sub);
  }

  async getCollane() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached("collane")
    );
    response.json().then((data) => {
      const i = _.findIndex(data, { cod: this.articolo.cod_collana });
      this.articolo["collana"] = i >= 1 ? data[i].descrizione : "";
    });
  }

  async getCategorie() {
    const cache = await caches.open(environment.cache);
    const response = await cache.match(
      this.bookservice.getCollectionCached("categorie")
    );
    response.json().then((data) => {
      const i = _.findIndex(data, { cod: this.articolo.cod_categoria });
      this.articolo["categoria"] = data[i].descrizione;
    });
  }

  makeDefaultAuthors() {
    let defaultCodiciAutori = [];
    for (let i = 1; i < 7; i++) {
      if (Number(this.articolo["cod_autore_" + i]) !== 0) {
        defaultCodiciAutori.push(Number(this.articolo["cod_autore_" + i]));
      }
    }
    const sub = this.bookservice
      .getAutoriInfo(defaultCodiciAutori)
      .subscribe((data: Array<description>) => {
        data.map((item, index) => {
          this.articolo[`autore_${index + 1}`] = item.descrizione;
        });
        this.articolo["autori"] = data;
      });

      this.subscription.push(sub);
  }

  ngOnDestroy(): void {
      this.subscription.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
