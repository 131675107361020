import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SuccessOautResponse } from '../models/oauth.model';
import { SessionStorageService } from './session.service';


export interface Filter {
  name: string;
  value: any;
} 

@Injectable({
  providedIn: 'root'
})

export class DocumentService {

  API_BASE: string = environment[environment.env].base_url;

  constructor(
    private injector: Injector,
    private httpclient: HttpClient,
    private session: SessionStorageService,
  ) {}

  private getHeaders(): HttpHeaders {
    const token: SuccessOautResponse = this.session.get('token');
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token.access_token,
    });

    return header;
}

  //Restutuisce l'elenco delle testate dei ddt
  public getDDTCollection( 
    startDate:string,
    stopDate: string, 
    filters?: Array<Filter>,
    orderby?: string
  ) {
    const serviceHeaders = this.getHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    } 

    const options = { headers: serviceHeaders, params: payload };  
    return this.injector.get(HttpClient).get(`${this.API_BASE}/ddt/${startDate}/${stopDate}`, options);
  }

  //Restutuisce le righe di un ddt
  public getRigheDDT(ddt_id) {
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/ddt/${ddt_id}`, options);
  }

  //Restituisce l'elenco delle fatture
  public getFattureCollection( 
    startDate:string,
    stopDate: string, 
    filters?: Array<Filter>,
    orderby?: string
  ) {
    const serviceHeaders = this.getHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    } 

    const options = { headers: serviceHeaders, params: payload };  
    return this.injector.get(HttpClient).get(`${this.API_BASE}/fatture/${startDate}/${stopDate}`, options);
  }

  //Restutuisce il file delle fatture
  public fileRequest(type: string, id: string, isFat = null): Request {
    const token: SuccessOautResponse = this.session.get('token');
    const options = {
      headers: {
        'Content-Type': 'application/pdf',
        'Accept': 'application/pdf',
        'Authorization': 'Bearer ' + token.access_token,
      }
    }
    let jsonResponse = null;
    if (!isFat) {
       jsonResponse = new Request(`${this.API_BASE}/pdf/${type}/${id}`, options);
    } else {
       jsonResponse = new Request(`${this.API_BASE}/pdf/${type}/${id}/${isFat}`, options);
    }
    
    return jsonResponse;
  }


  public getScadenze(){
    const serviceHeaders = this.getHeaders();
    const options = { headers: serviceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/scadenziario`, options);
  }
}
