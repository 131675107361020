import { ProfiloComponent } from "./pages/profilo/profilo.component";
import { PageComponent } from "./pages/page/page.component";
import { LoginComponent } from "./components/login/login.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AuthGuard } from "../app/auth.guard";
import { CatalogoComponent } from "./pages/catalogo/catalogo.component";
import { UserSettingsComponent } from "./pages/user-settings/user-settings.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "",
    component: PageComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "profilo",
    component: ProfiloComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  /*   { path: 'catalogo', component: CatalogoComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      
      { path: 'lanci/modifica-libro/{ean}', component: BookEditComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'preferenze', component: UserSettingsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
     */
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
