<div class="ep_card" style="height: 15vh;display: flex;flex-direction: column;">
  <!-- <div class="ep_card-header ep_card-header-icon ep_card-header-warning">
    <div class="ep_card-icon">
      <i class="material-icons">sim_card_download</i>
    </div>
    <p type="ep_card-header-warning" >Download Schede Novità</p>
  </div> -->
  <div class="ep_card-header ep_card-header-icon" [ngClass]="'ep_card-header-warning'">
    <div class="ep_card-icon"><i class="material-icons">sim_card_download</i></div>
    <p class="ep_card-category" style="font-size: 20px;display: flex;justify-content: end;margin-top: 1rem;">Download
      Scheda Novita</p>
  </div>
  <div class="ep_card-footer" *ngIf="!loader.pdf">
    <div class="row">
      <div class="col-4 center">
        <button mat-raised-button class="download-option" (click)="downloadSchede()">
          <i class="fa fa-icon fa-download"></i>
          &nbsp;Scarica PDEFFONE
        </button>
      </div>
      <div class="col-4 center">
        <button mat-raised-button class="download-option" (click)="rebuild()">
          <i class="fa fa-icon fa-file-pdf-o"></i> &nbsp;Rigenera PDFEFFONE
        </button>
      </div>

      <div class="col-4 center">
        <button mat-raised-button class="download-option" (click)="pdfList()">
          <i class="fa fa-icon fa-file-pdf-o"></i> &nbsp;Lista Cedole App
        </button>
      </div>

    </div>
  </div>
  <div class="ep_card-footer" *ngIf="loader.pdf">
    <div align="center">
      Download in corso , ti preghiamo di aspettare, potrebbe volerci qualche
      minuto.
    </div>
    <mat-spinner [diameter]="35" class="mx-auto"></mat-spinner>
  </div>
</div>