<mat-form-field class="full-width">
  <mat-label>{{itemsLabel}}</mat-label>
  <mat-chip-list #chipList aria-label="item selection">
    <mat-chip *ngFor="let item of items" [removable]="!disabled" (removed)="remove(item)">
      {{item.descrizione}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>

    <input *ngIf="items.length < maxItems" required [disabled]="disabled" placeholder="{{newItemLabel}}" #itemInput
      [formControl]="itemCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
    <button mat-button matTooltip="Aggiungi" (click)="add()" *ngIf="addElementEnabled">
      <mat-icon matSuffix>person_add_alt_1</mat-icon>
    </button>
    <mat-hint align="end">{{items.length}} / {{maxItems}}</mat-hint>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filtereditems" [value]="item.cod">
      {{item.descrizione}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>