<div class="loading-shade" *ngIf="loader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div
  class="pag"
  *ngIf="!loader && dataSource && dataSource.data && dataSource.data.length > 0"
>
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-label>Cerca</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
      </mat-form-field>
    </div>
    <div class="col-6" align="right">
      <export-table
        [data]="dataExport"
        *ngIf="dataExport.length"
      ></export-table>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
    >
      <!-- Num Fattura -->
      <ng-container matColumnDef="num_fattura">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr. Fattura</th>
        <td mat-cell *matCellDef="let row">
          {{ row.num_fattura + "/" + row.registro }}
        </td>
        <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
      </ng-container>
      <!-- Descrizione -->
      <ng-container matColumnDef="descrizione">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Descrizione</th>
        <td mat-cell *matCellDef="let row">{{ row.descrizione }}</td>
        <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
      </ng-container>
      <!-- Data fattura Column -->
      <ng-container matColumnDef="data_fattura">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Fattura</th>
        <td mat-cell *matCellDef="let row">
          {{ row.data_fattura | formatDate }}
        </td>
        <td mat-footer-cell *matFooterCellDef>&nbsp;</td>
      </ng-container>

      <!--[style.color]=""-->
      <ng-container matColumnDef="data_scadenza">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Scadenza</th>
        <td mat-cell *matCellDef="let row">
          {{ row.data_scadenza | formatDate }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="giorni_scadenza">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Giorni Scadenza
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="{{ row.giorni_scadenza > 0 ? 'red' : 'green' }}">{{
            row.giorni_scadenza
          }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="desc_pag">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Descrizione pagamento
        </th>
        <td mat-cell *matCellDef="let row">{{ row.desc_pag }}</td>
        <td mat-footer-cell *matFooterCellDef>
          <span class="colorPrimary">In Scadenza :</span
          >{{ getTotalPagare() | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="a_saldo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Da pagare</th>
        <td mat-cell *matCellDef="let row">
          <button mat-button *ngIf="row.a_saldo < 0" color="warn">
            {{ row.a_saldo }}
          </button>
          <button mat-button *ngIf="row.a_saldo > 0">{{ row.a_saldo }}</button>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <span class="colorPrimary">Scaduti :</span>
          {{ getTotalPagati() | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
        <td mat-cell *matCellDef="let row" class="tipo">
          <span
            matBadge="{{ row.tipo }}"
            matBadgeColor="{{ row.tipo === 'RB' ? 'warn' : 'primary' }}"
          >
          </span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="id_fattura">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-raised-button
            (click)="downloadPdf('fat', row.id_fattura)"
          >
            <mat-icon>download</mat-icon>
            Fattura
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          Non ho trovato dati per il valore "{{ input.value }}"
        </td>
      </tr>
    </table>
  </div>
</div>
<div
  class="page"
  *ngIf="dataSource && dataSource.data && dataSource.data.length === 0"
>
  <app-alert-box msg="Nessuna scadenza trovata" type="warning"></app-alert-box>
</div>
