import { BookService } from 'src/app/services/book.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import * as timezone from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

interface option {
  tipoformato: string;
  dataesportazione: Date;
}

@Component({
  selector: 'export-images',
  templateUrl: './export-images.component.html',
  styleUrls: ['./export-images.component.scss'],
})
export class ExportImagesComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() title = 'Esportazione immagini';
  @Input() subtitle = '';
  @Input() secondSubtitle = '';
  @Input() icon = 'camera_alt';
  @Input() type = '';
  @Input() disablexport = false;
  loading = false;
  @Output() changeDate = new EventEmitter();

  subscription: Subscription = null;

  public options: option = {
    tipoformato: '',
    dataesportazione: new Date(),
  };
  constructor(
    private bookservice: BookService,
    private toastservice: ToastrService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.disablexport =
        this.subtitle.split(' ')[0] ===
        moment(new Date()).format('DD-MM-YYYY');
      if (this.secondSubtitle) {
        this.options.dataesportazione = moment(
          this.secondSubtitle,
          'DD-MM-YYYY hh:mm'
        ).toDate();
      }
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  public export() {
    this.loading = true;
    let selectedDate = '';
    selectedDate = timezone(new Date()).format(
      'DD-MM-YYYY hh:mm'
    );

   this.subscription =  this.bookservice.getExport(selectedDate).subscribe({
      next: (data: any) => {
        this.toastservice.info(
          'Il download sta per iniziare'
        );
        const blob = new Blob([data], {
          type: 'application/json',
        });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = `copertine.zip`;
        a.click();
        this.changeDate.emit(true);
      },
      error: (err) => {
        console.error(err);
        this.toastservice.error(
          'Errore nel download, file inesistente forse non ci sono state caricate copertina'
        );
      },
      complete: () => (this.loading = false),
    });
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }
}
