<div *ngIf="!book">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</div>
<div class="row" *ngIf="book">
  <div class="col-3">
    <app-image-view [ean]="book.ean" [type]="'thumb'"></app-image-view>
    <div *ngIf="!loadingScheda && novita">
      <embed
        [attr.src]="novita"
        type="application/pdf"
        width="100%"
        height="500px"
        *ngIf="novita"
      />
    </div>
    <div *ngIf="!loadingScheda && !novita">
      <h4>SCHEDA PROMOZIONALE NON PRESENTE</h4>
    </div>
    <div *ngIf="loadingScheda"></div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  </div>
  <div class="col-9">
    <!-- controllo presenza "titolo esteso" (book.test) -->
    <h2>{{ (book.test ? book.test : book.titolo) | uppercase }}</h2>
    <h3>[ISBN]: {{ book.ean }}</h3>

    <mat-tab-group
      [selectedIndex]="selected.value"
      (selectedIndexChange)="getStats($event)"
    >
      <mat-tab label="Informazioni generali">
        <mat-card>
          <div *ngIf="1 * book.cod_autore_1">
            <strong>Autori:</strong>

            <span *ngIf="1 * book.cod_autore_1">
              <button
                mat-button
                color="accent"
                (click)="select(book.cod_autore_1, book.autore_1, 'autore')"
              >
                {{ book.autore_1 | uppercase }}
              </button>
            </span>
            <span *ngIf="1 * book.cod_autore_2">
              <button
                mat-button
                color="accent"
                (click)="select(book.cod_autore_2, book.autore_2, 'autore')"
              >
                {{ book.autore_2 | uppercase }}&nbsp;
              </button>
            </span>
            <span *ngIf="1 * book.cod_autore_3">
              <button
                mat-button
                color="accent"
                (click)="select(book.cod_autore_3, book.autore_3, 'autore')"
              >
                {{ book.autore_3 | uppercase }}&nbsp;
              </button>
            </span>
            <span *ngIf="1 * book.cod_autore_4">
              <button
                mat-button
                color="accent"
                (click)="select(book.cod_autore_4, book.autore_4, 'autore')"
              >
                {{ book.autore_4 | uppercase }}
              </button>
            </span>
          </div>
          <div *ngIf="1 * book.cod_argomento_1">
            <strong>Argomenti:</strong>
            {{ book.argomento }}
            <span *ngIf="1 * book.cod_argomento_2"
              >, {{ book.argomento_2 }}</span
            >
            <span *ngIf="1 * book.cod_argomento_3"
              >, {{ book.argomento_3 }}</span
            >
          </div>
          <div *ngIf="book.categoria" class="my-2">
            <strong>Categoria:</strong> {{ book.categoria }}
          </div>
          <div *ngIf="book.genere" class="my-2">
            <strong>Genere:</strong> {{ book.genere }}
          </div>

          <div *ngIf="1 * book.anno_edizione > 0" class="my-2">
            <strong>Edizione :</strong> anno : {{ book.anno_edizione }} mese :
            {{ book.mese_edizione }} numero : {{ book.numero_edizione }}
          </div>

          <div *ngIf="book.formato != '0.00 X 0.00'" class="my-2">
            <strong>Formato:</strong> {{ book.formato }}
          </div>
          <div *ngIf="book.data_pubblicazione" class="my-2">
            <strong>Data pubblicazione:</strong>
            {{ formatData(book.data_pubblicazione) }}
          </div>
          <div *ngIf="book.collana" class="my-2">
            <strong>Collana:</strong> {{ book.collana }}
            <span *ngIf="1 * book.ncol > 0"
              ><strong>Numero collana</strong>{{ book.ncol }}</span
            >
          </div>
          <div *ngIf="1 * book.numero_pagine" class="my-2">
            <strong>Numero pagine:</strong> {{ book.numero_pagine }}
          </div>
          <div *ngIf="book.numero_volume != ' '" class="my-2">
            <strong>Volume:</strong> {{ book.numero_volume }} di
            {{ book.numero_volumi }}
          </div>
          <div *ngIf="book.prezzo" class="my-2">
            <strong>Prezzo:</strong> {{ book.prezzo }}&nbsp;
            <span class="my-2"
              ><strong book.iva>IVA:</strong> {{ book.iva }}</span
            >
          </div>

          <div *ngIf="1 * book.anno_giro" class="my-2">
            <strong>Giro :</strong> {{ book.numero_giro }} /
            {{ book.anno_giro }}
          </div>

          <div class="my-2" *ngIf="book.interesse">
            <strong>Interesse :</strong>
            {{ book.interesse === "1" ? "Nazionale" : "Locale" }}
            {{
              book.zona_interesse !== " " ? "Zona : " + book.zona_iteresse : ""
            }}
          </div>

          <div *ngIf="book.stato" class="my-2">
            <strong>Stato:</strong> {{ book.stato }}
          </div>
          <!--      
      -->
          <div class="my-2" *ngIf="book.tags != ''">
            <p class="description" [innerHTML]="book.tags"></p>
          </div>
          <mat-card *ngIf="book.abstract" class="mt-2">
            <mat-card-title>Abstract</mat-card-title>
            <p class="description" [innerHTML]="book.abstract"></p>
          </mat-card>
          <mat-card *ngIf="book.note" class="mt-2">
            <mat-card-title>Note</mat-card-title>
            <p class="description" [innerHTML]="book.note"></p>
          </mat-card>
        </mat-card>
      </mat-tab>
      <mat-tab label="Giacenza">
        <app-tabella
          *ngIf="!user.isCliente()"
          [data]="giacenza"
          [loading]="false"
          title="Giacenza"
          subtitle=""
          icon="grid_on"
          type="ep_card-header-primary"
          style="width: 100%"
        ></app-tabella>
       <!--  <div *ngIf="user.isCliente()">
          <app-testo
            *ngFor="let cda of giacenza.rows"
            [name]="cda[0]"
            [number]="cda[1]"
            title="Giacenza"
            icon="assessment"
          ></app-testo>
        </div> -->
      </mat-tab> 

      <mat-tab label="Sell IN" *ngIf="!user.isCliente()">
        <mat-card>
          <div *ngIf="loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <mat-form-field appearance="" *ngIf="selectedIndex === 2">
            <mat-label>Intervallo date</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input
                matStartDate
                formControlName="start"
                placeholder="Data inizio"
                (dateChange)="getStats(2)"
              />
              <input
                matEndDate
                formControlName="end"
                placeholder="Data fine"
                (dateChange)="getStats(2)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <app-tabella
            *ngIf="!loading"
            [data]="sellIn"
            [loading]="false"
            title="Sell In"
            icon="grid_on"
            type="ep_card-header-primary"
            style="width: 100%"
          >
          </app-tabella>
        </mat-card>
      </mat-tab>

      <mat-tab label="Sell OUT" *ngIf="!user.isCliente()">
        <mat-card>
          <div *ngIf="loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <mat-form-field appearance="" *ngIf="selectedIndex === 3">
            <mat-label>Intervallo date</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input
                matStartDate
                formControlName="start"
                placeholder="Data inizio"
                (dateChange)="getStats(3)"
              />
              <input
                matEndDate
                formControlName="end"
                placeholder="Data fine"
                (dateChange)="getStats(3)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error
              *ngIf="range.controls.start.hasError('matStartDateInvalid')"
              >Data inizio non valida</mat-error
            >
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
              >Data fine non valida</mat-error
            >
          </mat-form-field>
          <app-tabella
            *ngIf="!loading"
            [data]="sellOut"
            [loading]="false"
            title="Sell Out"
            subtitle=""
            icon="grid_on"
            type="ep_card-header-primary"
            style="width: 100%"
          >
          </app-tabella>
        </mat-card>
      </mat-tab>

      <mat-tab label="Trova Libreria" *ngIf="user.isEditore()">
        <mat-card>
          <form>
            <mat-form-field class="example-full-width">
              <mat-label>INSERISCI PROVINCIA</mat-label>
              <input
                type="text"
                aria-label="Provincia"
                matInput
                [formControl]="provinceCtrl"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                (optionSelected)="findBookstoreByProvincia($event)"
              >
                <mat-option
                  *ngFor="let provincia of filtereditems"
                  [value]="provincia.sigla"
                >
                  {{ provincia.nome }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
          <app-tabella
            *ngIf="!loading"
            [data]="librerie"
            [loading]="false"
            [scroll]="true"
            title="Librerie"
            subtitle=""
            icon="grid_on"
            type="ep_card-header-primary"
            style="width: 100%"
          >
          </app-tabella>
          Le librerie mostrate fanno riferimento alle movimentazioni delgi
          ultimi 60 giorni.
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
