import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/services/document.service';


@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  @Input() element: any;
  file: string;
  loading = { ddt: false, fat: false};
  constructor(
    private documentservice: DocumentService,
    
    private toast: ToastrService) { }

  ngOnInit() {
  }


  downloadPdf(type: string, id: string){
    this.loading[type] = true;

    const request = this.documentservice.fileRequest(type, id);
      fetch(request, {cache: "reload"})
      .then(response => {
        if(response.status === 200){
        response.blob().then(blob =>{
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = `${type}_${id}.pdf`;
            a.click();
            this.loading[type] = false;
            //this.file = URL.createObjectURL(blob);
          });  
        }else{
          this.loading[type] = false;
          this.toast.error('File non trovato');
        }
      });
  }
}
