import { OrdiniService } from 'src/app/services/ordini.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from './../../services/storage.service';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { iUser } from 'src/app/models/user.model';
import { SessionStorageService } from 'src/app/services/session.service';
import { CarrelloService } from 'src/app/services/carrello.service';
import { OauthService } from 'src/app/services/oauthservice.service';
import {
  eventModel,
  MessageService,
} from 'src/app/services/message.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

export interface Stats {
  spediti: Array<any>;
  in_spedizione: Array<any>;
}

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit, OnDestroy {
  user: iUser;
  logged = false;
  loadingCart = false;
  carrelloItems = [];
  subscriptions: Subscription[] = [];

  constructor(
    private session: SessionStorageService,
    private storage: LocalStorageService,
    private oauthservice: OauthService,
    private carrelloservice: CarrelloService,
    private ordini: OrdiniService,
    private messageservice: MessageService,
    private toast: ToastrService
  ) {
    if (this.oauthservice.checkTokenIsValid()) {
      this.getCarrello();
      this.logged = true;
    } else {
      this.logged = false;
    }

    const sub = this.messageservice
      .listenEvent()
      .subscribe((data: eventModel) => {
        if (data.type === 'cart') {
          this.carrelloItems = data.event;
        }
      });
    this.subscriptions.push(sub);
  }

  ngOnInit(): void {
    this.user = new iUser(this.session.get('usr'));
  }

  public async stats() {
    const oggi = moment();
    const ieri = moment().subtract(1, 'days');
    const sub = this.ordini.getStats().subscribe((stats: Stats) => {
      const in_spedizione = stats.in_spedizione;
      const spediti_oggi = _.filter(stats.spediti, {
        data_ddt: oggi.format('YYYYMMDD'),
      });
      const spediti_ieri = _.filter(stats.spediti, {
        data_ddt: ieri.format('YYYYMMDD'),
      });

      if (in_spedizione.length) {
        this.toast.success('Hai della merce in spedizione');
      }

      if (spediti_oggi.length) {
        this.toast.success('Hai della merce spedita oggi');
      }

      if (spediti_ieri.length) {
        this.toast.success('Hai della merce spedita ieri');
      }
    });

    this.subscriptions.push(sub);
  }

  getCarrello() {
    this.loadingCart = true;
    const sub = this.carrelloservice
      .getCart()
      .subscribe((data: any) => {
        if (data.success) {
          this.carrelloItems = data.data;
        }
        this.loadingCart = false;
      });

      this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
