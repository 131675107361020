import { SessionStorageService } from './../../services/session.service';
import { iUser } from './../../models/user.model';
import { ToastrService } from 'ngx-toastr';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { LocalStorageService } from '../../services/storage.service';
import { BookService } from '../../services/book.service';
import * as moment from 'moment';
import { dataTable } from 'src/app/models/dataTable.model';
import { FormControl, FormGroup } from '@angular/forms';
import * as province from '../../../assets/province.json';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
})
export class BookComponent implements OnInit, OnDestroy {
  public loadingScheda = false;
  public novita;
  public book: any = null;
  public loading = true;
  public statsLoading = {
    giacenza: false,
    sellin: false,
    sellout: false,
  };
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  subscriptions: Subscription[] = [];
  @Input() code: string = null;
  @Output() clickEvent: any = new EventEmitter<any>();

  giacenza: dataTable = {
    header: [
      'descrizione',
      'giacenza fisica',
      'deposito',
      'ultimo aggiornamento',
    ],
    rows: [],
  };

  sellIn: dataTable = {
    header: [
      'Data',
      'Codice',
      'Cliente',
      'Città',
      'Quantità',
    ],
    rows: [],
    totale: { col: 'Quantità', value: 0 },
  };

  sellOut: dataTable = {
    header: [
      'Data',
      'Codice',
      'Cliente',
      'Città',
      'Quantità',
    ],
    rows: [],
    totale: { col: 'Quantità', value: 0 },
  };

  librerie: dataTable = {
    header: [
      'Ragione Sociale',
      'Indirizzo',
      'Città',
      'Provincia',
      'Quantità',
    ],
    rows: [],
    totale: { col: 'Quantità', value: 0 },
  };
  states: any = (province as any).default;
  selected = new FormControl(0);
  provinceCtrl = new FormControl();
  selectedIndex = 0;
  selectedProvincia: string;
  filtereditems = [];
  @Input() onlyCda = 0;
  user: iUser;
  constructor(
    private bookservice: BookService,
    private toastservice: ToastrService,
    private domSanitizer: DomSanitizer,
    private session: SessionStorageService
  ) {
    this.provinceCtrl.valueChanges.subscribe((data) => {
      this.filtereditems = _.filter(this.states, (o) => {
        return o && data
          ? o.nome
              .toLowerCase()
              .includes(data.toLowerCase()) > 0
          : [];
      });
    });
  }

  ngOnInit(): void {
    this.getBookDetail(this.code);

    const start = moment().subtract(12, 'M').toDate();
    const stop = moment().toDate();
    this.range.controls['start'].setValue(start);
    this.range.controls['end'].setValue(stop);
    const usr = this.session.get('usr');
    this.user = new iUser(usr);
  }

  getStats(value) {
    this.selectedIndex = value;
    /// giacenza
    if (value === 1) {
      this.statsLoading.giacenza = true;
      this.giacenza.rows = [];
      const sub = this.bookservice
        .getBookStats(this.code, 'giacenza', this.onlyCda)
        .subscribe((data: any) => {
          let g = 0;
          data.forEach((element) => {
            this.giacenza.rows.push([
              element.descrizione_mag,
              Number(element.giacenza_fisica) === 0
                ? ''
                : Number(element.giacenza_fisica),
              Number(element.deposito) === 0
                ? ''
                : Number(element.deposito),
              element.ultimo_aggiornamento != '' &&
              element.ultimo_aggiornamento != null
                ? this.formatData(
                    element.ultimo_aggiornamento
                  )
                : '', // annomeseanno
            ]);
            g = Number(g) + Number(element.giacenza);
          });
          this.statsLoading.giacenza = false;
        });

      this.subscriptions.push(sub);
    }

    if (value == 2) {
      this.statsLoading.sellin = true;
      this.sellIn.rows = [];
      const start = moment(this.range.value.start).format(
        'YYYYMMDD'
      );
      const stop = moment(this.range.value.end).format(
        'YYYYMMDD'
      );
      let totale = 0;
      const sub = this.bookservice
        .getBookStats(
          this.code,
          'sellin',
          false,
          start,
          stop
        )
        .subscribe((data: any) => {
          data.forEach((item) => {
            this.sellIn.rows.push([
              this.formatData(item.data_bolla),
              item.codice_fattura,
              item.ragione_sociale,
              item.citta,
              Number(item.quantita),
            ]);
            totale = Number(item.quantita) + totale;
            this.sellIn.totale.value = totale;
          });

          this.statsLoading.sellin = false;
        });

      this.subscriptions.push(sub);
    }

    if (value == 3) {
      this.statsLoading.sellout = true;
      this.sellOut.rows = [];
      let totale = 0;
      const start = moment(this.range.value.start).format(
        'YYYYMMDD'
      );
      const stop = moment(this.range.value.end).format(
        'YYYYMMDD'
      );
      const sub = this.bookservice
        .getBookStats(
          this.code,
          'sellout',
          false,
          start,
          stop
        )
        .subscribe((data: any) => {
          data.forEach((item) => {
            this.sellOut.rows.push([
              this.formatData(item.data_bolla),
              item.codice_fattura,
              item.ragione_sociale,
              item.citta,
              Number(item.quantita),
            ]);
            totale = Number(item.quantita) + totale;
            this.sellOut.totale.value = totale;
          });
          this.statsLoading.sellout = false;
        });

      this.subscriptions.push(sub);
    }
  }

  /**
   * getBookDetail
   */
  getBookDetail(code) {
    this.loading = true;
    const sub = this.bookservice.getBook(code).subscribe(
      (data: any) => {
        this.book = data;
        this.loading = false;
        this.loadSchedaNovita();
      },
      (error: any) => {
        this.bookservice.hadleError(error);
      }
    );

    this.subscriptions.push(sub);
  }

  select(code, description, event) {
    this.clickEvent.emit({
      code: code,
      event: event,
      description: description,
    });
  }

  formatData(data) {
    return moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY');
  }
  schedaNovita() {
    this.toastservice.warning('In fase di implementazione');
  }

  findBookstoreByProvincia(event) {
    this.selectedProvincia = event.option.value;
    const start = moment()
      .subtract(60, 'days')
      .format('YYYYMMDD');
    const stop = moment().format('YYYYMMDD');
    let totale = 0;
    if (this.selectedProvincia) {
      this.loading = true;
      const sub = this.bookservice
        .findBookstore(
          start,
          stop,
          this.book.cod,
          this.selectedProvincia
        )
        .subscribe((data: any) => {
          data.forEach((item) => {
            this.librerie.rows.push([
              item.ragione_sociale,
              item.indirizzo,
              item.citta,
              item.prov,
              Number(item.quantita),
            ]);
            totale = Number(item.quantita) + totale;
            this.librerie.totale.value = totale;
          });
          this.loading = false;
        });

      this.subscriptions.push(sub);
    }
  }

  /**
   *
   */
  loadSchedaNovita() {
    this.loadingScheda = true;
    const request = this.bookservice.schedaNovitaRequest(
      this.book.ean
    );
    fetch(request, { cache: 'reload' }).then((response) => {
      if (response.status != 404) {
        response.blob().then((blob) => {
          this.novita =
            this.domSanitizer.bypassSecurityTrustResourceUrl(
              URL.createObjectURL(blob)
            );
          this.loadingScheda = false;
        });
      } else {
        this.loadingScheda = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
