<div class="container-fluid">
  <div class="row">
      <div class="col-12"> 
          <div class="ep_card"> 
              <div class="ep_card-footer">
                  <mat-form-field appearance="">
                      <mat-label>Mostra</mat-label>
                      <!-- <mat-select [(value)]="limit" (selectionChange)="doReport()"> 
                        <mat-option value="5">5</mat-option>
                        <mat-option value="10">10</mat-option>
                        <mat-option value="15">15</mat-option>
                      </mat-select> -->
                      <input matInput type="number" [(ngModel)]="limit" (change)="doReport()" min="1">
                    </mat-form-field> 
                    &nbsp;
            <mat-form-field appearance="">
              <mat-label>Intervallo date</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker" matTooltip="Seleziona l'intervallo di tempo premendo sul calendario">
                <input matStartDate formControlName="start" placeholder="Data inizio" readonly>
                <input matEndDate formControlName="end" placeholder="Data fine" (dateChange)="doReport()" readonly>
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Data inizio non valida</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Data fine non valida</mat-error>
            </mat-form-field>
            <label id="example-radio-group-label">Tipo : &nbsp;</label>
            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              [ngModel]="selectedType">
              <mat-radio-button class="example-radio-button" *ngFor="let type of types" (change)="doReport(type.cod)" [value]="type.cod">
                {{type.description}}&nbsp;
              </mat-radio-button>
            </mat-radio-group>
          
              </div>
            </div>
          
      </div>
  </div>
  <div class="row">
     <div class="col-6">
          <app-grafico
          [loading]="loadData"
          [chartOption]="graphOption"
          title=""
          subtitle=""
          icon="pie_chart"
          type="ep_card-header-danger"
          style="width: 100%;"
        ></app-grafico>
      </div>
      <div class="col-6">
           <app-tabella
              [ngClass]="{'cursor': dataTable.rows.length > 0}"
              (rowClick)="openStatsBook($event)"
              [loading]="loadData"
              [data]="dataTable"
              [scroll]="true"
              title="Top Libri"
              icon="grid_on"
              type="ep_card-header-success"
              style="width: 100%;"
           ></app-tabella>
      </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="ep_card"> 
        <div class="search-field" *ngIf="user.isEditore()">
          <form>
            <mat-form-field class="example-full-width">
              <mat-label>Cerca Libreria</mat-label>
              <input matInput [formControl]="searchCtrl">
            </mat-form-field>
        </form>
      </div>
        <app-tabella
              [ngClass]="{'cursor': dataTable.rows.length > 0}"
              [loading]="loadDataLibrerie"
              [data]="dataTableLibrerie"
              [scroll]="true"
              (rowClick)="openMovimentiBookstore($event)"
              title="Top Librerie"
              icon="grid_on"
              type="ep_card-header-success"
              style="width: 100%;"
           ></app-tabella>
      </div>
    </div>
  </div>
</div>
