
<div class="loading-shade" *ngIf="loader">
  <mat-progress-bar  mode="indeterminate" color="accent"></mat-progress-bar>
  </div>
  <div class="pag" *ngIf="!loader" >
    <div class="row">
      <div class="col">
        <mat-form-field style="width: 100%;">
          <mat-label>Cerca</mat-label>
          <input matInput (keyup)="applyFilter($event)"  #input>
        </mat-form-field>
      </div>
      
      <export-table [data]="dataExport" *ngIf="dataExport.length"></export-table>
    </div>

    <div *ngIf="dataSource && !dataSource.data.length">
      <app-alert-box type="warning" msg="Nessun ordine trovato"></app-alert-box>
    </div>

      <div class="mat-elevation-z8" *ngIf="!loader && dataSource.data.length" >
          <table mat-table
          [dataSource]="dataSource" multiTemplateDataRows   (matSortChange)="sortData($event)" class="mat-elevation-z4" matSort>
     <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns; let i=index">
     <th mat-header-cell *matHeaderCellDef mat-sort-header> {{columnsName[i]}} </th>
     <!--  <th mat-header-cell *matHeaderCellDef  >  {{columnsName[i]}}  </th> -->
       <td mat-cell *matCellDef="let element" class="{{column}}"> 
        <div *ngIf="column!=='stato' && column!=='scadenza'" > 
            <mat-icon class="open" *ngIf="column==='nr_ordine'"  (click)="expandedElement = expandedElement === element ? null : element; openRighe(element);">folder_open</mat-icon>
            {{format(column, element[column])}}
        </div>  
      <div *ngIf="column==='scadenza'" class="scadenza_badge">
         {{element[column]}}
      </div> 
       <div  *ngIf="column==='stato'"    matTooltip="{{element[column] | statoDescrizione}}" >
        <span *ngIf="element[column]!==' '" >{{element[column]}}</span>
       </div>  
      </td> 
       <td mat-footer-cell *matFooterCellDef class="totale" >{{total(column)}}</td>
     </ng-container>
     <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
     <ng-container matColumnDef="expandedDetail">
       <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
         <div class="example-element-detail" *ngIf="expandedElement === element && rowsLoader"   >
              <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
         </div>
       </td>
     </ng-container>
     <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
     <tr mat-row *matRowDef="let element; columns: displayedColumns;"
         class="example-element-row"
         [class.example-expanded-row]="expandedElement === element"
        >
     </tr>
     <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
     <tr class="mat-row" *matNoDataRow>  <td class="mat-cell" colspan="4">Non ho trovato dati per il valore "{{input.value}}"</td></tr>
     <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
   </table>
      </div>
  </div>
