<div *ngIf="loading">
  <mat-spinner [diameter]="35" class="mx-auto"></mat-spinner>
</div>

<div class="container-fluid" *ngIf="!loading">
  <!-- Lista dei filtri -->

  <div *ngIf="!selectedBook; else bookSelectedTemplate">
    <mat-card class="row m-1">
      <mat-form-field *ngFor="let select of filters" class="col-1">
        <mat-label>{{ select.filterName }}</mat-label>
        <mat-select
          [(ngModel)]="select.selected"
          (ngModelChange)="
            getListaLanci(filters[0].selected, filters[1].selected)
          "
        >
          <mat-option
            *ngFor="let selectVal of select.filterData"
            [value]="selectVal.id"
          >
            {{ selectVal.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card>
    <div *ngIf="books.length > 0; else noBookTemplate">
      <mat-card class="book mt-3" *ngFor="let book of books">
        <div class="row" [id]="book.cod">
          <div class="col-2">
            <app-image-view [ean]="book.ean" [type]="'mini'"></app-image-view>
          </div>
          <div class="col-10">
            <h2>{{ booktitolo | uppercase }}</h2>
            <h3>[ISBN]: {{ book.ean }}</h3>
            <p>
              Editore :
              <button mat-button color="accent">
                {{ book.editore | uppercase }}
              </button>
              Autore :
              <button mat-button color="accent">
                {{ book.autore | uppercase }}
              </button>
              Collana
              <button mat-button color="primary">
                {{ book.collana }}
              </button>
              <span *ngIf="book.cod_argomento_1 > 0">
                Argomento:
                <button mat-button color="primary">
                  {{ book.argomento }}
                </button>
              </span>
            </p>
            <div class="row">
              <div class="col">
                Data di pubblicazione :
                {{ book.data_pubblicazione.substring(6, 8) }}-{{
                  book.data_pubblicazione.substring(4, 6)
                }}-{{ book.data_pubblicazione.substring(0, 4) }}
              </div>
              <div class="col">Categoria : {{ book.categoria }}</div>
              <div class="col">Stato : {{ book.stato | statoArticolo }}</div>
            </div>
            <div align="right">
              <button
                mat-raised-button
                color="primary"
                (click)="setBook(book.OID)"
              >
                Visualizza
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <ng-template #noBookTemplate>
      <mat-card class="row m-1">
        <mat-card-header>
          La ricerca non ha prodotto risultati - Prova a cambiare i filtri
        </mat-card-header>
      </mat-card>
    </ng-template>
  </div>

  <!--Else template-->
  <ng-template #bookSelectedTemplate>
    <book-form
      (OnClickNovita)="closeBookForm()"
      [oid]="selectedBook"
      [viewOnly]="true"
    ></book-form>
  </ng-template>
</div>
