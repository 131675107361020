import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-nuovo-autore',
  templateUrl: './nuovo-autore.component.html',
  styleUrls: ['./nuovo-autore.component.scss']
})
export class NuovoAutoreComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NuovoAutoreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
  
    ngOnInit(): void {
    }
    onUndoClick(): void {
      this.dialogRef.close();
    }
}
