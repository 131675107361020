import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-testo',
  templateUrl: './testo.component.html',
  styleUrls: ['./testo.component.scss']
})
export class TestoComponent implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() icon = '';
  @Input() type = '';
  @Input() number = null;
  @Input() name = null;
  @Input() loading = false;

  constructor() { }

  ngOnInit(): void {}

}
