import { SessionStorageService } from './../../../services/session.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Giro } from 'src/app/models/giro.model';
import { iUser } from 'src/app/models/user.model';
import { BookService } from 'src/app/services/book.service';
import { MessageService } from 'src/app/services/message.service';
import { LocalStorageService } from 'src/app/services/storage.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-giri-cliente',
  templateUrl: './giri-cliente.component.html',
  styleUrls: ['./giri-cliente.component.scss']
})
/** prendere anche i giri passati dal database e aggiungere 4/5 giorni alla data di scadenza */
  /** alla selezione del giro deve mostrare il componente document-download */
export class GiriClienteComponent implements OnInit { 
  loading = false;
  user: iUser;
  oggi = new Date().toISOString();
  @Input() giri:Array<Giro>;
  giroselezionato: Giro;
  constructor(
    private storage: LocalStorageService,
    private session: SessionStorageService,
    private bookservice: BookService
  ) { }

  ngOnInit(): void {
      this.user = new iUser(this.session.get('usr'));  
      //this.getGiri();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.giri.currentValue && changes.giri.currentValue.length > 0 ) {
      this.giri = _.filter(this.giri, (giro: Giro) => { 
        return moment(giro.data_distribuzione, 'YYYY-MM-DD').add(1, 'month').diff(moment())> 0 ? true: false ;
      });
    }
  }


  formatDataConsegna(data){
    const giorno = moment(data).add(5 ,'d').format('DD');
    return giorno + '/'+ moment(data).add(6 ,'d').format('DD-MM-YYYY');
  }

  formatDataInvio(data){
    return moment(data).subtract(1 ,'d').format('YYYY-MM-DD');
  }

  isSelected(giro:Giro, giri) {
    //secondo il calendario agenti, ad agosto 2021, le cedole sono scaicabili 2 giorni dopo la chiusura del giro (solitamente sono 5)
    //const daysToAdd = (moment().format('M') === '8') ? 2 : 4;
    const i = _.findLastIndex(giri, giro => { return moment(giro.data_scadenza).isSameOrBefore(moment()) });
    if ( giri[i] && giro.descrizione === giri[i].descrizione) {
      this.giroselezionato = giri[i];
     // this.storage.set('selectedGiro',this.giroselezionato);
      return 'rowTr selected';
    } 
    return 'rowTr';
  }
    //download pdf calendario giri
    downloadCalendario(){
      const a = document.createElement('a');
      a.href = '/assets/calendario_agenti.pdf';
      a.download = `calendario_agenti.pdf`;
      a.click();
    }
}
