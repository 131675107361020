<!-- articoli in ordine -->
<div class="loading-shade" *ngIf="loader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="pag" *ngIf="!loader">
  <div class="row">
    <div class="col">
      <mat-form-field style="width: 100%">
        <mat-label>Cerca</mat-label>
        <input matInput (keyup)="applyFilter($event)" #input />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <mat-select [(value)]="selected" (selectionChange)="filtraCausale()">
          <mat-option value="0">Tutti</mat-option>
          <mat-option *ngFor="let item of causali" value="{{ item.cod }}">{{
            item.descrizione
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-4">
      <div class="checkbox">
        <mat-checkbox
          [(ngModel)]="visualizzaArrivi"
          color="primary"
          (ngModelChange)="arriviFilter()"
        >
          Visualizza solo la merce in spedizione
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div *ngIf="dataSource && !dataSource.data.length">
    <app-alert-box
      type="warning"
      msg="Nessun articolo in ordine"
    ></app-alert-box>
  </div>
  <div
    class="mat-elevation-z8"
    *ngIf="dataSource && dataSource.data && dataSource.data.length"
  >
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      (matSortChange)="sortData($event)"
      class="mat-elevation-z4"
      matSort
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of displayedColumns; let i = index"
      >
        <!--  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{columnsName[i]}} </th> -->
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ columnsName[i] }}
        </th>
        <td mat-cell *matCellDef="let element" class="{{ column }}">
          <span *ngIf="column !== 'titolo'">{{
            formatField(element[column])
          }}</span>
          <span *ngIf="column === 'titolo'">{{
            formatField(element[column]) | bookTitle
          }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef>{{ total(column) }}</td>
      </ng-container>
      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="example-element-detail"
            *ngIf="expandedElement === element"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <app-view-order [data]="element.childs"></app-view-order>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          Non ho trovato dati per il valore "{{ input.value }}"
        </td>
      </tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>
  </div>
</div>
