import { Articolo, ArticoloTools } from './../models/articolo.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statoArticolo'
})
export class StatoArticoloPipe implements PipeTransform {
  
  articoloTools: ArticoloTools = new ArticoloTools();
  transform(value: Articolo, ...args: unknown[]): string {
    return  this.articoloTools.stato(value);
  }

}