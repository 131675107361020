<h1 mat-dialog-title>{{data.titolo}}</h1>
<div mat-dialog-content> 
        <app-tabella
            [data]="movimenti"
            title="Movimenti"
            subtitle=""
            icon="grid_on"
            type="ep_card-header-primary"
            style="width: 100%;">
        </app-tabella>
</div>
<mat-dialog-actions>
    <button mat-button (click)="onUndoClick()" cdkFocusInitial color="primary" class="full-width">Chiudi</button>
</mat-dialog-actions>


