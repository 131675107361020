<mat-card class="card">
    <div class="{{(error) ? 'richiestaStampaRadioList error' : 'richiestaStampaRadioList'}}">
        <div class="formElements">
            <span class="aspNetDisabled"><input id="1" (click)="getSelected($event)" type="radio" name="su" value="1" [checked]="(value==='1') ? 'checked' : ''" [disabled]="disabled"><label for="1">Richiedo la stampa (25€/titolo)</label></span>
        </div>
        <div class="formElements">
            <span class="aspNetDisabled"><input id="0" (click)="getSelected($event)" type="radio" name="No" value="0" [checked]="(value==='0') ? 'checked' : ''" [disabled]="disabled"><label for="0">Invierò la scheda stampata.
                <br>Autorizzo altresì il CDA a stampare per mio conto il file pdf della scheda al costo di 25€/titolo nel caso non riuscissi a consegnare al CDA le mie copie 
                <strong *ngIf="date" >entro {{data}}</strong>.</label></span>
        </div>
    </div>
</mat-card>
