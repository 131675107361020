<div class="ep_card">
  <div class="ep_card-header ep_card-header-icon" [ngClass]="type">
    <div class="ep_card-icon"><i class="material-icons">{{icon}}</i></div>
    <p class="ep_card-category">{{title}}</p>
    <h3 class="ep_card-title">{{subtitle}}</h3>
  </div>
  <div class="ep_card-footer">
    <div class="row" *ngIf="user.isAdmin()">
      <div class="col-4">
        <mat-form-field class="full-width">
          <mat-label>Seleziona un Editore</mat-label>
          <select matNativeControl [(ngModel)]="selected.editore" (change)="getAnniList()">
            <!-- <option   value=0>Seleziona</option> -->
            <option *ngFor="let item of editori" value="{{item.cod}}">{{item.descrizione}}</option>
          </select>
          <mat-spinner matSuffix [diameter]="15" class="mx-auto" *ngIf="loader.editore"></mat-spinner>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="full-width" *ngIf="selected.editore">
          <mat-label>anno</mat-label>
          <select matNativeControl [(ngModel)]="selected.anno" (change)="getMesiList()">
            <!-- <option   value=0>Seleziona</option> -->
            <option *ngFor="let item of anni" value="{{item.anno}}">{{item.anno}}</option>
          </select>
          <mat-spinner matSuffix [diameter]="15" class="mx-auto" *ngIf="loader.anno"></mat-spinner>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="full-width" *ngIf="selected.anno">
          <mat-label>mese</mat-label>
          <select matNativeControl [(ngModel)]="selected.mese">
            <!-- <option   value=0>Seleziona</option> -->
            <option *ngFor="let item of mesi" value="{{item.mese}}">{{printMonth(item.mese)}}</option>
          </select>
          <mat-spinner matSuffix [diameter]="15" class="mx-auto" *ngIf="loader.mese"></mat-spinner>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="user.isEditore()">
      <div class="col-6">
        <mat-form-field class="full-width" *ngIf="selected.editore">
          <mat-label>anno</mat-label>
          <select matNativeControl [(ngModel)]="selected.anno" (change)="getMesiList()">
            <!-- <option   value=0>Seleziona</option> -->
            <option *ngFor="let item of anni" value="{{item.anno}}">{{item.anno}}</option>
          </select>
          <mat-spinner matSuffix [diameter]="15" class="mx-auto" *ngIf="loader.anno"></mat-spinner>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="full-width" *ngIf="selected.anno">
          <mat-label>mese</mat-label>
          <select matNativeControl [(ngModel)]="selected.mese">
            <!-- <option   value=0>Seleziona</option> -->
            <option *ngFor="let item of mesi" value="{{item.mese}}">{{printMonth(item.mese)}}</option>
          </select>
          <mat-spinner matSuffix [diameter]="15" class="mx-auto" *ngIf="loader.mese"></mat-spinner>
        </mat-form-field>
      </div>
    </div>
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu" *ngIf="selected.mese">Scarica il
      rendiconto</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="scaricaRendiconto('pdf')"><i class="bi bi-filetype-pdf"></i>PDF</button>
      <button mat-menu-item (click)="scaricaRendiconto('xls')"><i class="bi bi-filetype-xlsx"></i>EXCEL</button>
    </mat-menu>

    <button mat-raised-button class="ml-2" color="accent" (click)="donwloadSellInOut('sell-in')"
      *ngIf="selected.mese && !user.isAdmin()">
      <i class="bi bi-house"></i>
      Scarica i dati di sell in
    </button>

    <button mat-raised-button class="ml-2" color="accent" (click)="donwloadSellInOut('sell-out')"
      *ngIf="selected.mese && !user.isAdmin()">
      <i class="bi bi-box-arrow-up-right"></i>
      Scarica i dati di sell out
    </button>
  </div>
</div>