import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { OwlOptions } from "ngx-owl-carousel-o";
import { BookModalComponent } from "../book-modal/book-modal.component";
import { User, iUser } from "src/app/models/user.model";
import { SessionStorageService } from "src/app/services/session.service";

@Component({
  selector: "app-image-carousel",
  templateUrl: "./image-carousel.component.html",
  styleUrls: ["./image-carousel.component.scss"],
})
export class ImageCarouselComponent implements OnInit, OnChanges {
  color = "";
  user: User;
  customOptions: OwlOptions = {
    items: 8,
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoWidth: true,
    autoHeight: true,
    lazyLoad: true,
  };

  @Input() items: any[];
  @Input() title;
  @Input() rankings = false;
  @Input() type;

  @Output() changeOutput = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private session: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.user = new iUser(this.session.get("usr"));
    this.iColor();
    this.customOptions.items = this.getCarouselItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items.currentValue.length <= 5) {
      setTimeout(() => {
        this.changeOutput.emit({ startPosition: this.items.length - 1 });
      }, this.items.length * 1000);
    }
  }

  /**
   * alla selezione apro il modale
   * @param item dato che passo al modale
   */
  itemSelected(item) {
    item["codice"] = item.cod ? item.cod : item.codice;
    this.dialog.open(BookModalComponent, {
      width: "900px",
      data: {
        position: this.items.indexOf(item) + 1,
        rankings: this.rankings,
        type: this.type,
        item: item,
      },
    });
  }

  /**
   * quando la pafina cambia di wisth carico un numero di elementi diversi nei caroselli
   * @returns numnero di elementi da mostrare
   */
  getCarouselItems(): number {
    const width: number = Number(window.innerWidth);
    if (width > 1600) {
      return 8;
    }
    if (width < 1590 && width > 1112) {
      return 6;
    }
    if (width < 1112 && width > 700) {
      return 4;
    }
    return 2;
  }

  /**
   * se la pagina cambia di dimensioni e rischio bug grafici la aggiorno
   * @param event
   */
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.customOptions.items !== this.getCarouselItems()) {
      window.location.reload();
    }
  }

  /**
   * colore in base all'utente che ho
   */
  iColor() {
    switch (this.user.profile) {
      case "editore":
        this.color = "primary";
        break;
      case "admin":
        this.color = "warn";
        break;
      case "cliente":
        this.color = "accent";
        break;
    }
  }

  emitChange(event) {
    this.changeOutput.emit(event);
  }
}
