import { description } from './../../../models/books.model';
import { Giro } from './../../../models/giro.model';
import { BookService } from 'src/app/services/book.service';
import { JsonapiService } from './../../../services/jsonapi.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Ricerca } from 'src/app/models/ricerca.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DeleteQuestionComponent } from '../../delete-question/delete-question.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { iUser } from 'src/app/models/user.model';
import { LocalStorageService } from 'src/app/services/storage.service';
import { SessionStorageService } from 'src/app/services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-novita-cliente',
  templateUrl: './novita-cliente.component.html',
  styleUrls: ['./novita-cliente.component.scss'],
})
export class NovitaClienteComponent implements OnInit, OnDestroy {
  public book;
  public books: any = [];
  public booksPage: any = [];
  public links = [];
  salvaModifiche = false;
  //info riguardanti la paginaziine degli oggetti restituiti es. current-page
  public booksMeta: any = {};
  searchFilters: any = {};
  filters = [];
  yearGiro = null;
  recordPerPage = 20;

  public sortField = 'titolo';
  public option = 'list';
  public loader = false;
  public page = 1;
  action: string = 'list';
  subscriptions: Subscription[] = [];
  ricerca: Ricerca = {
    fields: [
      {
        field: 'editore',
        option: {
          type: 'select',
          list: [],
        },
      },
      {
        field: 'titolo',
        option: {
          type: 'text',
        },
      },
      {
        field: 'giro',
        option: {
          type: 'select',
          list: [],
        },
      },
      {
        field: 'ean',
        option: {
          type: 'text',
        },
      },
      {
        field: 'autore',
        option: {
          type: 'lazyUrl',
          lazyUrl: 'autore',
        },
      },
      {
        field: 'collana',
        option: {
          type: 'lazyUrl',
          lazyUrl: 'collana',
        },
      },
      {
        field: 'argomento',
        option: {
          type: 'lazyUrl',
          lazyUrl: 'argomento',
        },
      },
    ],
  };
  selectedBook = null;
  user: iUser = null;
  public fieldLoader = {
    editori: false,
    giri: false,
  };
  giro: Giro = null;
  constructor(
    private jsonapi: JsonapiService,
    private bookservice: BookService,
    private dialog: MatDialog,
    private toastservice: ToastrService,
    private messageservice: MessageService,
    private session: SessionStorageService,
    private storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.user = this.session.get('usr');
    const date = new Date();
    const fromYear = date.getFullYear() - 5;
    const toYear = date.getFullYear() + 1;
    let giriData: Array<description> = [];
    for (let i = fromYear; i <= toYear; i++) {
      giriData.push({
        cod: i.toString(),
        descrizione: i.toString(),
      });
    }
    this.getGiri();
    this.getEditori();
    // this.giro = this.storage.get('selectedGiro');
  }

  isLoading() {
    Object.keys(this.loader).forEach((element) => {
      if (this.loader[element]) {
        return true;
      }
    });
    return false;
  }

  loadLista(data, field) {
    const i = _.findIndex(this.ricerca.fields, {
      field: field,
    });
    this.ricerca.fields[i].option.list = [];
    data.forEach((element) => {
      this.ricerca.fields[i].option.list.push({
        cod: element.cod,
        descrizione: element.descrizione,
      });
    });
  }

  getAllBooks(page, sort?, filters?) {
    this.loader = true;
    const sub = this.jsonapi
      .getCollectionPaginated(
        'novita-admin',
        page,
        this.recordPerPage,
        filters,
        sort
      )
      .subscribe({
        next: (data: any) => {
          this.books = data.data;
          this.booksMeta = data.meta.page;
          this.booksPage = data.data;

          if (this.user.profile === 'libreria') {
            this.booksPage = this.booksPage.filter(
              (book) => {
                return (
                  Number(
                    book.attributes.data_pubblicazione
                  ) >= Number(moment().format('YYYYMMDD'))
                );
              }
            );
          }

          this.searchFilters = {};
        },
        error: (err) => console.error(err),
        complete: () => (this.loader = false),
      });

    this.subscriptions.push(sub);
  }

  getGiri(anno = 0) {
    this.fieldLoader.giri = true;
    const sub = this.bookservice
      .getGiriByYear(anno)
      .subscribe((giri: Array<Giro>) => {
        const data: Array<description> = [];
        /* giri.forEach((item:Giro) => { 
          data.push({cod: item.anno + item.numero, descrizione: item.descrizione});
      })  */

        giri.forEach((item: Giro) => {
          if (
            this.user.profile !== 'libreria' &&
            Number(
              moment(item.data_scadenza)
                .add(4, 'd')
                .format('YYYYMMDD')
            ) < Number(moment().format('YYYYMMDD'))
          ) {
            data.push({
              cod: item.anno + item.numero,
              descrizione: item.descrizione,
            });
          } else {
            data.push({
              cod: item.anno + item.numero,
              descrizione: item.descrizione,
            });
          }
        });
        this.loadLista(data, 'giro');
        this.fieldLoader.giri = false;
      });

    this.subscriptions.push(sub);
  }

  submitButton() {
    this.filters = this.searchFilters;

    if (this.giro) {
      this.filters.push({
        name: 'giro_s',
        value: Number(
          this.giro.anno + Number(this.giro.numero)
        ),
      });
    }
    this.filters && this.filters.length > 0
      ? this.getAllBooks(1, this.sortField, this.filters)
      : this.getAllBooks(1, this.sortField);
  }

  getSearchType(data: any) {
    if (data.event === 'search') {
      this.searchFilters = data.values;
    }
    if (data.event === 'order') {
      this.sortField =
        data.value === 'desc'
          ? '-' + data.field
          : data.field;
    }
  }

  changePage($event): void {
    this.page = $event.pageIndex + 1;
    this.getAllBooks(
      this.page,
      this.sortField,
      this.filters
    );
    this.loader = true;
  }

  buildLists() {}

  resetForm(event) {
    if (event) {
      this.filters = [];
    }
  }

  searchFromBook(field, value) {
    const filtro = [{ name: field, value: value }];
    this.getAllBooks(1, this.sortField, filtro);
  }

  deleteNovita(id) {
    let book = this.books.find((libro) => {
      return libro.attributes.oid === id;
    });
    const dialogRef = this.dialog.open(
      DeleteQuestionComponent,
      {
        data: {
          delete: true,
          titolo: book.attributes.titolo,
          dialogTitle: 'Elimina libro dalle Novità',
        },
        height: 'auto',
        width: 'auto',
      }
    );
    dialogRef
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data && data.delete) {
          const sub = this.bookservice
            .deleteBook(id)
            .subscribe(
              (response: any) => {
                this.booksPage = [];
                this.booksMeta = [];
                this.books = [];
                this.filters = [];
                this.toastservice.success(
                  'Libro eliminato con successo'
                );
                this.messageservice.setEvent({
                  type: 'refresh',
                });
              },
              (error) => {
                this.toastservice.error(
                  "Errore durante l'eliminazione, libro non eliminato"
                );
              }
            );

          this.subscriptions.push(sub);
        }
      });
  }

  getEditori() {
    this.fieldLoader.editori = true;
    const sub = this.bookservice
      .getEditoriCliente(this.user.code)
      .subscribe((editori: Array<description>) => {
        this.loadLista(editori, 'editore');
        this.fieldLoader.editori = false;
      });

    this.subscriptions.push(sub);
  }

  clickEdit(id) {
    this.action = 'view';
    this.selectedBook = id;
  }

  showBooks(element) {
    this.action = 'list';
    this.selectedBook = null;
    this.getAllBooks(
      this.page,
      this.sortField,
      this.filters
    );
  }
  formatData(data) {
    return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
  }

  isNovita(book) {
    if (this.giro) {
      const giro = Number(
        this.giro.anno + Number(this.giro.numero)
      );
      const giroLibro = Number(
        book.anno_giro + Number(book.numero_giro)
      );
      if (giroLibro === giro || giroLibro > giro) {
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }
}
