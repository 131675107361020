import { Subscription } from "rxjs";
import { eventModel, MessageService } from "./../../services/message.service";
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { ArticoloTools } from "src/app/models/articolo.model";
import { iUser } from "src/app/models/user.model";
import { SessionStorageService } from "src/app/services/session.service";

@Component({
  selector: "app-order-quantity",
  templateUrl: "./order-quantity.component.html",
  styleUrls: ["./order-quantity.component.scss"],
})
export class OrderQuantityComponent implements OnInit, OnDestroy {
  @Input() codice;
  @Input() disponibilita;
  @Output() editQta = new EventEmitter<any>();
  @Input() qta = 0;
  articoloTools: ArticoloTools = new ArticoloTools();
  user: iUser;
  subscription:Subscription = null;

  constructor(
    private messageservice: MessageService,
    private session: SessionStorageService
  ) {
    this.subscription =  this.messageservice.listenEvent().subscribe((data: eventModel) => {
      if (data.type === "close-cart") {
        this.qta = 0;
      }
    });
  }

  ngOnInit(): void {
    this.user = new iUser(this.session.get("usr"));
  }

  changeInput($element) {
    this.editQta.emit({ codice: this.codice, qta: $element.qta });
  }

  add() {
    this.qta++;
    this.editQta.emit({ codice: this.codice, qta: this.qta });
  }

  remove() {
    if (this.qta - 1 >= 0) {
      this.qta--;
      this.editQta.emit({ codice: this.codice, qta: this.qta });
    }
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }
}
