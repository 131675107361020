import * as moment from "moment";

export const filters = [
  {
    filterName: "Anno Giro",
    filterData: getFilterYears(),
    selected: getFilterYears()[getFilterYears().length - 1].id.toString(),
  },
  {
    filterName: "Numer Giro",
    filterData: [
      {
        id: 0,
        value: "tutti",
      },
      {
        id: 1,
        value: "giro n.1",
      },
      {
        id: 2,
        value: "giro n.2",
      },
      {
        id: 3,
        value: "giro n.3",
      },
      {
        id: 4,
        value: "giro n.4",
      },
      {
        id: 5,
        value: "giro n.5",
      },
      {
        id: 6,
        value: "giro n.6",
      },
      {
        id: 7,
        value: "giro n.7",
      },
    ],
    selected: "0",
  },
];

function getFilterYears() {
  const firstYear = 2012;
  const currentYear = moment().year();
  let years = [
    {
      id: firstYear,
      value: firstYear.toString(),
    },
  ];

  for (let i = firstYear + 1; i <= currentYear; i++) {
    years.push({
      id: i,
      value: i.toString(),
    });
  }


  return years;
}
