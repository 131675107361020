<div class="container-fluid mt12px">
  <mat-card *ngIf="import === 0">
    <mat-card-subtitle>
      Puoi caricare l'ordine selezionando un file xls o csv
      ( separato da punti e virgola ) con il seguente ordine
      dei campi : Codice ISBN: Quantit&agrave;.
      <br />Dopo aver caricato un file, sarai in grado di
      vedere gli articoli che verranno importati nel tuo
      carrello.
      <br />
      <br /><span font-size="12px"
        >Qui riportato un esempio di come deve essere
        formattato il file.</span
      >
      Puoi scaricare un file di esempio cliccando
      <a
        target="_blank"
        href="assets/esempio_importazione.xlsx"
        >qui</a
      >
      <br /><img
        src="assets/images/importazione_example.png"
      />
    </mat-card-subtitle>
    <mat-card-content *ngIf="!records.length">
      <input
        type="file"
        (change)="onFileChange($event)"
        multiple="false"
      />
    </mat-card-content>
    <mat-card-content *ngIf="records.length">
      <p>
        Il file contiene {{ records.length }} articoli.
        <span *ngIf="unvalidISBNS.length === 0"
          >Non sono stati trovati errori.</span
        >
        <span *ngIf="unvalidISBNS.length > 0"
          >{{ unvalidISBNS.length }} articolo/i non saranno
          importati perch&egrave; hanno un ISBN non
          valido o non sono ancora stati pubblicati</span
        >
      </p>
    </mat-card-content>
    <mat-card-actions *ngIf="records.length">
      <button
        mat-button
        color="warn"
        (click)="undoImport()"
        
      >
        Annulla importazione
      </button>
      <button
        mat-button
        color="accent"
        (click)="doImport()"
      >
        Continua importazione
      </button>
    </mat-card-actions>
  </mat-card>
  <div *ngIf="records.length && import === 0">
    <mat-divider></mat-divider>
    <table
      mat-table
      [dataSource]="records"
      class="mat-elevation-z4"
    >
      <ng-container matColumnDef="codiceISBN">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          ISBN
        </th>
        <td
          mat-cell
          *matCellDef="let element"
        >
          <button
            mat-icon-button
            color="primary"
            matTooltip="ISBN VALIDO"
            *ngIf="isValid(element.codiceISBN)"
          >
            <span class="material-icons">task_alt</span>
          </button>
          <button
            mat-icon-button
            color="warn"
            matTooltip="ISBN INVALIDO O TITOLO NON ANCORA PUBBLICATO"
            *ngIf="!isValid(element.codiceISBN)"
          >
            <span class="material-icons"
              >report_problem</span
            >
          </button>
          <strong>{{ element.codiceISBN }} </strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="descrizione">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Titolo
        </th>
        <td
          mat-cell
          *matCellDef="let element"
        >
          {{ element.descrizione }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantita">
        <th
          mat-header-cell
          *matHeaderCellDef
        >
          Quantita
        </th>
        <td
          mat-cell
          *matCellDef="let element"
        >
          {{ element.quantita }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>

  <div *ngIf="import">
    <mat-card>
      <mat-card-content>
        <p>I prodotti sono stati importati con successo.</p>
        <p>
          Vai nel carrello per visualizzare i prodotti
          importati
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<!-- 'ISBN', 'descrizione', 'quantita', 'sconto']
  
  </table> -->
