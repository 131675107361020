import { exit } from 'process';
import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Ricerca } from 'src/app/models/ricerca.model';
import { BookService } from 'src/app/services/book.service';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/message.service';
import { LocalStorageService } from 'src/app/services/storage.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { description } from 'src/app/models/books.model';

@Component({
  selector: 'admin-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoComponent
  implements OnInit, OnDestroy
{
  public pagination = [];
  public book;
  public books: any = [];
  public booksPage: any = [];
  //info riguardanti la paginaziine degli oggetti restituiti es. current-page
  public booksMeta: any = {};
  public loader = false;
  public page = 1;
  public search = '';
  public searchField = 'titolo';
  public defaultOrder = 'data_pubblicazione';
  public sortField = '-data_pubblicazione';
  public option = 'view';
  searchFilters: any = {};
  filters = [];
  salvaModifiche = false;
  flip: string = 'default';
  public stati = [
    { cod: 'P', descrizione: 'Disponibile' },
    { cod: 'E', descrizione: 'Esaurito' },
    { cod: 'R', descrizione: 'Ristampa' },
    { cod: 'I', descrizione: 'In produzione' },
  ];
  animation = '';
  public links = [];
  searchCtrl = new FormControl();
  subscribe: Subscription[] = [];
  ricerca: Ricerca = {
    fields: [
      {
        field: 'editore',
        option: {
          type: 'select',
          list: [],
        },
      },
      {
        field: 'titolo',
        option: {
          type: 'text',
        },
      },
      {
        field: 'ean',
        option: {
          type: 'text',
        },
      },
      {
        field: 'anno_pubblicazione',
        option: {
          type: 'text',
        },
      },
      {
        field: 'autore',
        option: {
          type: 'lazyUrl',
          lazyUrl: 'autore',
        },
      },
      {
        field: 'collana',
        option: {
          type: 'lazyUrl',
          lazyUrl: 'collana',
        },
      },
      {
        field: 'argomento',
        option: {
          type: 'lazyUrl',
          lazyUrl: 'argomento',
        },
      },
      {
        field: 'stato',
        option: {
          type: 'select',
          list: [],
        },
      },
    ],
  };
  recordPerPage = 20;
  selectedBook = null;
  catalogoloaded = null;
  submit: boolean = false;
  @Output() loaded = new EventEmitter();

  constructor(
    private jsonapi: JsonapiService,
    private storage: LocalStorageService,
    private messageservice: MessageService,
    private bookservice: BookService
  ) {
    const sub = this.searchCtrl.valueChanges.subscribe(
      (data: string) => {
        this.search = data;
        //this.getBooks(0);
      }
    );

    this.subscribe.push(sub);
  }
  ngOnInit(): void {
    //this.getAllBooks(this.page);
    //this.loader = true;
    this.buildStatiList();
    this.getEditori();
  }
  /**prendo tutti i libri presenti nel catalogo dal database */
  getAllBooks(page, sort?, filters?) {
    this.loader = true;
    let sub;
    (sub = this.jsonapi
      .getCollectionPaginated(
        'catalogo-generales',
        page,
        20,
        filters,
        sort
      )
      .subscribe((data: any) => {
        this.loader = false;
        this.books = data.data;
        this.booksMeta = data.meta.page;
        this.booksPage = data.data;

        this.searchFilters = {};
      })),
      (error) => {
        console.error(error);
      };

    this.subscribe.push(sub);
  }

  loadLista(data, field) {
    const i = _.findIndex(this.ricerca.fields, {
      field: field,
    });
    this.ricerca.fields[i].option.list = [];
    data.forEach((element) => {
      this.ricerca.fields[i].option.list.push({
        cod: element.cod,
        descrizione: element.descrizione,
      });
    });
  }

  getEditori() {
    //this.fieldLoader.editori = true;
    const sub = this.bookservice
      .editori('')
      .subscribe((editori: Array<description>) => {
        this.loadLista(editori, 'editore');
        //this.fieldLoader.editori = false;
      });

    this.subscribe.push(sub);
  }

  toggle(bookId): void {
    this.selectedBook = !bookId ? null : bookId;
    this.option = 'view';
    this.salvaModifiche = false;
  }

  getSearchType(data: any) {
    if (data.event === 'search') {
      this.searchFilters = data.values;
    }
    if (data.event === 'order') {
      this.sortField =
        data.value === 'desc'
          ? '-' + data.field
          : data.field;
    }
  }

  submitButton() {
    this.filters = this.searchFilters;
    this.filters && this.filters.length > 0
      ? this.getAllBooks(1, this.sortField, this.filters)
      : this.getAllBooks(1, this.sortField);
  }

  modifica() {
    this.option = 'edit';
  }
  confirm() {
    this.salvaModifiche = true;
  }

  changePage($event): void {
    this.page = $event.pageIndex + 1;
    this.getAllBooks(
      this.page,
      this.sortField,
      this.filters
    );
    this.loader = true;
  }
  resetForm(event) {
    if (event) {
      this.filters = [];
    }
  }

  getStato(codstato) {
    let stato = this.stati.find((stato) => {
      return stato.cod === codstato;
    });
    return stato.descrizione;
  }

  buildStatiList() {
    const i = _.findIndex(this.ricerca.fields, {
      field: 'stato',
    });
    this.ricerca.fields[i].option.list = this.stati;
  }
  /**Esegue la ricerca al click dell'elemento nella card del libro*/
  searchFromBook(field, value) {
    const filtro = [{ name: field, value: value }];
    this.getAllBooks(1, this.sortField, filtro);
  }

  formatData(data) {
    return moment(data, 'YYYYMMDD').format('DD-MM-YYYY');
  }

  ristampa(ean, editore) {
    this.messageservice.setEvent({
      type: 'menu',
      event: { link: 'novita' },
    });
    this.storage.set('ristampaBook', ean);
    this.storage.set('selectedEditore', editore);
  }

  ngOnDestroy(): void {
    this.subscribe.map((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
