import { Component, OnDestroy, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { iUser } from "src/app/models/user.model";
import { BookService } from "src/app/services/book.service";
import { LocalStorageService } from "src/app/services/storage.service";
import { filters } from "./filtersData";
import { Subscription } from "rxjs";

@Component({
  selector: "app-storico-novita",
  templateUrl: "./storico-novita.component.html",
  styleUrls: ["./storico-novita.component.scss"],
})
export class StoricoNovitaComponent implements OnInit, OnDestroy {
  books;
  loading = false;
  user: iUser;
  selectedBook;
  filters = filters;
  subscription: Subscription = null;

  constructor(
    private bookService: BookService,
    private toast: ToastrService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(sessionStorage.getItem("usr"));

    this.getListaLanci(filters[0].selected, filters[1].selected);
  }

  /**
   * Vado a prendermi tutti i titoli che sono presenti nel catalogo e che sono usciti nel giro selezionato
   * @param anno anno del giro
   * @param numGiro numero del giro
   */
  getListaLanci(anno, numGiro) {
    this.loading = true;
    this.subscription = this.bookService
      .getCollection(`libri/novita/${this.user.code}/${anno}/${numGiro}`)
      .subscribe({
        next: (data: any) => {
        
          this.books = data;
        },
        error: (err) => {
          console.error(err);
          this.toast.error("Errore nel recupero dei dati", "Errore");
        },
        complete: () => (this.loading = false),
      });
  }

  setBook(oid) {
    this.selectedBook = oid;
  }

  closeBookForm() {
    this.selectedBook = null;
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }
}
