<mat-form-field class="full-width">
  <mat-label>Seleziona Collaboratore</mat-label>
  <mat-chip-list
    #chipList
    aria-label="item selection"
  >
    <mat-chip
      *ngFor="let item of items"
      [removable]="!disabled"
      (removed)="remove(item)"
    >
      <mat-form-field>
        <mat-select
          matNativeControl
          required
          (selectionChange)="changeType($event, item)"
          [(ngModel)]="item.type"
        >
          <mat-option value="curatore">Curatore</mat-option>
          <mat-option value="illustratore"
            >Illustratore</mat-option
          >
          <mat-option value="traduttore"
            >Traduttore</mat-option
          >
          <mat-option value="prefazione"
            >Prefazione</mat-option
          >
        </mat-select>
      </mat-form-field>
      <p
        style="
          display: flex;
          margin: 0;
          max-width: fit-content;
        "
      >
        - {{ item.descrizione }}
      </p>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>

    <input
      *ngIf="items && items.length < 5"
      required
      [disabled]="disabled"
      placeholder="{{ newItemLabel }}"
      #collabInput
      [formControl]="itemCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />
    <button
      mat-button
      matTooltip="Aggiungi"
      (click)="this.addElement.emit(true);"
      *ngIf="addElementEnabled"
    >
      <mat-icon matSuffix>person_add_alt_1</mat-icon>
    </button>
    <mat-hint align="end">{{ items.length }} / 5</mat-hint>
  </mat-chip-list>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selected($event, 'persona')"
  >
    <mat-option
      *ngFor="let item of filtereditems"
      [value]="item.cod"
    >
      {{ item.descrizione }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
