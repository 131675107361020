<h1 mat-dialog-title>Impossibile proseguire la ristampa del testo</h1>
<div mat-dialog-content> 
    <p>
        Contatta CDA per completare la scheda del libro, altrimenti non sarai in grado di effettuare una ristampa.
       
    </p>
    <p>
        Comunica i seguenti errori :
    </p>
        <div class="error">
            <p *ngFor="let item of errors"  >
                {{item}}
            </p>
        </div>
         
</div>

<mat-dialog-actions>
    <button mat-button  [mat-dialog-close]="errors" cdkFocusInitial color="primary" class="full-width">Chiudi</button>
</mat-dialog-actions>
