<h1
  class="title"
  mat-dialog-title
>
  {{ data.title }}
</h1>
<div >
  <div
    *ngIf="changedGiro"
  >
    {{ data.content }}
    <div
      mat-dialog-actions
      class="right"
    >
      <button
        mat-raised-button
        (click)="changedGiro = false"
      >
        Indietro
      </button>

      <button
        mat-raised-button
        mat-dialog-close
        cdkFocusInitial
        color="primary"
        [mat-dialog-close]="this.giroSelezionato"
      >
        Conferma
      </button>
    </div>
  </div>

  <div *ngIf="!changedGiro">
    <mat-form-field>
      <mat-select
        [ngModel]="this.giroSelezionato.code"
        (selectionChange)="changeGiro($event)"
      >
        <mat-option
          *ngFor="let giro of data.giri"
          [value]="giro.code"
        >
          {{ giro.descrizione }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div
      mat-dialog-actions
      class="right"
    >
      <button
        mat-raised-button
        mat-dialog-close
      >
        Annulla
      </button>
      <button
        mat-raised-button
        cdkFocusInitial
        color="primary"
        (click)="changedGiro = true"
      >
        Avanti
      </button>
    </div>
  </div>
</div>
