import { Utilities } from './../../utilities/utilities.class';
import { DocumentService } from 'src/app/services/document.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash'; 
import { formatCurrency } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Fattura } from 'src/app/models/document.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-elenco-fatture',
  templateUrl: './elenco-fatture.component.html',
  styleUrls: ['./elenco-fatture.component.scss']
})
export class ElencoFattureComponent implements OnInit,OnDestroy {
  loader = false;
  rowsLoader = false;
  ricerca = ''; 
  public page = 1;
 
  start = null;
  stop  = null;
  @ViewChild(MatSort) sort: MatSort;
  defaultSort = 'data_fattura';
  defaultSortDir = 'desc';
  displayedColumns: string[] = [ 'nr_fattura', 'tipo_documento', 'data_fattura', 'imponibile' ,'iva', 'totale','pagamento',  'id_fattura'];
  columnsName: string[] = [ 'Nr.Fattura', 'Tipo', 'Data', 'Imponibile', 'Iva', 'Totale' ,  'Pagamento',  ''];
  dataSource;
  loading = false;
  dataExport = [];
  expandedElement;
  subscritpion: Subscription = null;
  constructor( private documentservice: DocumentService, private toast: ToastrService) { }

  ngOnInit(): void {
    const start =  moment().subtract(3, 'months');
    const stop  =  moment();
    this.start = new FormControl( start.toDate() );
    this.stop  = new FormControl( stop.toDate() );
    this.getData(); 
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadPdf(type: string, id: string){
    this.loading  = true;
    
   const request = this.documentservice.fileRequest(type, id, 'FT');
    fetch(request, {cache: "reload"})
    .then(response => { 
      if(response.status === 200){
        response.blob().then(blob =>{
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `${type}_${id}.pdf`;
          a.click();
          this.loading = false;
          //this.file = URL.createObjectURL(blob); 
        });
      }else{
        this.loading = false;
        this.toast.error('File non trovato');
      }
    });
  }

  public getData() { 
    let filters = [];
    const filter =  {
      name: 'search',
      value: this.ricerca
    }
    if ( this.ricerca!=='') { 
      filters.push(filter);
    }
    const orderby = null;
    const startDate = moment(this.start.value).format('YYYYMMDD');
    const dtaStop = moment(this.stop.value).format('YYYYMMDD');
    this.loader = true;
    this.subscritpion = this.documentservice.getFattureCollection(startDate, dtaStop, filters).subscribe((data:any)  => {
       this.dataSource = new MatTableDataSource(this.buildDataSource(data.data));
       this.dataExport = this.buildDataExport(data.data);
       this.loader = false;
    });
  }

  buildDataSource(collection:any) { 
    let dataSource = [];    
    let data =  Utilities.castToNumber(collection, ['iva', 'imponibile']);
    let fatture = _.groupBy(data, 'nr_fattura');
    for (const key of Object.keys(fatture)) {
      //[ 'nr_fattura', 'tipo_documento', 'data_fattura', 'imponibile' ,'iva', 'totale','pagamento', 'rappresentante']; 
       let imponibile = _.sumBy(fatture[key], 'imponibile');
       let iva        = _.sumBy(fatture[key], 'iva'); 
       let obj = {
         'rows': fatture[key], 
         'nr_fattura' : fatture[key][0].nr_fattura,
         'data_fattura' : fatture[key][0].data_fattura,
         'pagamento' : fatture[key][0].pagamento,
         'tipo_documento': fatture[key][0].tipo_documento,
         'imponibile' : imponibile,
         'iva' :  iva,
         'totale' : imponibile + iva,
         'rappresentante' : fatture[key][0].rappresentante,
         'id_fattura': fatture[key][0].id_fattura
        };
        dataSource.push(obj);  
    }; 
    return _.orderBy(dataSource, [this.defaultSort], [this.defaultSortDir]); 
}



  buildDataExport(collection:any){
    let dataExport = [];
    let row= {};
    collection.forEach(element =>{
      this.displayedColumns.forEach((column, index) => {
        row[this.columnsName[index]]=element[column];
      });
      dataExport.push(row);
      row = {};
    });
    return dataExport;
  }


  public addEvent() {
     this.getData();
  }

  sortData($event) {
    this.defaultSortDir = $event.direction;
      this.defaultSort    = $event.active; 
      this.dataSource = new MatTableDataSource(this.buildDataSource(this.dataSource.data)); 
  }

  format(field, value) {
   
     const valute = ['imponibile', 'iva', 'totale'];
     const dates = ['data_fattura' ];
     const numbers = ['nr_fattura'];

     if (numbers.indexOf(field) > -1) {
       if (Number(value) > 0) {
         return value;
       }
       return;
     }

     if (valute.indexOf(field) > -1) { 
       // caso valuta 
       if (Number(value) > 0 || Number(value) < 0) {
            return formatCurrency(value, 'IT', '');
       }
       return ;
     }
     // caso date
     if (dates.indexOf(field) > -1) {
       if (Number(value) >> 20000000 ) {
         return moment(value, 'YYYYMMDD').format('DD-MM-YYYY');
       }
         return;
     }
      return value;
   }
  
  total(columnName) {
 
    const totals = ['totale', 'iva', 'imponibile'];
    if (totals.indexOf(columnName) > -1) {
     const data = Utilities.castToNumber(this.dataSource.data, [columnName]);  
     return formatCurrency(_.sumBy(data, columnName), 'IT', ''); 
    }
  }

  ngOnDestroy(): void {
      if(this.subscritpion){
        this.subscritpion.unsubscribe();
      }
  }
}
