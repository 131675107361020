import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { BookService } from "src/app/services/book.service";

@Component({
  selector: "app-pdf-list-dialog",
  templateUrl: "./pdf-list-dialog.component.html",
  styleUrls: ["./pdf-list-dialog.component.scss"],
})
export class PdfListDialogComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ["cedola", "filename", "download"];
  sub: Subscription;
  download = false;

  constructor(
    private dialogRef: MatDialogRef<PdfListDialogComponent>,
    private bookservice: BookService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    
  }

  donwloadPDF(pdf) {
    this.download = true;
    this.sub = this.bookservice.getBlob("cedola", pdf.cedola).subscribe({
      next: (data: any) => {
        const blob = new Blob([data], { type: "application/pdf" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        const myWindow = window.open(a.href, "My Window");
        this.dialogRef.close()
      },
      error: (err) => console.error(err),
      complete: () => (this.download = false),
    });
  }

  ngOnDestroy(): void {
      if(this.sub){
        this.sub.unsubscribe();
      }
  }
}
