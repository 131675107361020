import {Component, Input, OnInit} from '@angular/core';
import * as echarts from 'echarts';

@Component({
    selector: 'app-grafico',
    templateUrl: './grafico.component.html',
    styleUrls: ['./grafico.component.scss']
})
export class GraficoComponent implements OnInit {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() icon = '';
    @Input() type = '';
    @Input() chartOption = null;
    @Input() loading = false;

    constructor() {
    }

    ngOnInit(): void {
        this.chartOption['toolbox'] = {
            feature: {
                saveAsImage: {
                    title: 'Salva'
                },
                dataView: {show: true, readOnly: true, title: 'Dati' , lang: ['Dati in tabella', 'Chiudi', 'aggiorna']}, 
            }
        };
    }

}
