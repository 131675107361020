import {Carrello} from 'src/app/models/cart.model';
import { Router } from '@angular/router';
import { CarrelloService } from 'src/app/services/carrello.service';
import { MessageService } from './../../services/message.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {Cart, CartItem} from 'src/app/models/cart.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { findIndex } from 'lodash';
import { OrdiniService } from 'src/app/services/ordini.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-carrello',
  templateUrl: './carrello.component.html',
  styleUrls: ['./carrello.component.scss']
})
export class CarrelloComponent implements OnInit, OnDestroy {
  @Input() items: CartItem[] = [];
  @Input() loading = false;
  subscriptions: Subscription[] = [];
  public total = 0;
  constructor(
     private carrelloservice: CarrelloService,
     private ordiniservice: OrdiniService,
     private messageservice: MessageService, 
     private router: Router,
     private toast: ToastrService
  ) {
   
  }
  ngOnChanges(changes: SimpleChanges): void { 
      this.total = this.calc(this.items); 
  }


  ngOnInit(): void {
   this.total =  this.calc(this.items);
  }

  aggiornaTotale($event:Carrello) { 
   this.total =  this.calc($event);
  }

  concludiOrdine() { 
    const sub = this.ordiniservice.placeOrder().subscribe(response => {
      this.messageservice.setEvent({type : 'cart', event: []});
      this.toast.success("Ordine inviato con successo.");
    }, (err) => {
      this.toast.warning("Errore durante l'invio dell'ordine, riprova.");
    })
    
    this.subscriptions.push(sub);
  }
  
  svuotaCarrello() {
    const sub = this.carrelloservice.emptyTrash().subscribe((data: any) => {   
        this.items =  data; //null
        this.calc(this.items);
        this.messageservice.setEvent({type : 'close-cart', event: data});
        this.toast.success('Il carrello è stato svuotato');
    });

    this.subscriptions.push(sub);
  }

  remove(ean) {
    const sub = this.carrelloservice.delItemFromCart(ean).subscribe((data:any) => { 
      const index = findIndex(this.items, { 'ean': ean });
      this.items.splice(index, 1);
      this.total = this.calc(this.items);
      this.messageservice.setEvent({type:'adj-cart', event: this.items});
    }); 

    this.subscriptions.push(sub);
  }

  calc(items) {
    if (items && items.length > 0) {
      const cart = new Cart(items);
      return cart.totals; 
    } else {
      return 0;
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((el) => {
        if(el){
          el.unsubscribe();
        }
      })
  }

}
