<div *ngIf="loading">
  <mat-spinner matSuffix [diameter]="15" class="mx-auto"></mat-spinner>
</div>
<mat-card class="card-container" *ngIf="!loading">
  <mat-card-title class="mb-5" style="color: #e64a19">
    Dashboard
  </mat-card-title>
  <mat-card-content>
    <div class="row card-row">
      <div class="col-12">
        <app-image-carousel
          [items]="topBooks"
          rankings="true"
          title="Top libri ultimi 3 mesi"
        ></app-image-carousel>
      </div>
      <div class="col-12 mt-5">
        <app-image-carousel [items]="carousel" title="Ultime uscite">
        </app-image-carousel>
      </div>
      <div class="col-12 mt-5">
        <app-image-carousel
          [items]="arrayArgs"
          type="novita"
          [title]="'Novità Cedola ' + giro + ' - Argomento: ' + currentArg"
          (changeOutput)="takeEmitChange($event)"
        >
        </app-image-carousel>
      </div>
    </div>
  </mat-card-content>
</mat-card>
