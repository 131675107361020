import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {DomSanitizer,SafeResourceUrl} from '@angular/platform-browser'
import { BookService } from 'src/app/services/book.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pdf-uploader',
  templateUrl: './pdf-uploader.component.html',
  styleUrls: ['./pdf-uploader.component.scss']
})
export class PdfUploaderComponent implements OnInit , OnChanges, OnDestroy {
  @Input() title = '';
  @Output() changeFile = new EventEmitter<object>();
  @Input() pdf:any = null; 
  @Output() download = new EventEmitter<boolean>();
  @Input() disabled:boolean = false;
  @Input() minimalView:boolean = false;
  @Input() loading = false;
  @Input() pdferror = ''; 
  pdfCrtl = new FormControl();
  constructor(private domSanitizer: DomSanitizer ) { }
  maxSize = 10000000;
  error:boolean = false;
  filename:string = '';
  subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.pdfCrtl.valueChanges.subscribe((data:any)=>{
      if(data){
        this.filename = data.split(/(\\|\/)/g).pop();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
 
    /* if (this.pdferror==='') {
      this.clear();
    } */
  }
  
  clear() {
    this.pdf = null;
    this.pdfCrtl.setValue(null);
    this.changeFile.emit(null);
  } 

  loadFile(event):void {
    //minore di 10MB
    if(event?.target?.files[0]?.size < this.maxSize){
      this.error = false;
      this.changeFile.emit(event.target.files[0]);
      this.pdf = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(event.target.files[0]));
    }else{
      this.error = true;
      this.pdf = null;
    }
  }
  apriDocumento() {
    this.download.emit(true);
  }

  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }
}
