import { SessionStorageService } from './../../services/session.service';
import { BookService } from 'src/app/services/book.service';
import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/storage.service';
import { MessageService } from 'src/app/services/message.service';
import { Giro } from 'src/app/models/giro.model';
import { iUser } from 'src/app/models/user.model';

@Component({
  selector: 'app-archivio-giri',
  templateUrl: './archivio-giri.component.html',
  styleUrls: ['./archivio-giri.component.scss']
})
export class ArchivioGiriComponent implements OnInit {
  @Input() giri: Array<Giro>;  
  giroselezionato: Giro;
  user: iUser;
  oggi = new Date().toISOString();
  constructor(
    private storage: LocalStorageService,
    private session: SessionStorageService,
    private messageservice: MessageService
  ) { }

  ngOnInit(): void {
      this.user = new iUser(this.session.get('usr'));
      const giro:Giro = this.storage.get('giro');
      const firstGiro:Giro = this.storage.get('firstGiro');

      const g = Number(firstGiro.numero)


     /*  this.selezionaGiro(this.giri[1]) */
      if (giro) {
        this.selezionaGiro(giro);
      } else {
       this.selezionaGiro(firstGiro); 
      }
  }

  selezionaGiro(giro: Giro) {
      this.storage.set('giro', giro);
      this.giroselezionato = giro;
      this.messageservice.setEvent({type: 'giro', event: { giro: giro }});
  }

  
  isSelected(giro:Giro) {
    if (this.giroselezionato.descrizione === giro.descrizione) {
      return 'rowTr selected';
    }
    return 'rowTr';
  }

  //download pdf calendario giri
  downloadCalendario(){
    const a = document.createElement('a');
    a.href = '/assets/calendario_editori.pdf';
    a.download = `calendario_editori.pdf`;
    a.click();
  }

}
