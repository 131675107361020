import { MessageService } from 'src/app/services/message.service';
import { BookService } from 'src/app/services/book.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})

export class ImageViewComponent implements OnInit, OnDestroy {

  @Input() ean: string = null;
  @Input() type: string = null;

  loader = true;
  frontImageSrc;
  subscription:Subscription = null;

  constructor(
    private bookservice: BookService,
    private readonly sanitizer: DomSanitizer,
    private messageservice: MessageService
  ) { }

  ngOnInit(): void {
    this.getImage(this.ean);

    this.subscription = this.messageservice.listenEvent().subscribe((data) => {

      if (data.type === 'reloadImage' && data.event.ean === this.ean) {
        setTimeout(() => {
          this.getImage(this.ean)
        }, 500);
      }
      if (data.type === 'loadImage' && Number(data.event.ean) === Number(this.ean)) {
        this.loader = true;
      }
    });

  }


  async getImage(ean) {
    this.loader = true;
    const imageType = (this.type === 'full') ? '' : this.type;

    const cache = await caches.open(environment.cache);
    const request = this.bookservice.imageRequest(this.ean, imageType);
    /**
     * da gestire il tempo di cache.
     */
    let times = 2;
    fetch(request, { cache: "reload" })
      .then(response => {
        if (response.status === 500) {
        /*  setTimeout(() => {
            this.getImage(this.ean)
          }, 500);  */
          this.loader = false;
        } else {
          response.blob().then(blob => {
            this.frontImageSrc = URL.createObjectURL(blob);
            this.loader = false;
          });
        }


      });

  }

  ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
  }

}
