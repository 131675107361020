import { SessionStorageService } from './../../services/session.service';
import {
  MessageService,
  eventModel,
} from './../../services/message.service';
import { LocalStorageService } from './../../services/storage.service';
import { Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { iUser, User } from 'src/app/models/user.model';
import { OauthService } from 'src/app/services/oauthservice.service';
import { SuccessOautResponse } from 'src/app/models/oauth.model';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { CartItem } from 'src/app/models/cart.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          opacity: 0.2,
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: iUser;
  @Input() enableMenu = true;
  @Input() items: CartItem[] = [];
  @Input() loading = false; //loading carrello
  @Output() toggleSideCart: EventEmitter<any> =
    new EventEmitter();
  color: string = 'primary';
  tabs = [
    { index: 0, name: 'dashboard' },
    { index: 1, name: 'catalogo' },
    { index: 2, name: 'novita' },
    { index: 2, name: 'statistiche' },
  ];
  subscription: Subscription[] = [];
  selected = new FormControl(0);
  giro = null;
  isOpen = true;
  constructor(
    private router: Router,
    private storage: LocalStorageService,
    private session: SessionStorageService,
    private oauth: OauthService,
    private messageservice: MessageService
  ) {}

  ngOnInit(): void {
    this.user = new iUser(this.session.get('usr'));
    this.giro = this.storage.get('giro');
    const sub = this.messageservice
      .listenEvent()
      .subscribe((data: eventModel) => {
        if (data.type === 'giro') {
          this.giro = data.event.giro;
        }
        if (data.type === 'menu') {
          this.goto(data.event.link);
        }

        if (data.type === 'riepilogo-ordine') {
          this.toggleCart();
        }
        // chiude il pannello del carrello
        if (data.type === 'close-cart') {
          this.items = data.event;
          this.toggleCart();
        }
        //aggiorna il carrello
        if (data.type === 'adj-cart') {
          this.items = data.event;
        }
      });
    this.selectColor();
    this.subscription.push(sub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.launchRipple();
    if (this.items && this.items.length === 0) {
    }
  }

  goto(link: string): void {
    const i = _.findIndex(this.tabs, { name: link });
    this.selected.setValue(this.tabs[i].index);
  }

  logout(): void {
    const token: SuccessOautResponse =
      this.session.get('token');
    const sub = this.oauth
      .logout(token.access_token)
      .subscribe(
        (data) => {
          this.storage.clear();
          this.session.clear();
          this.router.navigate(['login']);
        },
        (e) => {
          this.storage.clear();
          this.session.clear();
          this.router.navigate(['login']);
        }
      );

      this.subscription.push(sub)
  }

  selectColor() {
    switch (this.user.profile) {
      case 'editore':
        this.color = 'primary';
        break;
      case 'admin':
        this.color = 'warn';
        break;
      case 'cliente':
        this.color = 'accent';
        break;
      case 'libreria':
        this.color = 'warn';
        break;
    }
  }

  toggleCart() {
    this.toggleSideCart.emit();
  }

  launchRipple() {
    this.isOpen = !this.isOpen;
    setTimeout(() => {
      this.isOpen = !this.isOpen;
    }, 500);
  }

  ngOnDestroy(): void {
    this.subscription.forEach((el) => {
      if(el){
        el.unsubscribe();
      }
    })
  }
}
