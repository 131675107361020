import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import { dataTable } from "src/app/models/dataTable.model";
import { BookService } from "src/app/services/book.service";

export interface Argomento {
  id: number;
  descrizione: string;
}

@Component({
  selector: "app-dashboard-libreria",
  templateUrl: "./dashboard-libreria.component.html",
  styleUrls: ["./dashboard-libreria.component.scss"],
})
export class DashboardLibreriaComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  carousel;
  topBooks;
  librigiro: any[];
  loading = false;
  argomenti: Argomento[] = [];

  counterArgs = 0;
  arrayArgs = [];
  currentArg;
  giro = "";

  constructor(private bookservice: BookService) {}

  ngOnInit(): void {
    this.getDashBoard();
  }

  /**
   * Prendo i dati dal backend
   */
  getDashBoard() {
    this.loading = true;
    this.subscription = this.bookservice.getDashBoard().subscribe({
      next: (data: any) => {
        
        this.topBooks = data.top;
        this.carousel = data.carousel;
        this.librigiro = data.librigiro.libri;
        // info sul giro corrente
        this.giro = `${data.librigiro.numero}/${data.librigiro.anno}`;

        // prendo tutti gli argomenti che trovo nelle novità
        this.librigiro.map((el) => {
          const element: Argomento = {
            id: el.cod_argomento_1,
            descrizione: el.argomento,
          };

          // se non sono presenti nell'array li inserisco
          if (
            this.argomenti.filter((el) => el["id"] === element.id).length <= 0
          ) {
            this.argomenti = [...this.argomenti, element];
          }
        });

        // ordino in maniera tale da avere id 0 come ultimo
        this.argomenti = _.orderBy(this.argomenti,['id'],['desc'])
        this.splitArgomento();
        
      },
      error: (err) => console.error(err),
      complete: () => {
        this.loading = false;
      },
    });
  }

  /**
   * filtro l'array per argomento in modo da mostrarne uno alla volta
   */
  splitArgomento() {
    this.currentArg =
      this.argomenti[this.counterArgs].descrizione ?? "Altri libri";

    this.arrayArgs = this.librigiro.filter(
      (el) => this.argomenti[this.counterArgs].id === el.cod_argomento_1
    );
    this.counterArgs =
      this.counterArgs >= this.argomenti.length - 1 ? 0 : this.counterArgs + 1;
  }

  /**
   * prendo il libro in prima posizione, se è l'ultimo scalo al prossimo 
   * @param event passato dal componente del carosello
   */
  takeEmitChange(event) {
    const timeSkip = this.arrayArgs.length <= 5 ? 8000 : 2000;
    if (event.startPosition === this.arrayArgs.length - 1) {
      setTimeout(() => {
        this.splitArgomento();
      }, timeSkip);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
