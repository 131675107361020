import { description } from './../../../models/books.model';
import { Giro } from './../../../models/giro.model';
import { BookService } from './../../../services/book.service';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { iUser } from 'src/app/models/user.model';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'scarica-cedole',
  templateUrl: './scarica-cedole.component.html',
  styleUrls: ['./scarica-cedole.component.scss'],
})
export class ScaricaCedoleComponent
  implements OnInit, OnDestroy
{
  giri: Array<any> = [];
  clienti: Array<any> = [];
  types = ['pdf', 'excel'];
  subscriptions: Subscription[] = [];
  @Input() title = '';
  @Input() subtitle = '';
  @Input() icon = 'receipt';
  @Input() type = '';
  @Input() user: iUser;

  public loader = {
    clienti: false,
    giri: false,
    mese: false,
    download: false,
  };

  typeSelected = null;
  giroSelected = null;
  clienteSelected = null;

  constructor(private bookservice: BookService) {}

  ngOnInit(): void {
    this.getGiri();
    this.getClienti();
    if (this.user.isCliente()) {
      this.clienteSelected = this.user.code;
    }
  }

  getGiri(): void {
    this.loader.giri = true;
    const sub = this.bookservice
      .getGiriByYear(0)
      .subscribe((data: Array<Giro>) => {
        this.loader.giri = false;
        const daysToAdd = this.user.isAdmin() ? 0 : 3;
        data.forEach((giro) => {
          if (
            Number(
              moment(giro.data_scadenza)
                .add(daysToAdd, 'd')
                .format('YYYYMMDD')
            ) < Number(moment().format('YYYYMMDD'))
          )
            this.giri.push({
              cod: giro.anno + '.' + giro.numero,
              description: giro.descrizione,
            });
        });
      });

    this.subscriptions.push(sub);
  }

  async getClienti() {
    this.loader.clienti = true;
    const sub = this.bookservice
      .clienti()
      .subscribe((data: Array<any>) => {
        /*  data.forEach((cliente)=> {
          this.clienti.push({cod : cliente.cod, description: cliente.descrizione});
      }); */
        this.clienti = data;
        this.loader.clienti = false;
      });

    this.subscriptions.push(sub);
  }

  scaricaCedola(): void {
    this.loader.download = true;
    const info = this.giroSelected.split('.');
    const year = info[0];
    const giro = info[1];
    const sub = this.bookservice
      .getCedola(
        this.typeSelected,
        year,
        giro,
        this.clienteSelected
      )
      .subscribe((data: any) => {
        let contentType =
          this.typeSelected === 'pdf'
            ? 'application/pdf'
            : 'application/vnd.ms-excel';
        const blob = new Blob([data], {
          type: contentType,
        });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        let ext =
          this.typeSelected === 'pdf' ? '.pdf' : '.xls';
        a.download = `cedola_${year}_${giro}${ext}`;
        a.click();
        this.loader.download = false;
      });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => {
      if (el) {
        el.unsubscribe();
      }
    });
  }
}
