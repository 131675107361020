import { Injectable } from '@angular/core';

@Injectable()
export class Utilities {
  public static getBase64Image(
    file,
    imageFormat = 'image/jpeg'
  ): string {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let base64string = '';
    (reader.onload = () => {
      let encoded = reader.result
        .toString()
        .replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      base64string =
        'data:' + imageFormat + ';base64,' + encoded;
    }),
      (reader.onerror = (error) => {
        console.error(error);
        base64string = '';
      });

    return base64string;
  }

  public static getBase64String(file): string {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    let base64string = '';
    (reader.onload = () => {
      let encoded = reader.result
        .toString()
        .replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      base64string = encoded;
    }),
      (reader.onerror = (error) => {
        console.error(error);
        base64string = '';
      });

    return base64string;
  }

  public static castToNumber(
    data,
    keysToCast: Array<string>
  ): any {
    data.forEach((item) => {
      keysToCast.forEach((key) => {
        item[key] = +item[key]; //cast float e int
      });
    });
    return data;
  }
}
