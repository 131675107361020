import { dataTable } from "./../../models/dataTable.model";
import { Giro } from "./../../models/giro.model";
import { SuccessOautResponse } from "./../../models/oauth.model";
import { BookService } from "src/app/services/book.service";
import { LocalStorageService } from "src/app/services/storage.service";
import { MessageService } from "./../../services/message.service";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { description } from "../../models/books.model";
import * as _ from "lodash";
import * as moment from "moment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { timeInterval, timeout } from "rxjs/operators";
import { Subscription } from "rxjs";
import { SessionStorageService } from "src/app/services/session.service";

@Component({
  selector: "app-recap-lancio",
  templateUrl: "./recap-lancio.component.html",
  styleUrls: ["./recap-lancio.component.scss"],
})
export class RecapLancioComponent implements OnInit, OnDestroy {
  @Input() title = "";
  @Input() description = "";
  @Input() inizioPren = "";
  @Input() finePren = "";
  @Input() mesiConsigiati = null;
  @Input() volumiInDistrDal = null;
  @Input() icon = "";
  @Input() type = "";
  @Input() numeroGiro = " ";
  @Input() annoGiro = " ";
  pdffilename;
  @Output() OnClickNovita = new EventEmitter<string>();
  @Output() OnClickRistampa = new EventEmitter<string>();
  user = null;
  giro: Giro = null;
  allegato = null;
  pdf = null;
  giriResume = [];
  deletedImg = false;
  loading = false;
  loadingScheda = false;
  private allegatoPromozionale = new FormData();
  titoliGiro: dataTable = {
    header: ["ISBN", "Titolo", "Autore", "Tipo", "Stampa"],
    rows: [],
  };
  novita = [];
  giroResumeSelected;
  subtitle = "";
  subscriptions: Subscription[] = [];
  constructor(
    private toast: ToastrService,
    private messageservice: MessageService,
    private storage: LocalStorageService,
    private session: SessionStorageService,
    private bookservice: BookService,
    private domSanitizer: DomSanitizer
  ) {
    this.giro = this.storage.get("giro");
    //this.getNovitaGiro();
  }

  ngOnInit(): void {
    /**
     * prima visualizzazione allegato promozionale in base al giro selezionato
     * salvato nello storage
     */
    this.giroResumeSelected = this.giro.anno + "." + this.giro.numero;
    this.getGiriYears();
    this.getNovitaGiro({ value: this.giroResumeSelected });
    this.giro = this.storage.get("giro");
    this.user = this.session.get("usr");
    this.loadAllegatoPromozionale();
    const sub = this.messageservice
      .listenEvent()
      .subscribe((data: any) => {
        if (data.type === "giro" || data.type === "refresh") {
          /**Aggiorno l'allegato promozionale in base al giro che seleziona l'utente */
          this.giro = this.storage.get("giro");
          this.loadAllegatoPromozionale();
        }
      });
  }
  

  public getNovitaGiro(event) {
    const giro = event.value.split(".");
    const anno = giro[0];
    const numero = giro[1];
    if (this.giro && this.giro.numero) {
      this.loading = true;
      const sub = this.bookservice.getEditoreResume(anno, numero).subscribe(
        (data: any) => {
          this.titoliGiro.rows = [];
          data.books.forEach((item) => {
            this.titoliGiro.rows.push([
              item.ean,
              item.titolo,
              item.autore,
              item.ristampa === "2" ? "Ristampa" : "Novità",
              item.rich_stampa === "1" ? "Si" : "",
            ]);
          });
          this.subtitle =
            data.allegato !== ""
              ? "Allegato promozionale inserito"
              : "Nessun allegato promozionale inserito";
          this.loading = false;
        },
        (error: any) => {
          this.bookservice.hadleError(error);
        }
      );

      this.subscriptions.push(sub);
    }
  }

  clickNovita() {
    this.messageservice.setEvent({
      type: "menu",
      event: { link: "novita", action: "insert" },
    });
    this.OnClickNovita.emit("novita");
    this.storage.set("action", "insert");
  }

  clickRistampa() {
    this.toast.info("Seleziona il libro da ristampare dal tuo catalogo");
    this.messageservice.setEvent({ type: "menu", event: { link: "catalogo" } });
    //  this.OnClickRistampa.emit('ristampa');
  }

  downloadPdf() {
    const giro = this.giro.anno + this.giro.numero;
    const request = this.bookservice.getAllegatoPromozionale(
      this.user.code,
      giro
    );
    fetch(request, { cache: "reload" }).then(
      (response) => {
        if (response.status != 404) {
          response.blob().then((blob) => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.click();
          });
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  loadAllegatoPromozionale() {
    const giro = this.giro.anno + this.giro.numero;
    const request = this.bookservice.getAllegatoPromozionale(
      this.user.code,
      giro
    );
    fetch(request, { cache: "reload" }).then(
      (response) => {
        if (response.status != 404) {
          response.blob().then((blob) => {
            this.pdf = this.domSanitizer.bypassSecurityTrustResourceUrl(
              URL.createObjectURL(blob)
            );
          });
        } else {
          this.pdf = null;
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  async saveAllegatoPromozionale() {
    const editore = this.session.get("usr");
    const annoNumeroGiro = this.giro.anno + this.giro.numero;
    
    if (this.deletedImg) {
      const sub = this.bookservice
        .deleteResource(`delete-scheda-promozionale/${this.user.code}/${this.numeroGiro}/${this.annoGiro}`)
        .subscribe({
          next: (data) => {
            this.toast.success("Copertina eliminata con successo");
          },
          error: (err) => {
            console.error(err);
            this.toast.error(`Errore nell'eliminazione del file`);
          },
        });

        this.subscriptions.push(sub);
    }
    if (
      this.allegatoPromozionale.has("allegato") &&
      this.allegatoPromozionale.get("allegato") != null
    ) {
      const sub = this.bookservice
        .setAllegatoPromozionale(
          editore.code,
          annoNumeroGiro,
          this.allegatoPromozionale
        )
        .subscribe(
          (data: any) => {
            this.toast.info("Scheda novità inserita con successo");
          },
          (error) => {
            this.toast.error(
              "Si sono verificati degli errori durante l'inserimento della scheda novità"
            );
          }
        );

        this.subscriptions.push(sub);
    }
  }

  setPdf($event, check = false): void {
    this.allegatoPromozionale.delete("allegato");
    this.allegatoPromozionale.append("allegato", $event);

    this.deletedImg = $event === null;

    if ($event !== null) {
      this.allegatoPromozionale.delete("allegato");
      this.allegatoPromozionale.append("allegato", $event);
      this.pdffilename = $event.name;
    } else {
      this.allegatoPromozionale.delete("allegato");
    }
  }

  async getGiriYears() {
    //this.loadingItems.giri = true;
    const sub = this.bookservice
      .getGiriByYear(moment().format("YYYY"), 1)
      .subscribe((data: Array<Giro>) => {
        //this.loadingItems.giri = true;
        data.forEach((giro) => {
          this.giriResume.push({
            cod: giro.anno + "." + giro.numero,
            description: giro.descrizione,
          });
        });
      });

      this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => {
      if(el){
        el.unsubscribe();
      }
    })
  }
}
