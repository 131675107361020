<mat-form-field class="full-width">
    <mat-label>{{itemsLabel}}</mat-label>
    <input 
        type="text"
        [disabled]= "disabled"
        placeholder="{{newItemLabel}}"
        matInput
        #itemInput
        [formControl]="itemCtrl"
        [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" >
        <mat-option *ngFor="let item of filtereditems" [value]="item.cod">
            {{item.descrizione}}
        </mat-option>
    </mat-autocomplete>
    <mat-progress-spinner mode="indeterminate" diameter="15" matSuffix *ngIf="loading"></mat-progress-spinner>
</mat-form-field> 
